import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';

import {ApiService} from '../../services/api.service';
import {Globals} from '../../services/globals.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MockDataService} from '../../services/mock-data.service';
import {ToastrService} from 'ngx-toastr';
import {constants} from '../../shared/constants/constants';
import {UserService} from '../../services/user.service';
import {User} from '../../classes/user';
import {ApplicationService} from '../../services/application.service';
import {BaseService} from '../../services/base-service';
import {AuthService, LoginErrorReasons} from '../../services/auth.service';
import {VisibilityService} from '../../services/visibility.service';
import {mergeMap} from 'rxjs/operators';
import {forkJoin, of} from 'rxjs';
import {StorageAttributes} from '../../shared/constants/storage-attributes.constants';
import {NotificationTexts} from '../../shared/texts/notification.texts';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MFALoginPopover} from '../../popovers/static.popover.config';
import {Popover} from '../../popovers/popover/popover.service';
import {LoginTexts} from '../../shared/texts/login.texts';
import {GTMWrapperService} from '../../services/gtmwrapper.service';
import {environment} from '../../../environments/environment';
import {AccountRewriteService} from '../../services/account-rewrite.service';
import {RegistrationService} from '../../services/registration.service';

@Component({
    selector: 'iona-app',
    templateUrl: './login.component.html',
    styleUrls: [
        './login-base.component.scss',
        './login-initial.component.scss',
        './login-password-reset.component.scss',
        './login.component.scss',
    ],
    viewProviders: [],
    providers: [Globals]
})

export class LoginComponent implements OnInit, AfterViewInit {

    readonly TEXTS = LoginTexts;
    readonly LoginState = LoginState;
    readonly Notifications = NotificationTexts;

    email = '';
    password = '';
    emailreset = '';

    state = LoginState.LOGIN;

    private start_time: Date;
    private demo_interval;

    loginDisabled = true;
    emailResetDisabled = true;
    displayEmailError = false;
    displayEmailResetError = false;
    displayPasswordError = false;
    passwordVisible = false;

    // forms
    loginForm = new UntypedFormGroup({
        email: new UntypedFormControl('', [Validators.required]),
        password: new UntypedFormControl('', [Validators.required])
    });
    passwordResetForm = new UntypedFormGroup({
        email: new UntypedFormControl('', [Validators.required])
    });


    static validateEmailFormat(email: string): boolean {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(email);
    }


    constructor(public apiService: ApiService,
                private title: Title,
                private globals: Globals,
                private router: Router,
                private mockDataService: MockDataService,
                private toastrService: ToastrService,
                private userService: UserService,
                private application: ApplicationService,
                private auth: AuthService,
                private visibility: VisibilityService,
                private popover: Popover,
                private route: ActivatedRoute,
                private gtm: GTMWrapperService,
                private accountRewrite: AccountRewriteService,
                private registrationService: RegistrationService) {
        if (this.userService.hasUser()) {
            this.state = LoginState.LOGIN;
        }
    }


    ngOnInit() {
        this.setupForms();
        this.title.setTitle('Login | E.ON Smart Control');
        this.setupLogoutBehavior();
        this.setupRouteChanges();
    }


    ngAfterViewInit() {
        this.trackViewAppearedEvent();
    }


    /**
     * Route to onboarding / registration process
     */
    onFirstTimeButtonPress(): void {
        this.router.navigate(['registrieren']);
    }


    /**
     * Handle UI state changes
     * @param newState
     */
    changeState(newState: LoginState): void {
        this.state = newState;
    }


    /**
     * Continue with the login procedure
     */
    login(): void {
        const formValues = this.loginForm.value;
        const password = formValues.password;

        let user = formValues.email;
        // fake login rerouting account eonstele02a@energy-i.com to peter
        if (user === this.accountRewrite.userToRewrite && environment.isStaging) {
            user = 'peter.weisshardt@eon.com';
            this.accountRewrite.setAccountRewriteState(true);
        }

        if (this.loginDisabled) {
            return;
        }

        this.application.setApplicationState(constants.application.states.live);
        this.auth.requestLogin(user, password).pipe(
            mergeMap(result => {
                if (result.loginType === 'mfa') {
                    return this.popover.open(MFALoginPopover).afterClosed$.pipe(
                        mergeMap(popoverResult => {
                            if (popoverResult.data) {
                                return of(popoverResult.data);
                            }
                            return of({success: false, data: null});
                        })
                    );
                }
                return of({success: true, data: result.response});
            }),
            mergeMap((results: { success: boolean, data: any }) => {
                if (results.success) {
                    return this.auth.loginSuccessfulPipeline(user, results.data);
                }
                return of(false);
            })
        ).subscribe({
            next: final => {
                this.trackLoginEvent();
                this.auth.fromLogin = true;
                this.continueToDashboard();
            },
            error: error => {
                localStorage.removeItem(StorageAttributes.USERS);
                try {
                    if (error.error === LoginErrorReasons.INVALID_PROVIDER) {
                        this.toastrService.error(this.Notifications.GENERAL_LOGIN_ERROR);
                    }
                    if (error.error === LoginErrorReasons.OPT_IN_REQUIRED) {
                        localStorage.setItem(StorageAttributes.OPT_IN_REQUIRED, '0');
                        this.continueToDashboard();
                    }
                } catch (error) {
                    this.toastrService.error(this.Notifications.GENERAL_LOGIN_ERROR);
                }
            }
        });
    }


    /**
     * Continue with demo mode.
     * Setup a timer for an automatic cancellation once five mintues have passed
     * TODO: refactor this at some point
     */
    continueWithDemoMode() {
        this.trackLinkClickedEvent('demo');
        const init_sub = this.mockDataService.getInitialize();
        const profile_sub = this.mockDataService.getProfile();

        // set globally needed variables
        forkJoin([init_sub, profile_sub]).subscribe(
            (values: any) => {
                this.router.navigate(['']);

                const u: User = {
                    email: 'demo',
                    access_token: '',
                    refresh_token: '',
                    provider: values[0].data.profile.labelpartner,
                    nilm_status: null,
                    token_expires: null,
                    tiles: null,
                    storage: null,
                    device: constants.application.devices.plug
                };
                this.userService.setCurrentUser(u);
                this.userService.setActiveUserProvider(values[0].data.profile.labelpartner);

                // calculate expire date
                const demo_mode_min = 5;
                const demo_mode_time = demo_mode_min * 60 * 1000;
                const current_time = new Date();
                const expire_date = new Date();
                expire_date.setMinutes(current_time.getMinutes() + 5);
                // this._apiService.enableDemoMode(expire_date);
                this.application.enableDemoMode(expire_date);

                this.toastrService.info('Sie werden in ' +
                    `${5} Minuten` + ' automatisch ausgeloggt.');

                this.start_time = new Date();
                this.demo_interval = setInterval(() => {
                    const now = new Date();

                    const diff = now.getTime() - this.start_time.getTime();
                    const diff_s = diff / 1000;
                    const diff_i = parseInt(diff_s.toString(), 10);
                    const diff_min = parseInt((diff_i / 60).toFixed(0), 10);

                    if (diff_min) {
                        if (diff_i % 60 === 0) {
                            if (diff_i <= 239) {
                                this.toastrService.info('Sie werden in ' +
                                    `${demo_mode_min - diff_min} Minuten` + ' automatisch ausgeloggt.');
                            } else if (diff_i >= 240 && diff_i <= 299) {
                                this.toastrService.info('Sie werden in ' +
                                    `${demo_mode_min - diff_min} Minute` + ' automatisch ausgeloggt.');
                            } else if (diff_i >= 300) {
                                this.apiService.logoutUser();
                                clearInterval(this.demo_interval);
                            }
                        }
                    }
                }, 10000);
            },
            (errors) => {
                console.log('Error:', errors);
            }
        );
    }


    onResetPasswordLinkClick(): void {
        this.state = LoginState.PASSWORD_RESET;
        this.trackLinkClickedEvent('password-reset');
    }


    onRegisterLinkClick(): void {
        this.trackLinkClickedEvent('register');
        this.router.navigate(['registrieren']);
    }


    /**
     * Triggers a password reset
     */
    resetPassword() {
        if (this.emailResetDisabled) {
            return;
        }
        const email = this.passwordResetForm.value.email;
        this.registrationService.resetPassword(email).subscribe({
            next: () => {
                this.toastrService.success(this.Notifications.RESET_SUCCESS);
            },
            error: () => {
                this.toastrService.error(this.Notifications.RESET_ERROR);
            }
        });
    }


    /**
     * Route to dashboard
     */
    private continueToDashboard(): void {
        this.router.navigate(['/']);
        this.visibility.init();
    }


    /**
     * Setup Forms
     */
    private setupForms(): void {
        this.loginForm.valueChanges.subscribe((values) => {
            this.email = values.email;
            if (this.email.length >= 1) {
                this.validateLoginInput('email');
            }
            this.password = values.password;
            if (this.password.length >= 1) {
                this.validateLoginInput('password');
            }
        });

        this.passwordResetForm.valueChanges.subscribe((values) => {
            this.emailreset = values.email;
            if (this.emailreset.length >= 1) {
                this.validatePasswordResetInput();
            }
        });
    }


    /**
     * Validate the input login values
     * @param type
     * - can be either 'password' or 'email'
     */
    private validateLoginInput(type: 'email' | 'password'): void {
        let email_valid;
        let password_valid;
        if (this.email.includes('debug')) {
            email_valid = true;
            password_valid = this.password.length >= 5;
        } else {
            email_valid = LoginComponent.validateEmailFormat(this.email);
            password_valid = this.password.length >= 6;
        }

        if (type === 'email') {
            this.displayEmailError = !email_valid;
            this.trackLoginFormErrorEvent(this.TEXTS.EMAIL_ERROR, 'invalid_email');
        }
        if (type === 'password') {
            this.displayPasswordError = !password_valid;
            this.trackLoginFormErrorEvent(this.TEXTS.PASSWORD_ERROR, 'invalid_password');
        }

        this.loginDisabled = !(email_valid && password_valid);
    }


    /**
     * Validate Input for password reset
     */
    private validatePasswordResetInput(): void {
        const email_valid = LoginComponent.validateEmailFormat(this.emailreset);
        this.displayEmailResetError = !email_valid;
        this.emailResetDisabled = !email_valid;
    }


    /**
     * Setup handling once users logout of the application
     */
    private setupLogoutBehavior(): void {
        this.apiService.loggedOut.subscribe(
            (next) => {
                if (next === 'demo') {
                    clearInterval(this.demo_interval);
                } else if (next === 'live') {
                    BaseService.killAll();
                } else {
                    console.log('Error: undefined behavior');
                }
            }
        );
    }


    /**
     * Setup route changes to route directly into demo mode
     */
    private setupRouteChanges(): void {
        this.route.url.subscribe((urlSegments) => {
            const demoSegmentFound = urlSegments.find((seg) => seg.path === 'demo');
            if (demoSegmentFound) {
                this.continueWithDemoMode();
            }
        });
    }


    /// ============================================================================================
    /// GTM STUFF
    /// ============================================================================================
    private trackViewAppearedEvent(): void {
        this.gtm.trackEvent({
            event: 'start',
            eventCategory: 'interaction',
            eventAction: 'start',
            journeyId: 'smart control login',
            toolId: 'smart control login',
            elementId: 'smart control login',
            stepId: 'smart control login',
            platform: 'smart control'
        });
    }


    private trackLinkClickedEvent(link: 'password-reset' | 'register' | 'demo'): void {
        let url = '/registrieren';
        let text = 'Registrieren';
        switch (link) {
            case 'password-reset':
                url = '/login';
                text = 'Passwort vergessen';
                break;
            case 'demo':
                url = '/';
                text = 'Demo Modus';
                break;
            default:
                break;

        }
        this.gtm.trackEvent({
            event: 'click-link',
            eventCategory: 'interaction',
            eventAction: 'click-link',
            journeyId: 'smart control login',
            toolId: 'smart control login',
            elementId: 'smart control login',
            stepId: 'smart control login',
            linkText: text,
            linkPath: url,
        }, true);
    }


    private trackLoginFormErrorEvent(message: string, reason: string): void {
        this.gtm.trackEvent({
            event: 'error',
            eventCategory: 'monitoring',
            eventAction: 'error',
            journeyId: 'smart control login',
            toolId: 'smart control login',
            elementId: 'smart control login',
            stepId: 'smart control login',
            errorMessage: message,
            errorReason: reason
        }, true);
    }


    private trackLoginEvent(): void {
        this.gtm.trackEvent({
            event: 'submit',
            eventCategory: 'conversion',
            eventAction: 'submit',
            journeyId: 'smart control login',
            toolId: 'smart control login',
            elementId: 'smart control login',
            stepId: 'smart control login'
        });
    }

}


export enum LoginState {
    INITIAL,
    LOGIN_SELECT,
    LOGIN,
    PASSWORD_RESET,
};
