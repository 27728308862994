import {Component, OnInit} from '@angular/core';
import {
    HouseholdComparisonDetailTexts
} from '../../../shared/texts/detail/household-comparison/household-comparison-detail.texts';
import {
    HouseholdComparisonDetailLabels
} from '../../../shared/texts/detail/household-comparison/household-comparison-detail.labels';
import {FormControl, FormGroup} from '@angular/forms';
import {
    HouseholdComparisonDataProviderService
} from '../../../services/household-comparison-data-provider.service';
import {HouseholdComparisonProps} from '../../../shared/interfaces/household-comparison.interfaces';
import {BehaviorSubject} from 'rxjs';
import {ProfileService} from '../../../services/profile.service';


@Component({
    selector: 'app-household-comparison-filter-form',
    templateUrl: './household-comparison-filter-form.component.html',
    styleUrls: ['./household-comparison-filter-form.component.scss']
})
export class HouseholdComparisonFilterFormComponent implements OnInit {
    readonly LABELS = HouseholdComparisonDetailLabels;
    readonly TEXTS = HouseholdComparisonDetailTexts;

    formSubmitted = new BehaviorSubject<boolean>(null);

    filterForm = new FormGroup({
        occupants: new FormControl(false, []),
        spaceHeating: new FormControl(false, []),
        waterHeating: new FormControl(false, []),
        property: new FormControl(false, []),
        evCharger: new FormControl(false, []),
        pool: new FormControl(false, []),
        sauna: new FormControl(false, []),
    });

    fieldsDisabled = {
        occupants: false,
        spaceHeating: false,
        waterHeating: false,
        property: false,
        evCharger: false,
        pool: false,
        sauna: false,
    };


    constructor(
        private householdComparisonDataProvider: HouseholdComparisonDataProviderService,
        private profileService: ProfileService,
    ) {
    }


    ngOnInit(): void {
        this.initializeFormWithProfileAttributes();
        this.restoreForm();
    }


    /**
     * Handles the form submission.
     *
     */
    onSubmit(): void {
        const props: HouseholdComparisonProps = {
            occupants: this.filterForm.value.occupants,
            spaceHeating: this.filterForm.value.spaceHeating,
            evCharger: this.filterForm.value.evCharger,
            swimmingPool: this.filterForm.value.pool,
            sauna: this.filterForm.value.sauna,
            waterHeating: this.filterForm.value.waterHeating,
            propertyType: this.filterForm.value.property,
        };
        this.householdComparisonDataProvider.setProps(props);

        this.formSubmitted.next(true);
    }


    /**
     * Determines whether to show a filter info paragraph.
     * Returns {boolean} if at least one filter is disabled.
     */
    showFilterInfo(): boolean {
        for (const key of Object.keys(this.fieldsDisabled)) {
            if (this.fieldsDisabled[key]) {
                return true;
            }
        }
        return false;
    }


    /**
     * Initializes the form state depending on the profile attributes.
     * If an attribute is not available, the corresponding form control is disabled.
     * @private
     */
    private initializeFormWithProfileAttributes(): void {
        this.profileService.getAttributesWithCache().subscribe((attributes) => {
            if (attributes.Attributes.Occupants == null) {
                this.filterForm.controls.occupants.disable();
            }
            if (attributes.Attributes.SpaceHeating == null) {
                this.filterForm.controls.spaceHeating.disable();
            }
            if (attributes.Attributes.WaterHeating == null) {
                this.filterForm.controls.waterHeating.disable();
            }
            if (attributes.Attributes.PropertyType == null) {
                this.filterForm.controls.property.disable();
            }
            if (attributes.Appliances['A.21'] == null) {
                this.filterForm.controls.evCharger.disable();
            }
            if (attributes.Appliances['A.22'] == null) {
                this.filterForm.controls.pool.disable();
            }
            if (attributes.Appliances['A.23'] == null) {
                this.filterForm.controls.sauna.disable();
            }
            this.fieldsDisabled.occupants = attributes.Attributes.Occupants == null;
            this.fieldsDisabled.spaceHeating = attributes.Attributes.SpaceHeating == null;
            this.fieldsDisabled.waterHeating = attributes.Attributes.WaterHeating == null;
            this.fieldsDisabled.property = attributes.Attributes.PropertyType == null;
            this.fieldsDisabled.evCharger = attributes.Appliances['A.21'] == null;
            this.fieldsDisabled.pool = attributes.Appliances['A.22'] == null;
            this.fieldsDisabled.sauna = attributes.Appliances['A.23'] == null;
        });
    }


    /**
     * Restores the form to the values of the current props from data provider.
     * @private
     */
    private restoreForm(): void {
        const props = this.householdComparisonDataProvider.getCurrentProps();
        this.filterForm.setValue({
            occupants: props.occupants ?? false,
            spaceHeating: props.spaceHeating ?? false,
            evCharger: props.evCharger ?? false,
            pool: props.swimmingPool ?? false,
            sauna: props.sauna ?? false,
            waterHeating: props.waterHeating ?? false,
            property: props.propertyType ?? false,
        });
    }

}
