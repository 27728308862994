export const translateAppliance = (value: string) => {
    switch (value) {
        case 'AlwaysOn': {
            return 'Grundlast';
        }
        case 'Refrigeration': {
            return 'Kühlung';
        }
        case 'SpaceHeating': {
            return 'Heizung & Kühlung';
        }
        case 'WaterHeating': {
            return 'Warmwasser';
        }
        case 'Cooking': {
            return 'Kochen';
        }
        case 'Laundry': {
            return 'Reinigen & Trocknen';
        }
        case 'Lighting': {
            return 'Beleuchtung';
        }
        case 'Entertainment': {
            return 'Unterhaltung';
        }
        case 'ElectricVehicle': {
            return 'E-Auto';
        }
        case 'PoolOrSauna': {
            return 'Pool & Sauna';
        }
        case 'Other': {
            return 'Sonstige';
        }
        case 'DishWasher': {
            return 'Spülmaschine';
        }
        case 'Dryer': {
            return 'Trockner';
        }
        case 'WashingMachine': {
            return 'Waschmaschine';
        }
        default: {
            return value;
        }
    }
};

export const translateApplianceLowercase = (value: string) => {
    switch (value) {
        case 'alwayson': {
            return 'Grundlast';
        }
        case 'refrigeration': {
            return 'Kühlung';
        }
        case 'spaceheating': {
            return 'Heizung & Kühlung';
        }
        case 'waterheating': {
            return 'Warmwasser';
        }
        case 'cooking': {
            return 'Kochen';
        }
        case 'laundry': {
            return 'Reinigen & Trocknen';
        }
        case 'lighting': {
            return 'Beleuchtung';
        }
        case 'entertainment': {
            return 'Unterhaltung';
        }
        case 'electricvehicle': {
            return 'E-Auto';
        }
        case 'poolsauna': {
            return 'Pool & Sauna';
        }
        case 'other': {
            return 'Sonstige';
        }
        case 'dishwasher': {
            return 'Spülmaschine';
        }
        case 'dryer': {
            return 'Trockner';
        }
        case 'washingmachine': {
            return 'Waschmaschine';
        }
        case 'vehicle': {
            return 'E-Auto';
        }
        default: {
            return value;
        }
    }
};


export interface AppliancesDiagramCallout {
    label: string;
    value: string;
    image: string;
    applianceProfileComplete: boolean;
    color: string;
}
