import {AfterViewInit, Component, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {UserService} from '../../services/user.service';
import {Observable, of, Subscription, from, throwError} from 'rxjs';
import {MockDataService} from '../../services/mock-data.service';
import {constants} from '../../shared/constants/constants';
import {MvpConfig, MvpService} from '../../services/mvp.service';
import {ApplicationService} from '../../services/application.service';
import {MeterService} from '../../services/meter.service';
import {mergeMap, switchMap} from 'rxjs/operators';
import {ProfileService} from '../../services/profile.service';
import {NilmService} from '../../services/nilm.service';
import {RegistrationService} from '../../services/registration.service';
import {VersionService} from '../../services/version.service';
import {BaseComponent} from '../../classes/base-component';
import {UserGroupService} from '../../services/user-group.service';
import {Popover} from '../../popovers/popover/popover.service';
import {AddTileComponent} from '../../popovers/add-tile/add-tile.component';
import {TILE_TYPE, TileDef, TileService} from '../../services/tile.service';
import {SortTilesComponent} from '../../popovers/sort-tiles/sort-tiles.component';
import {
    FirmwareUpdateAvailablePopover,
    FirmwareUpdatePopover,
    ManualPinEntryInfoPopoverConfig,
    ManualPinEntryPopoverConfig,
    PinEntryPopoverConfig,
    PinFailedPopoverConfig,
    RadioLinkLostPopover
} from '../../popovers/static.popover.config';
import {VisibilityService} from '../../services/visibility.service';
import {MeterReaderStatus, OpticalReaderService} from '../../services/optical-reader.service';
import {BannerComponent} from '../../components/banner/banner.component';
import * as moment from 'moment';
import {FirmwareUpdateService} from '../../services/firmware-update.service';
import {MeterReadingService} from '../../services/meter-reading.service';
import {Banners} from '../../shared/constants/banners.constants';
import {ActivatedRoute, Router} from '@angular/router';
import {ACTION} from '../../lib/Action';
import {StorageAttributes} from '../../shared/constants/storage-attributes.constants';
import {LocalOptInService} from '../../services/local-opt-in.service';
import {GTMWrapperService} from '../../services/gtmwrapper.service';
import {AuthService} from '../../services/auth.service';
import {UserTariffService} from '../../services/user-tariff.service';
import {MeterStatuses} from '../../shared/enums/meter-statuses.enum';
import {SupportModalComponent} from '../../components/support-modal/support-modal.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'iona-app',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
    readonly TILE_TYPE = TILE_TYPE;

    private refresh = null;

    userHasPlug = false;

    mvpTileAvailable = false;
    dashboardTiles: TileDef[] = [];

    currentORBatteryState = 0;

    private lastPopupShown: Date = null;

    private visibilitySub = null;
    private meterReadingSub: Subscription = null;
    private popoversOpen = false;
    private preventPinErrorPopover = false;
    private meterPin = null;

    @ViewChild('banner', {static: true}) banner: BannerComponent;


    constructor(
        public application: ApplicationService,
        public tileService: TileService,
        public userService: UserService,
        private title: Title,
        private mockData: MockDataService,
        private renderer: Renderer2,
        private mvpService: MvpService,
        private meter: MeterService,
        private profile: ProfileService,
        private nilm: NilmService,
        private registration: RegistrationService,
        private versionService: VersionService,
        private userGroupService: UserGroupService,
        private popover: Popover,
        private visibility: VisibilityService,
        private opticalReader: OpticalReaderService,
        private updateService: FirmwareUpdateService,
        private meterReading: MeterReadingService,
        private route: ActivatedRoute,
        private router: Router,
        private optInService: LocalOptInService,
        private gtm: GTMWrapperService,
        private auth: AuthService,
        private userTariffService: UserTariffService,
        public dialog: MatDialog
    ) {
        super();
    }


    ngOnInit() {
        this.title.setTitle('Übersicht | E.ON Smart Control');
        this.optInService.checkStatus(true);

        this.initializeDashboardTiles();

        // further inits
        this.setupVisibilityCallback();
        this.nilm.startNilmStatusUpdateForCurrentProfile();

        this.initPlug();
        this.initERNA();
        this.initPhaseChecker();
        this.initializeMVPTiles();

        this.initializeMeterReading();
        this.handleQueryParams();

        this.initializeTariffs();

        this.meter.startLiveUpdate();
    }


    ngOnDestroy() {
        if (this.refresh) {
            clearInterval(this.refresh);
        }

        if (this.meterReadingSub) {
            this.meterReadingSub.unsubscribe();
            this.meterReadingSub = null;
        }

        delete this.tileService;
    }


    ngAfterViewInit() {
        if (localStorage.getItem('userOnboardingInProgress') === 'in_progress') {
            this.openCustomDialog();
            localStorage.removeItem('userOnboardingInProgress');
        }

        if (this.auth.fromLogin) {
            const labelpartner = this.userService.getActiveUserProvider();
            const meterType = this.userService.getUserDevice();
            this.auth.fromLogin = false;
            this.trackAfterLoginEvent(labelpartner, meterType);
        }
    }

    openCustomDialog(): void {
        const dialogRef = this.dialog.open(SupportModalComponent, {
            width: '500px',
            data: {
                text: 'Bitte haben Sie noch etwas Geduld. Je nach Zählertyp kann es noch etwas Zeit benötigen, bis die ersten Daten zu sehen sind. Sollten diese auch am nächsten Tag noch nicht verfügbar sein, wenden Sie sich gerne an unseren Kundenservice (smartcontrol@eon.com oder 0800-99449021).',
                imageUrl: 'assets/img/logos/app-icon/icon.png',
                title: 'Willkommen bei Smart Control!',
                buttonText: 'Weiter'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The custom modal was closed');
        });
    }



    private initializeDashboardTiles(): void {
        this.dashboardTiles = this.tileService.getCurrentTiles();
        this.tileService.init();
        const tileSelectionSub = this.tileService.selectionChanged.subscribe({
            next: (res: any) => {
                if (this.tileService) {
                    this.dashboardTiles = res;
                }
            }
        });
        this.addSub(tileSelectionSub);

    }


    private handleQueryParams(): void {
        if (this.application.isDemoMode()) {
            return;
        }

        this.route.queryParams.subscribe({
            next: (params) => {
                if (!('detail' in params)) {
                    return;
                }
                switch (params.detail) {
                    case 'meter':
                        this.tileService.openDetailView(
                            TILE_TYPE.METER,
                            ACTION.TRIGGER_METER_READING
                        );
                        this.router.navigate(['/']);
                        break;
                }
            }
        });
    }


    private initializeMeterReading(): void {
        if (this.application.isDemoMode()) {
            return;
        }
        if (!this.userService.isMMEWMSBUser()) {
            return;
        }
        this.meterReadingSub = this.meterReading.getMeterReadings().subscribe({
            next: (res) => {
                if (res.length > 0) {
                    this.banner.show(Banners.meterReading);
                }
            },

        });
    }


    /**
     * Wrapper for extracting MVP tile configs an minor response restructuring
     * @param response
     */
    private extractMvpTileDefinitions(response): Observable<MvpConfig[]> {
        try {
            const mapped = response.groups.map(group => {
                const cfg = group.parameters as MvpConfig;
                cfg.group = group.group_id;
                return cfg;
            }) as MvpConfig[];
            return of(mapped);
        } catch (error) {
            return throwError(error);
        }
    }


    /**
     * MPV Tile initialization pipeline
     */
    private initializeMVPTiles(): void {
        if (this.application.isDemoMode()) {
            return;
        }
        this.tileService.removeLegacyMVPTiles();
        const promise = this.userGroupService.getUserGroupNew();
        const sub = from(promise).pipe(
            mergeMap(response => this.extractMvpTileDefinitions(response)),
            mergeMap(tileDefs => of({
                    currentConfig: this.userService.getLastMvpTileConfigNew(),
                    newConfig: tileDefs
                })
            ),
        ).subscribe({
            next: (configs: { currentConfig: MvpConfig[], newConfig: MvpConfig[] }) => {
                const currentConfig = configs.currentConfig;
                const newConfigs = configs.newConfig;

                if (currentConfig) {
                    for (const config of currentConfig) {
                        const newConfig = newConfigs.find(el => el.id === config.id);
                        if (!newConfig) {
                            this.tileService.removeMVPTile(config);
                            this.userService.setMvpTileConfigNew(newConfigs);
                        }
                    }

                    for (const config of newConfigs) {
                        const oldConfig = currentConfig.find(el => el.id === config.id);
                        if (oldConfig) {
                            const tileThere = this.tileService.mvpTileIsCurrentlyActive(config.id);
                            if (!tileThere) {
                                if (config.dashboardConfiguration.version >
                                    oldConfig.dashboardConfiguration.version) {
                                    if (config.dashboardConfiguration.forceAdd) {
                                        this.tileService.updateMVPTileConfig(config);
                                        this.tileService.toggleMVPTile(config.id);
                                        this.tileService.updateMVPTilePosition(config);

                                        // save mvp tile configs
                                        this.userService.setMvpTileConfigNew(newConfigs);
                                    }
                                }
                            }
                        } else {
                            this.tileService.addMVPTile(config);
                        }
                    }
                } else {
                    for (const config of newConfigs) {
                        this.tileService.addMVPTile(config);
                    }
                    this.userService.setMvpTileConfigNew(newConfigs);
                }
                this.mvpTileAvailable = true;
            },
            error: error => console.log('Error initializing MVP tile feature:', error),
            complete: () => sub.unsubscribe()
        });
    }

    /**
     * Fetches the user tariffs for later use.
     * @private
     */
    private initializeTariffs(): void {
        // request user tariffs so that we won't have a problem later.
        this.userTariffService.getTariffs$().subscribe({
            next: null,
        });
    }


    private initPhaseChecker(): void {
        const o = of(this.userService.hasPhaseChecker()).pipe(
            mergeMap((res) => {
                if (!res) {
                    return this.meter.onMeterStatus;
                }
                return of(null);
            })
        );
        let sub = null;
        sub = o.subscribe({
            next: (res) => {
                if (res) {
                    if ('lora_mode' in res) {
                        if (res.lora_mode === 0 || res.lora_mode === 7 || res.lora_mode === 8) {
                            this.tileService.enableTileType(TILE_TYPE.PHASE_CHECKER);
                            this.tileService.setSelected(true, TILE_TYPE.PHASE_CHECKER);
                            // this.addTile('phase-checker', false);
                        }
                        this.userService.setPhaseCheckerAvailability(true);
                    }
                }
                if (sub) {
                    sub.unsubscribe();
                }
            },
        });
    }


    /**
     * Check the user has a plug or a box
     */
    private initPlug(): void {
        this.registration.getModel().subscribe({
            next: (res) => {
                if ('model_identifier' in res) {
                    this.handlePlugResponse(res);
                }
            }
        });
    }


    /**
     * If the user has a plug, handle the response and act accordingly
     * @param response
     */
    private handlePlugResponse(response: any): void {
        const model = response.model_identifier;
        switch (model) {
            case constants.application.devices.plug:
            case constants.application.devices.plug_optical:
                this.userService.updateUserDevice(constants.application.devices.plug);
                this.userHasPlug = true;
                break;
            case constants.application.devices.smart_box:
                this.userService.updateUserDevice(constants.application.devices.smart_box);
                break;
            case constants.application.devices.smart_box2:
                this.userService.updateUserDevice(constants.application.devices.smart_box2);
                break;
            case constants.application.devices.box:
                this.userService.updateUserDevice(constants.application.devices.box);
                break;
            default:
                this.userService.updateUserDevice(constants.application.devices.box);
                this.userHasPlug = false;
        }


        if (this.userHasPlug) {
            if (!this.tileService.tileAvailable(TILE_TYPE.POWER_CHECKER)) {
                this.tileService.enableTileType(TILE_TYPE.POWER_CHECKER);
                this.tileService.setSelected(true, TILE_TYPE.POWER_CHECKER);
            }
        }
    }


    private initERNA(): void {
        if (this.userService.isEDGUser()) {
            this.tileService.disableTileType(TILE_TYPE.PHASE_CHECKER);

            this.opticalReader.startLiveUpdate();
            this.enableMeterStatusUpdates();
        }
    }


    private shouldTriggerTimeBasedOverlay(item: string, timeframe: any, time: number): boolean {
        const lastTriggered = localStorage.getItem(item);
        if (!lastTriggered) {
            localStorage.setItem(item, moment().toDate().toString());
            return true;
        }

        const date = new Date(lastTriggered);
        if (date <= moment().subtract(time, timeframe).toDate()) {
            localStorage.setItem(item, moment().toDate().toString());
            return true;
        }
        return false;
    }


    private shouldTriggerPinOverlay(): boolean {
        if (this.preventPinErrorPopover) {
            return false;
        }

        const lastTriggered = localStorage.getItem(StorageAttributes.LAST_PIN_INFO);
        if (!lastTriggered) {
            localStorage.setItem(StorageAttributes.LAST_PIN_INFO, moment().toDate().toString());
            return true;
        }

        const date = new Date(lastTriggered);
        if (date <= moment().subtract(1, 'hour').toDate()) {
            localStorage.setItem(StorageAttributes.LAST_PIN_INFO, moment().toDate().toString());
            return true;
        }
        return false;
    }


    private shouldTriggerAvailableUpdateOverlay(): boolean {
        const lastTriggered = localStorage.getItem(StorageAttributes.LAST_UPDATE_INFO);
        if (!lastTriggered) {
            localStorage.setItem(StorageAttributes.LAST_UPDATE_INFO, moment().toDate().toString());
            return true;
        }

        const date = new Date(lastTriggered);
        if (date <= moment().subtract(24, 'hour').toDate()) {
            localStorage.setItem(StorageAttributes.LAST_UPDATE_INFO, moment().toDate().toString());
            return true;
        }
        return false;
    }


    handleStateIndependentValues(res: MeterReaderStatus): Observable<any> {
        this.currentORBatteryState = res.battery_status;
        if (res.mode === 'RT_INACTIVE') {
            if (res.battery_status > 0) {
                // this.banner.show(Banners.energySaver);
            } else {
                // this.banner.hide();
            }
        }
        return of(res);
    }


    /**
     * Trigger the popover cycle necessary when the pin entry has failed
     *    furthermore determine whether the overlays should be shown
     */
    private triggerPinFailedPopoverCycle(): Observable<any> {
        return of(this.shouldTriggerPinOverlay()).pipe(
            mergeMap((trigger) => {
                if (trigger) {
                    return this.popover.open(PinFailedPopoverConfig).afterClosed$.pipe(
                        switchMap((dialogData) => {
                            if (dialogData.data === null) {
                                this.popoversOpen = false;
                                return of(null);
                            }
                            let overlayDef: any = ManualPinEntryPopoverConfig;
                            if (dialogData.data) {
                                overlayDef = PinEntryPopoverConfig;
                            }
                            if (this.meterPin) {
                                overlayDef.data.text +=
                                    ` Ihre aktuelle Zähler-Pin lautet: ${this.meterPin}`;
                            }
                            return this.popover.open(overlayDef).afterClosed$;
                        })
                    );
                } else {
                    return of(null).pipe(switchMap(res => of(res)));
                }
            })
        );
    }


    private enableMeterStatusUpdates(): void {
        let previousStatus = '';
        this.opticalReader.onMeterReaderStatus.pipe(
            mergeMap((res: MeterReaderStatus) => {
                return this.handleStateIndependentValues(res);
            }),
            mergeMap((result: MeterReaderStatus) => {
                // if the update state has changed killit
                if (previousStatus === MeterStatuses.UPDATE_INSTALLING &&
                    result.status !== MeterStatuses.UPDATE_INSTALLING) {
                    this.updateService.onUpdateStateReceived.next(null);
                }
                previousStatus = result.status;

                this.meterPin = result.pincode;
                const pincodeThere = result.pincode
                    ? result.pincode !== '' || result.pincode !== null
                    : false;
                const pinEntryModeValid = result.pin_entry_mode === 'optical' ||
                    result.pin_entry_mode === 'unknown';
                const pinInputRequired = result.pin_entry_mode === 'manual_push_button' ||
                    result.pin_entry_mode === 'manual_torch';

                const meterUnlocked = result.meter_unlocked;

                let toReturn = of(null) as Observable<any>;

                // status independent mechanisms
                if (this.shouldTriggerTimeBasedOverlay(
                    StorageAttributes.MANUAL_PIN_ENTRY_MODE, 'hours', 24)) {
                    if (!meterUnlocked && pinInputRequired) {
                        const overlayDef = ManualPinEntryInfoPopoverConfig;
                        if (this.meterPin) {
                            overlayDef.data.text +=
                                ` Ihre aktuelle Zähler-Pin lautet: ${this.meterPin}.`;
                        }
                        return this.popover.open(overlayDef)
                            .afterClosed$.pipe(
                                mergeMap(res =>
                                    of({type: MeterStatuses.MANUAL_PIN_ENTRY_REQUIRED, res})
                                ));
                    }
                }

                switch (result.status) {
                    case MeterStatuses.PIN_FAILED:
                        if (!this.popoversOpen) {
                            toReturn = this.triggerPinFailedPopoverCycle().pipe(
                                mergeMap(res => of({type: MeterStatuses.PIN_FAILED, res}))
                            );
                        }
                        break;
                    case MeterStatuses.UPDATE_INSTALLING:
                        this.updateService.onUpdateStateReceived.next(result.update_progress);
                        if (!this.popoversOpen) {
                            if (result.update_progress < 100) {
                                toReturn = this.popover.open(FirmwareUpdatePopover).afterClosed$
                                    .pipe(
                                        mergeMap(res => of({
                                            type: MeterStatuses.UPDATE_INSTALLING, res
                                        }))
                                    );
                                this.popoversOpen = true;
                            }
                        }
                        break;
                    case MeterStatuses.CONNECTED_WITH_METER:
                        if (!meterUnlocked) {
                            if (result.key_retries === 0
                                && !pincodeThere
                                && pinEntryModeValid) {
                                toReturn = this.triggerPinFailedPopoverCycle().pipe(
                                    mergeMap(res =>
                                        of({type: MeterStatuses.PIN_FAILED, res}))
                                );
                                break;
                            }
                            this.banner.show(Banners.meterConnecting);
                        }

                        if (result.firmware_status === 'UPDATE_AVAILABLE') {
                            if (!this.shouldTriggerAvailableUpdateOverlay()) {
                                break;
                            }
                            if (this.popoversOpen) {
                                break;
                            }
                            toReturn = this.popover.open(FirmwareUpdateAvailablePopover)
                                .afterClosed$.pipe(
                                    mergeMap(res => of({
                                        type: MeterStatuses.CONNECTED_WITH_METER, res
                                    }))
                                );
                            this.popoversOpen = true;
                        }


                        break;
                    case MeterStatuses.RADIO_LINK_LOST:
                        if (!this.shouldTriggerTimeBasedOverlay(
                            StorageAttributes.RADIO_LINK_LOST_INFO, 'hours', 24)) {
                            break;
                        }
                        if (this.popoversOpen) {
                            break;
                        }
                        toReturn = this.popover.open(RadioLinkLostPopover).afterClosed$.pipe(
                            mergeMap(res => of(
                                {type: MeterStatuses.RADIO_LINK_LOST, res}
                            ))
                        );
                        this.popoversOpen = true;
                        break;
                    case MeterStatuses.READY_FOR_METER_INCLUSION:
                        // if (!pincodeThere && !this.popoversOpen) {
                        //     toReturn = this.triggerPinFailedPopoverCycle().pipe(
                        //         mergeMap(res => of({type: MeterStatuses.PIN_FAILED, res}))
                        //     );
                        // }
                        this.banner.show(Banners.meterConnectingTutorial);
                        break;
                    default:
                        break;
                }
                return toReturn;
            }),
        ).subscribe({
            next: (result) => {
                try {
                    if (result.type === MeterStatuses.PIN_FAILED) {
                        if (typeof result.res.data === 'string') {
                            const pin = parseInt(result.res.data, 10);
                            this.preventPinErrorPopover = true;
                            this.meter.startContinuousPinEntry(pin);
                            this.meter.onPinEntrySuccess.subscribe((res) => {
                                if (res) {
                                    this.preventPinErrorPopover = false;
                                }
                            });
                        }
                        this.popoversOpen = false;
                    } else if (result.type === MeterStatuses.UPDATE_INSTALLING) {

                    } else if (result.type === MeterStatuses.CONNECTED_WITH_METER) {
                        if (result.res.data) {
                            this.updateService.startUpdate().subscribe(() => {
                            });
                        }
                    } else if (result.type === MeterStatuses.RADIO_LINK_LOST) {

                    }
                    this.popoversOpen = false;
                } catch (error) {
                }
            }
        });
    }


    /**
     * Setup visibility change functionality
     */
    private setupVisibilityCallback(): void {
        if (!this.visibilitySub) {
            this.visibilitySub = this.visibility.onVisible.subscribe({
                next: () => {
                    // this.checkNilmStatus();
                    this.opticalReader.getBatteryStatus().subscribe(
                        value => this.currentORBatteryState = value);
                }
            });
        }
    }


    /**
     * On Sort Dashboard tiles button clicked.
     * Opens an overlay on the rhs containing the list
     */
    onSortButtonClick() {
        const s = this.popover.open({
            content: SortTilesComponent,
            data: null,
            hasBackdrop: true,
            position: 'absolute',
            placement: 'end center'
        }).afterClosed$.subscribe({
            next: (res) => s.unsubscribe()
        });
    }


    /**
     * On Add Dashboard tiles button clicked.
     * Opens an overlay containing an overview of all available tiles
     */
    onAddTileButtonClicked() {
        const s = this.popover.open({
            content: AddTileComponent,
            data: null,
            hasBackdrop: true,
            placement: 'center center'
        }).afterClosed$.subscribe({next: (res) => s.unsubscribe()});
    }


    /// ============================================================================================
    /// GTM STUFF
    /// ============================================================================================
    private trackAfterLoginEvent(labelpartner: string, meterType): void {
        this.gtm.trackEvent({
            event: 'login',
            eventCategory: 'conversion',
            eventAction: 'login',
            journeyId: 'smart control login',
            toolId: 'smart control login',
            elementId: 'smart control login',
            stepId: 'smart control login',
            lablePartner: labelpartner,
            tariffMeterType: meterType
        });
    }
}
