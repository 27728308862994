import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {BaseComponent} from '../../classes/base-component';
import {LocalOptInService} from '../../services/local-opt-in.service';
import {GTMWrapperService} from '../../services/gtmwrapper.service';

@Component({
    selector: 'iona-app',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss'],
    viewProviders: []
})

export class ContactComponent extends BaseComponent implements OnInit, AfterViewInit {


    constructor(private title: Title,
                private optInService: LocalOptInService,
                private gtm: GTMWrapperService) {
        super();
    }

    ngOnInit() {
        this.title.setTitle('Info | E.ON Smart Control');
        this.optInService.checkStatus();
    }


    ngAfterViewInit(): void {
        this.trackViewLoadedEvent();
    }


    openPhone(): void {
        window.open('tel:0800-99449021');
    }


    openMail(): void {
        window.open('mailto:smartcontrol@eon.com', '');
        this.trackEmailButtonClick();
    }


    /// ============================================================================================
    /// GTM STUFF
    /// ============================================================================================
    private trackViewLoadedEvent(): void {
        this.gtm.trackEvent({
            event: 'view',
            eventCategory: 'interaction',
            eventAction: 'view',
            journeyId: 'direct contact',
            toolId: 'direct contact',
            elementId: 'direct contact',
            stepId: 'direct contact'
        });
    }

    private trackEmailButtonClick(): void {
        this.gtm.trackEvent({
            event: 'click-link',
            eventCategory: 'interaction',
            eventAction: 'view',
            journeyId: 'direct contact',
            toolId: 'direct contact',
            elementId: 'direct contact',
            stepId: 'direct contact',
            linkText: 'smartcontrol@eon.com',
            linkPath: 'not_exists'
        });
    }
}
