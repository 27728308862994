<div class="overlay">
    <div class="overlay-container-cdk">

        <div class="header">
            <div class="grow-space">
                <h2 class="m-a-n"> Neuer Tarif </h2>
            </div>
            <button type="button" class="icn-btn-close"
                    (click)="close(null)"></button>
        </div>

        <div class="content">
            <div class="tariff-creation">
                <form (ngSubmit)="submit()" (change)="onFormChange()">
                    <div>
                        <input type="text" class="text-input" placeholder="Tarifname"
                               name="name" [(ngModel)]="data.name">
                    </div>
                    <div>
                        <input #dateInput type="text" class="text-input"
                               placeholder="Tarifbeginn" name="start"
                               [min]="minDate" [max]="maxDate"
                               (focus)="dateInput.type = 'date'"
                               [(ngModel)]="data.dateStart">
                    </div>
                    <div>
                        <input type="number" class="text-input" placeholder="Grundpreis in €"
                               name="baseprice" [(ngModel)]="data.basePrice">
                    </div>
                    <div>
                        <input type="number" class="text-input" placeholder="Arbeitspreis in €"
                               name="workprice" [(ngModel)]="data.workPrice">
                    </div>
                    <div>
                        <button type="submit" class="btn-scale" [disabled]="confirmDisabled">
                            Speichern
                        </button>
                    </div>
                </form>
            </div>
        </div>

    </div>
</div>
