<div class="overlay-minimal">
    <div class="overlay-container">
        <h1 class="eon-bold">
            {{showFeatures ? 'Ihre Daten können mehr!' : 'Hinweis'}}
        </h1>
        <ng-container *ngIf="showFeatures; else alternate">
            <p class="eon-regular">
                Erhalten Sie eine umfangreiche Auswerung, indem Sie Ihre Daten dafür freigeben.
            </p>
            <div class="feature-list">
                <div *ngFor="let feature of features" class="feature-list-item">
                    <div class="feature-icon"
                         [style.background-image]="createImageUrl(feature)"></div>
                    <div class="feature-description">
                        <div><strong class="m-b-m font-bold">{{feature.title}}</strong></div>
                        <div class="font-regular"> {{feature.text}} </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #alternate>
            <p class="font-regular">
                Wenn Sie die WebApp und alle Funktionen von E.ON Smart Control nutzen möchten, können Sie in den
                Online-Modus wechseln. Im Online-Modus werden Ihre Stromzähler- und Stromverbrauchsdaten auf
                E.ON Smart Control-Servern gespeichert und verarbeitet. Den vollen Funktionsumfang des E.ON Smart
                Control-Service sowie eine genaue Erläuterung wie E.ON Smart Control Ihre Stromzähler und
                Stromverbrauchsdaten verarbeitet, können Sie Ihren Vertragsunterlagen und der Datenschutzinformation zum
                E.ON Smart Control-Service entnehmen.
                <br>
                Die WebApp ist aus technischen Gründen nur im Online-Modus nutzbar.
            </p>
        </ng-template>
        <div class="center-contents m-a-m">
            <button class="eon-button-new"
                    (click)="close(true)">
                Online-Modus erlauben
            </button>
        </div>
        <div class="center-contents">
            <a href="javascript:;" target="_self"
               class="eon-link-new"
               (click)="close()">
                Abmelden
            </a>
<!--            <eon-ui-link href="javascript:;" text="Abmelden" target="_self"-->
<!--                         (click)="close()"></eon-ui-link>-->
        </div>
    </div>
</div>
