import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';

import {ToastrService} from 'ngx-toastr';
import {Globals} from '../../services/globals.service';

import {UserService} from '../../services/user.service';
import {ApplicationService} from '../../services/application.service';
import {InitializationService} from '../../services/initialization.service';
import {ProfileService} from '../../services/profile.service';
import {VersionService} from '../../services/version.service';
import {BaseComponent} from '../../classes/base-component';
import {TrackAnalyticsService} from '../../services/track-analytics.service';
import {LocalOptInService} from '../../services/local-opt-in.service';
import {HouseholdTexts} from '../../shared/texts/household.texts';

@Component({
    selector: 'app-household',
    templateUrl: './household.component.html',
    styleUrls: ['./household.component.scss'],
    viewProviders: [],
    providers: [Globals]
})

export class HouseholdComponent extends BaseComponent implements OnInit {
    readonly TEXTS = HouseholdTexts;

    username: string = null;
    fullname: string = null;

    household: any = {
        Attributes: {
            PropertyType: null,
            PropertySize: null,
            PropertyAge: null,
            Occupants: null,
            SpaceHeating: null,
            WaterHeating: null,
            OvenHeating: null,
            GrillHeating: null,
            StoveHeating: null,
            Photovoltaic: null,
        },
        Appliances: []
    };

    propertyType = {
        title: this.TEXTS.PROPERTY_QUESTION,
        selectedValue: null,
        items: [
            {title: 'freistehendes Haus', iconName: 'PT.01'},
            {title: 'Doppelhaushälfte', iconName: 'PT.02'},
            {title: 'Bungalow', iconName: 'PT.03'},
            {title: 'Reihenhaus', iconName: 'PT.04'},
            {title: 'Reiheneckhaus', iconName: 'PT.05'},
            {title: 'Wohnung', iconName: 'PT.06'},
        ]
    };

    propertySize = {
        title: this.TEXTS.SIZE_QUESTION,
        selectedValue: null,
        items: [
            {title: '1 Zimmer', iconName: 'PS.01'},
            {title: '2 Zimmer', iconName: 'PS.02'},
            {title: '3 Zimmer', iconName: 'PS.03'},
            {title: '4+ Zimmer', iconName: 'PS.04'},
        ]
    };

    propertyAge = {
        title: this.TEXTS.AGE_QUESTION,
        selectedValue: null,
        items: [
            {title: 'Vor 1919', iconName: 'PA.01'},
            {title: '1920 - 1975', iconName: 'PA.02'},
            {title: '1979 - 1999', iconName: 'PA.03'},
            {title: 'Nach 2000', iconName: 'PA.04'},
        ]
    };

    occupants = {
        title: this.TEXTS.OCCUPANTS_QUESTION,
        selectedValue: null,
        items: [
            {title: '1', iconName: 'OCC.01'},
            {title: '2', iconName: 'OCC.02'},
            {title: '3', iconName: 'OCC.03'},
            {title: '4', iconName: 'OCC.04'},
            {title: '5+', iconName: 'OCC.05'},
        ]
    };

    spaceHeating = {
        title: this.TEXTS.SPACE_HEATING_QUESTION,
        selectedValue: null,
        items: [
            {title: 'Gasheizung', iconName: 'SH.01'},
            // removed 28.09.2021 - re-enabled 21.07.2023
            {title: 'Wärmespeicher', iconName: 'SH.02'},
            {title: 'Elektroheizung', iconName: 'SH.03'},
            {title: 'Wärmepumpe', iconName: 'SH.04'},
            {title: 'Ölheizung', iconName: 'SH.05'},
            {title: 'Solarzelle', iconName: 'SH.06'},
            {title: 'Hybrid-Wärmepumpe', iconName: 'SH.08'},
            {title: 'Fernwärme', iconName: 'SH.09'},
            {title: 'Holzpellets', iconName: 'SH.10'},
            {title: 'Andere', iconName: 'SH.07'},
        ]
    };

    waterHeating = {
        title: this.TEXTS.WATER_HEATING_QUESTION,
        selectedValue: null,
        items: [
            {title: 'Gasheizkessel', iconName: 'WH.01'},
            {title: 'Elektrischer Boiler', iconName: 'WH.02'},
            // removed 28.09.2021 - re-enabled 21.07.2023
            {title: 'Ölheizkessel', iconName: 'WH.03'},
            {title: 'Solartherme', iconName: 'WH.04'},
            {title: 'Andere', iconName: 'WH.05'},
        ]
    };

    photovoltaic = {
        title: this.TEXTS.PHOTOVOLTAIC_QUESTION,
        selectedValue: null,
        items: [
            {title: 'Ja', iconName: 'SOL.01'},
            {title: 'Nein', iconName: 'SOL.02'},
        ]
    };

    appliances = {
        title: this.TEXTS.APPLIANCES_QUESTION,
        items: [
            {title: 'Gefrierkombination', iconName: 'A.03', count: 0, selected: false},
            {title: 'Kühlschrank', iconName: 'A.01', count: 0, selected: false},
            {title: 'Gefrierschrank', iconName: 'A.02', count: 0, selected: false},
            {title: 'Elektrobackofen', iconName: 'A.04', count: 0, selected: false},
            {title: 'Elektrogrill', iconName: 'A.05', count: 0, selected: false},
            {title: 'Elektroherd', iconName: 'A.06', count: 0, selected: false},
            {title: 'Mikrowelle', iconName: 'A.07', count: 0, selected: false},
            {title: 'Wasserkocher', iconName: 'A.08', count: 0, selected: false},
            {title: 'Toaster', iconName: 'A.09', count: 0, selected: false},
            {title: 'Spülmaschine', iconName: 'A.10', count: 0, selected: false},
            {title: 'Waschmaschine', iconName: 'A.11', count: 0, selected: false},
            {title: 'Trockner', iconName: 'A.12', count: 0, selected: false},
            {title: 'Bügeleisen', iconName: 'A.13', count: 0, selected: false},
            {title: 'TV', iconName: 'A.14', count: 0, selected: false},
            {title: 'DVD oder Bluray Player', iconName: 'A.15', count: 0, selected: false},
            {title: 'Digitale TV Box', iconName: 'A.16', count: 0, selected: false},
            {title: 'Spielkonsole', iconName: 'A.17', count: 0, selected: false},
            {title: 'Computer', iconName: 'A.18', count: 0, selected: false},
            {title: 'Tablet', iconName: 'A.19', count: 0, selected: false},
            {title: 'Durchlauferhitzer', iconName: 'A.20', count: 0, selected: false},
            {title: 'Wallbox', iconName: 'A.21', count: 0, selected: false},
            {title: 'Pool', iconName: 'A.22', count: 0, selected: false},
            {title: 'Sauna', iconName: 'A.23', count: 0, selected: false},
            {title: 'Klimaanlage', iconName: 'A.25', count: 0, selected: false},
            {title: 'Warmwasserboiler', iconName: 'A.27', count: 0, selected: false},
            {
                title: 'Warmwasserboiler (Untertischgerät)',
                iconName: 'A.28',
                count: 0,
                selected: false
            },
            {title: 'Batteriespeicher (kWh)', iconName: 'A.29', count: 0, selected: false},
        ]
    };

    state = 'none';


    constructor(
        private title: Title,
        private toastrService: ToastrService,
        private globals: Globals,
        private userService: UserService,
        public application: ApplicationService,
        private initialization: InitializationService,
        private profile: ProfileService,
        private versionService: VersionService,
        private analytics: TrackAnalyticsService,
        private optInService: LocalOptInService
    ) {
        super();
    }


    ngOnInit() {
        this.title.setTitle('Mein Haushalt | E.ON Smart Control');
        this.optInService.checkStatus();
        this.username = this.userService.getActiveUserName();

        this.initialize();
    }


    initialize(): void {
        this.addSub(this.initialization.get().subscribe((res) => {
            if (res) {
                if ('profile' in res) {
                    this.processContractData(res);
                }
            }
        }));

        this.addSub(this.profile.getAttributes().subscribe((res) => {
            if (res) {
                this.processProfileData(res);
            }
        }));
    }


    determineAppliancesSelected(): boolean {
        for (const element of this.appliances.items) {
            if (element.selected) {
                return true;
            }
        }
        return false;
    }


    determineImagePath(element, currentItem): string {
        const state = element.selectedValue === currentItem.iconName ? 'active' : 'inactive';
        return `/assets/img/icons/household/${state}/${currentItem.iconName}.png`;
    }


    determineApplianceImagePath(element, currentItem): string {
        const state = currentItem.selected ? 'active' : 'inactive';
        return `/assets/img/icons/household/${state}/${currentItem.iconName}.png`;
    }


    onCountChanged(item, value): void {
        // if (value === 0) {
        //     item.selected = false;
        // }
    }


    /**
     * neue Settings speichern
     */
    saveSettings() {
        let errors: any = [];

        // if ((this.household.Appliances['A.04'] > 0) && (this.household.Attributes.OvenHeating === 'OH.00')) {
        //     errors.push('Ihre Angaben zum Backofen sind widersprüchlich!');
        // } else if ((this.household.Appliances['A.04'] === 0) && (this.household.Attributes.OvenHeating !== 'OH.00')) {
        //     errors.push('Ihre Angaben zum Backofen sind widersprüchlich!');
        // }
        //
        // if ((this.household.Appliances['A.05'] > 0) && (this.household.Attributes.GrillHeating === 'GH.00')) {
        //     errors.push('Ihre Angaben zum Grill sind widersprüchlich!');
        // } else if ((this.household.Appliances['A.05'] === 0) && (this.household.Attributes.GrillHeating !== 'GH.00')) {
        //     errors.push('Ihre Angaben zum Grill sind widersprüchlich!');
        // }
        //
        // if ((this.household.Appliances['A.06'] > 0) && (this.household.Attributes.StoveHeating === 'STH.00')) {
        //     errors.push('Ihre Angaben zum Herd sind widersprüchlich!');
        // } else if ((this.household.Appliances['A.06'] === 0) && (this.household.Attributes.StoveHeating !== 'STH.00')) {
        //     errors.push('Ihre Angaben zum Herd sind widersprüchlich!');
        // }

        const Appliances = {};
        for (const el of this.appliances.items) {
            if (el.selected) {
                Appliances[el.iconName] = el.count; // : null;
            }
        }
        const Attributes = {
            // GrillHeating: null, // 'GH.00',
            Occupants: this.occupants.selectedValue,
            // OvenHeating: null, // 'OH.00',
            PropertyAge: this.propertyAge.selectedValue,
            // PropertyLocation: null, // '45128',
            PropertySize: this.propertySize.selectedValue,
            PropertyType: this.propertyType.selectedValue,
            SpaceHeating: this.spaceHeating.selectedValue,
            // StoveHeating: null, // 'STH.00',
            WaterHeating: this.waterHeating.selectedValue,
            Photovoltaic: this.photovoltaic.selectedValue

        };

        const payload = {Appliances, Attributes};

        if (errors.length > 0) {
            for (let error of errors) {
                this.toastrService.warning(error);
            }
        } else {
            // Wenn Onboarding-Prozess - Google Analytics Event
            if (this.globals.getIsOnboarding()) {
                this.analytics.trackEvent({
                    action: 'onboarding_profile_end',
                    properties: {
                        category: 'Onboarding'
                    }
                });
                this.globals.resetIsOnboarding();
            }

            if (!this.application.isDemoMode()) {
                this.profile.setAttributes(payload).subscribe(
                    // this.profile.setAttributes(this.household).subscribe(
                    (response) => {
                        this.toastrService.success('Ihre Angaben zum Haushalt wurden übernommen!');
                    },
                    (error) => {
                        this.toastrService.error('Hoppla, da ist etwas schief gelaufen.', 'Fehler');
                        const error_obj = error.error;
                        if (error_obj.error.code === 180) {
                            console.log('Error:', error);
                        }
                    }
                );
            } else {
                this.toastrService.info('Die Angaben zum Haushalt können im Demo-Modus nicht verändert werden!');
            }
        }
    }


    /**
     * Process contract data
     * @param data
     */
    private processContractData(data): void {
        if (this.application.isDemoMode()) {
            this.fullname = 'Frau Maxi Mustermann';
        } else {
            this.fullname = data.profile.customer_name;
        }
    }


    /**
     * Process Profile information
     * @param attributes
     */
    private processProfileData(attributes): void {
        if ('Attributes' in attributes) {
            this.propertyType.selectedValue = attributes.Attributes.PropertyType;
            this.propertySize.selectedValue = attributes.Attributes.PropertySize;
            this.propertyAge.selectedValue = attributes.Attributes.PropertyAge;
            this.occupants.selectedValue = attributes.Attributes.Occupants;
            this.spaceHeating.selectedValue = attributes.Attributes.SpaceHeating;
            this.waterHeating.selectedValue = attributes.Attributes.WaterHeating;
            this.photovoltaic.selectedValue = attributes.Attributes.Photovoltaic;

            for (const element of this.appliances.items) {
                element.count = attributes.Appliances[element.iconName];
                element.selected = attributes.Appliances[element.iconName] > 0;
            }
        }
    }

}
