<div class="overlay">
    <div class="detail-overlay">
        <div class="detail-view">

            <section class="detail-head">
                <header class="detail-header">
                    <h1 class="detail-heading">Geräte</h1>

                    <button angulartics2On="click" angularticsCategory="Detailansichten"
                            angularticsAction="detail_info_text" angularticsLabel="Meine Geräte"
                            (click)="infoVisible = !infoVisible">
                        <i class="info-sm dark-grey"></i>
                    </button>

                    <button (click)="close()">
                        <i class="close-sm dark-grey"></i>
                    </button>
                </header>
            </section>

            <section class="detail-info" *ngIf="infoVisible">
                <div class="info-icon">
                    <i class="info-sm dark-grey"></i>
                </div>
                <div class="info-text">
                    <ng-container [ngSwitch]="currentDisplayMode">
                        <ng-container *ngSwitchCase="DisplayMode.DATA">
                            <h2 class="font-bold">{{TEXTS.INFO.TITLE}}</h2>
                            <p *ngFor="let paragraph of TEXTS.INFO.PARAGRAPHS">
                                {{paragraph}}
                            </p>
                        </ng-container>
                        <ng-container *ngSwitchCase="DisplayMode.RETRAINING">
                            <h2 class="font-bold">{{TEXTS.INFO_ALT.TITLE}}</h2>
                            <p *ngFor="let paragraph of TEXTS.INFO_ALT.PARAGRAPHS">
                                {{paragraph}}
                            </p>
                        </ng-container>
                    </ng-container>
                </div>
            </section>

            <!--            <section class="profile-update-reminder" *ngIf="showProfileUpdateReminder && profileUpdateReminderVisible">-->
            <!--                <div class="icon">-->
            <!--                    <i class="info-sm dark-grey"></i>-->
            <!--                </div>-->
            <!--                <div class="banner-content">-->
            <!--                    <p>-->
            <!--                        Bitte prüfen Sie Ihr Haushaltsprofil auf Aktualität.-->
            <!--                    </p>-->
            <!--                    <button class="eon-button-new turquoise"-->
            <!--                            (click)="routeToProfile()">-->
            <!--                        Mein Haushaltsprofil prüfen-->
            <!--                    </button>-->
            <!--                </div>-->
            <!--                <div class="close">-->
            <!--                    <button (click)="hideReminder()">-->
            <!--                        <i class="close-s dark-grey"></i>-->
            <!--                    </button>-->
            <!--                </div>-->
            <!--            </section>-->

            <!-- BANNER -->
            <ng-container *ngIf="detailBanner$ | async as detailBannerVisibility">
                <app-detail-banner
                    *ngIf="detailBannerVisibility.showProfileUpdateBanner && profileUpdateBannerVisible"
                    [bannerData]="profileUpdateBanner"
                    (hideBanner)="hideProfileUpdateBanner()"
                    (bannerInteraction)="onProfileUpdateBannerInteraction()">
                </app-detail-banner>
                <app-detail-banner
                    *ngIf="detailBannerVisibility.showRetrainingBanner"
                    [bannerData]="retrainingBanner"
                    (sideButtonInteraction)="showRetrainingView()">
                </app-detail-banner>
            </ng-container>


            <!-- TAB BAR -->
            <div class="tab-bar">
                <div [class.active]="currentMode === DataMode.MONTH && currentDisplayMode === DisplayMode.DATA"
                     (click)="setMode(DataMode.MONTH)">
                    {{LABELS.TAB_TITLE_OVERVIEW_MONTHS}}
                </div>
                <div [class.active]="currentMode === DataMode.YEAR && currentDisplayMode === DisplayMode.DATA"
                     (click)="setMode(DataMode.YEAR)">
                    {{LABELS.TAB_TITLE_OVERVIEW_YEARS}}
                </div>
                <div class="grow-space"></div>
                <ng-container *ngIf="currentNilmPhaseAllowsRetraining$ | async as retrainingAllowed">
                    <ng-container *ngIf="retrainingAllowed">
                        <div [class.active]="currentDisplayMode === DisplayMode.RETRAINING"
                             (click)="setDisplayMode(DisplayMode.RETRAINING)">
                            {{LABELS.TAB_TITLE_RETRAINING}}
                        </div>
                    </ng-container>
                </ng-container>
            </div>

            <section class="detail-content">

                <ng-template
                    [cdkPortalOutlet]="selectedPortal"
                    (attached)="onComponentAttachedToPortal($event)">
                </ng-template>

                <!--                -->
                <!--                <div class="diagram-navigation">-->
                <!--                    -->
                <!--                    <button class="eon-icon-button small turquoise"-->
                <!--                            [disabled]="interactionDisabled"-->
                <!--                            (click)="positionBack()">-->
                <!--                        <i class="chevron-left-s white"></i>-->
                <!--                    </button>-->
                <!--                    <div class="current-timeframe">{{currentTimeframe}}</div>-->
                <!--                    <button class="eon-icon-button small turquoise"-->
                <!--                            [disabled]="interactionDisabled || offsetMonths === 0"-->
                <!--                            (click)="positionForward()">-->
                <!--                        <i class="chevron-right-s white"></i>-->
                <!--                    </button>-->
                <!--                </div>-->

                <!--                <div class="appliances-details-diagram-container">-->
                <!--                    <div class="appliance-diagram-new">-->
                <!--                        <app-appliance-chart #applianceChart-->
                <!--                                             [isDetail]="true">-->
                <!--                        </app-appliance-chart>-->
                <!--                    </div>-->
                <!--                </div>-->

                <!--                <ul class="appliances-list">-->
                <!--                    <li *ngFor="let category of applianceCategories"-->
                <!--                        (click)="toggleAccordion(category)">-->
                <!--                        <div class="li-content-wrapper">-->
                <!--                            <div class="list-icon-wrapper">-->
                <!--                                <div class="list-icon"-->
                <!--                                     [ngStyle]="{-->
                <!--                                         'mask-image': 'url(/assets/img/graphics/appliances/'+ category.name.toLowerCase() + '.svg)',-->
                <!--                                        '-webkit-mask-image': 'url(/assets/img/graphics/appliances/'+ category.name.toLowerCase() + '.svg)',-->
                <!--                                        'background-color': category.color-->
                <!--                                     }">-->
                <!--                                </div>-->
                <!--                                <div *ngIf="!category.categoryProfileComplete"-->
                <!--                                     class="attention-indicator">-->
                <!--                                </div>-->
                <!--                            </div>-->
                <!--                            <div class="grow-space"-->
                <!--                                 [style.color]="category.color">-->
                <!--                                {{ translateAppliance(category.name) }}-->
                <!--                            </div>-->
                <!--                            <div>{{ category.kwh }} kWh</div>-->
                <!--                            <div class="cost">{{ category.cost }}</div>-->
                <!--                            <div class="accordion-indicator">-->
                <!--                                <i [ngClass]="{rotated: category.accordionOpen}"></i>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                        <div class="accordion" [hidden]="!category.accordionOpen">-->
                <!--                            <div class="category-list">-->
                <!--                                <div *ngFor="let item of category.appliances">-->
                <!--                                    <div class="sub-icon"-->
                <!--                                         [style.mask-image]="determineApplianceCategoryItemPath(item.icon)"-->
                <!--                                         [style.-webkit-mask-image]="determineApplianceCategoryItemPath(item.icon)">-->
                <!--                                    </div>-->
                <!--                                    <div class="sub-name">{{translateAppliance(item.appliance_instance_id)}}</div>-->
                <!--                                    <div class="sub-value"><span>kWh</span> <br> {{item.energy_ws}} </div>-->
                <!--                                    <div class="sub-value"><span>€</span> <br> {{item.cost}} </div>-->
                <!--                                    <div class="spacer"></div>-->
                <!--                                </div>-->
                <!--                            </div>-->
                <!--                            <p>-->
                <!--                                {{category.description}}-->
                <!--                            </p>-->
                <!--                        </div>-->
                <!--                    </li>-->
                <!--                </ul>-->

            </section>

        </div>
    </div>
</div>
