<div class="overlay">
    <div class="detail-overlay" style="overflow: hidden">
        <div class="detail-view">

            <section class="detail-head">
                <header class="detail-header">
                    <h1 class="detail-heading">Vergleich</h1>

                    <button angulartics2On="click" angularticsCategory="Detailansichten"
                            angularticsAction="detail_info_text" angularticsLabel="Vergleich"
                            (click)="infoVisible = !infoVisible">
                        <i class="info-sm dark-grey"></i>
                    </button>

                    <button (click)="close()">
                        <i class="close-sm dark-grey"></i>
                    </button>
                </header>
            </section>

            <section class="detail-info" *ngIf="infoVisible">
                <div class="info-icon">
                    <i class="info-sm dark-grey"></i>
                </div>
                <div class="info-text">
                    <h2 class="eon-black">Vergleich: Was ist das?</h2>
                    <p>
                        E.ON Smart Control liebt Statistiken. Vergleichen Sie doch einfach mal Ihren Energieverbrauch
                        mit dem Vortag, der Vorwoche, dem Vormonat oder dem Vorjahr. EON Smart Control vergleicht Ihnen
                        alles - nur nicht Äpfel mit Birnen. Daher wird auf der Kachel auch nur der zum aktuellen Monat
                        passende Vergleichszeitraum angezeigt. </p>
                </div>
            </section>

            <div class="tab-bar">
                <div [class.active]="currentMode === comparisonMode.STATIC"
                     (click)="setMode(comparisonMode.STATIC)">
                    Statisch
                </div>
                <div [class.active]="currentMode === comparisonMode.DYNAMIC"
                     (click)="setMode(comparisonMode.DYNAMIC)">
                    Anpassbar
                </div>
                <div class="grow-space"></div>
            </div>

            <section class="detail-content">
                <div class="upper-timeframe-select">
                    <div *ngFor="let s of seriesLegend" class="center-contents legend-item">
                        <span class="color-dot" [style.background]="s.color"></span> {{s.name}}
                    </div>

                    <!-- TIMEFRAME SELECTOR -->
                    <div>
                        <div class="eon-dropdown">
                            <label for="timeframe">Zeitraum</label>
                            <select id="timeframe" (change)="setTimeframe($event.target.value)"
                                    angulartics2On="change"
                                    angularticsCategory="Detailansichten"
                                    angularticsAction="detail_time_interval_change"
                                    angularticsLabel="Vergleich - {{ currentMode === comparisonMode.STATIC ? 'Statisch' : 'Anpassbar' }}">
                                <option [value]="comparisonTimeframe.DAY">Tag</option>
                                <option [value]="comparisonTimeframe.WEEK">Woche</option>
                                <option [value]="comparisonTimeframe.MONTH" selected>Monat</option>
                                <option [value]="comparisonTimeframe.YEAR">Jahr</option>
                            </select>
                            <span></span>
                        </div>
                    </div>

                    <div class="p-h-s"></div>

                    <!-- DISPLAY MODE SELECTOR -->
                    <div>
                        <div class="eon-dropdown">
                            <label for="displayType">Anzeigetyp</label>
                            <select #displayTypeSelect id="displayType"
                                    (change)="setDisplayMode($event.target.value)">
                                <option [value]="comparisonDisplayMode.CONSUMPTION"
                                        [selected]="currentDisplayMode === comparisonDisplayMode.CONSUMPTION">
                                    Verbrauch
                                </option>
                                <option [value]="comparisonDisplayMode.COST"
                                        [selected]="currentDisplayMode === comparisonDisplayMode.COST">
                                    Kosten
                                </option>
                                <ng-container *ngIf="!application.isDemoMode()">
                                    <option [value]="comparisonDisplayMode.FEEDIN">
                                        Einspeisung
                                    </option>
                                </ng-container>
                            </select>
                            <span></span>
                        </div>
                    </div>
                </div>

                <app-comparison-chart (chartLoaded)="onChartLoaded($event)"
                                      (seriesAdded)="updateLegend($event)">
                </app-comparison-chart>

                <div class="diagram-controls" [ngSwitch]="currentMode">
                    <div *ngSwitchCase="comparisonMode.STATIC" class="comparison-static-diagram-controls">
                        <div class="left">
                            <button class="eon-icon-button small turquoise"
                                    [disabled]="interactionDisabled"
                                    (click)="positionBack()">
                                <i class="chevron-left-s white"></i>
                            </button>
                        </div>
                        <div class="base-price-display">
                            <div *ngIf="currentDisplayMode === comparisonDisplayMode.COST">
                                Kosten zzgl. Grundpreis:
                                <strong>{{currentBasePrice | async}}</strong>
                            </div>
                        </div>
                        <div class="right-controls">
                            <button class="eon-icon-button small turquoise"
                                    [disabled]="interactionDisabled || positionForwardDisabled()"
                                    (click)="positionForward()">
                                <i class="chevron-right-s white"></i>
                            </button>
                            <div class="m-h-s"></div>
                            <button class="eon-button-new small turquoise"
                                    [disabled]="interactionDisabled || positionForwardDisabled()"
                                    (click)="positionNow()">
                                Jetzt
                            </button>
                        </div>
                    </div>

                    <div *ngSwitchCase="comparisonMode.DYNAMIC">
                        <div class="comparison-dynamic-diagram-controls">
                            <div>
                                <div class="eon-dropdown" *ngIf="currentTimeframe === comparisonTimeframe.DAY">
                                    <label for="left_day">Tag</label>
                                    <select id="left_day" (change)="setComparisonDate('lhsDay', $event.target.value)">
                                        <option *ngFor="let day of loop(1, 31)" value="{{ day }}"
                                                [selected]="dynamicComparisonDates.lhsDay === day">{{ day }}</option>
                                    </select>
                                    <span></span>
                                </div>

                                <div class="eon-dropdown" *ngIf="currentTimeframe === comparisonTimeframe.WEEK">
                                    <label for="left_kw">Kalenderwoche</label>
                                    <select id="left_kw" (change)="setComparisonDate('lhsCalendarWeek', $event.target.value)">
                                        <option *ngFor="let kw of loop(1, 52)" value="{{ kw }}"
                                                [selected]="dynamicComparisonDates.lhsCalendarWeek === kw">
                                            KW {{ kw }}</option>
                                    </select>
                                    <span></span>
                                </div>

                                <div class="eon-dropdown"
                                     *ngIf="(currentTimeframe === comparisonTimeframe.DAY) || (currentTimeframe === comparisonTimeframe.MONTH)">
                                    <label for="left_month">Monat</label>
                                    <select id="left_month"
                                            (change)="setComparisonDate('lhsMonth', $event.target.value)">
                                        <option *ngFor="let month of MONTHS; let i = index" value="{{ i + 1 }}"
                                                [selected]="dynamicComparisonDates.lhsMonth === (i + 1)">{{ month }}</option>
                                    </select>
                                    <span></span>
                                </div>

                                <div class="eon-dropdown">
                                    <label for="left_year">Jahr</label>
                                    <select id="left_year"
                                            (change)="setComparisonDate('lhsYear', $event.target.value)">
                                        <option *ngFor="let year of loop(year - 4, 5)" value="{{ year }}"
                                                [selected]="dynamicComparisonDates.lhsYear === year">{{ year }}</option>
                                    </select>
                                    <span></span>
                                </div>
                            </div>

                            <div>
                                <div class="eon-dropdown" *ngIf="currentTimeframe === comparisonTimeframe.DAY">
                                    <label for="right_day">Tag</label>
                                    <select id="right_day"
                                            (change)="setComparisonDate('rhsDay', $event.target.value)">
                                        <option *ngFor="let day of loop(1, 31)" value="{{ day }}"
                                                [selected]="dynamicComparisonDates.rhsDay === day">{{ day }}</option>
                                    </select>
                                    <span></span>
                                </div>

                                <div class="eon-dropdown" *ngIf="currentTimeframe === comparisonTimeframe.WEEK">
                                    <label for="right_kw">Kalenderwoche</label>
                                    <select id="right_kw" (change)="setComparisonDate('rhsCalendarWeek', $event.target.value)">
                                        <option *ngFor="let kw of loop(1, 52)" value="{{ kw }}"
                                                [selected]="dynamicComparisonDates.rhsCalendarWeek === kw">
                                            KW {{ kw }}</option>
                                    </select>
                                    <span></span>
                                </div>

                                <div class="eon-dropdown"
                                     *ngIf="(currentTimeframe === comparisonTimeframe.DAY) || (currentTimeframe === comparisonTimeframe.MONTH)">
                                    <label for="right_month">Monat</label>
                                    <select id="right_month"
                                            (change)="setComparisonDate('rhsMonth', $event.target.value)">
                                        <option *ngFor="let month of MONTHS; let i = index" value="{{ i + 1 }}"
                                                [selected]="dynamicComparisonDates.rhsMonth === (i + 1)">{{ month }}</option>
                                    </select>
                                    <span></span>
                                </div>

                                <div class="eon-dropdown">
                                    <label for="right_year">Jahr</label>
                                    <select id="right_year"
                                            (change)="setComparisonDate('rhsYear', $event.target.value)">
                                        <option *ngFor="let year of loop(year - 4, 5)" value="{{ year }}"
                                                [selected]="dynamicComparisonDates.rhsYear === year">{{ year }}</option>
                                    </select>
                                    <span></span>
                                </div>
                            </div>

                        </div>

                        <div class="base-price-display"
                             [class.no-display]="currentDisplayMode !== comparisonDisplayMode.COST">
                            <div *ngIf="currentDisplayMode === comparisonDisplayMode.COST">
                                Kosten zzgl. Grundpreis:
                                <strong>{{currentBasePrice | async}}</strong>
                            </div>
                        </div>

                    </div>
                </div>

            </section>

        </div>
    </div>
</div>
