<div class="overlay-small">
    <div class="overlay-container">
        <h1>Möchten Sie Ihr <br> Passwort zurücksetzten?</h1>
        <p>
            Geben Sie hier Ihr neues Passwort ein.
        </p>

        <form [formGroup]="form" (ngSubmit)="submitChange()">
            <fieldset class="eon-input">
                <legend>Altes Passwort</legend>
                <input id="oldPassword" class="eon-input" type="password" formControlName="oldPassword">
                <label for="oldPassword"></label>
            </fieldset>

            <fieldset class="eon-input">
                <legend>Neues Passwort</legend>
                <input id="newPassword1st" class="eon-input" type="password" formControlName="newPassword1">
                <label for="newPassword1st"></label>
            </fieldset>

            <div class="p-v-t"></div>

            <p class="password-info">
                Das Passwort muss aus 8 Stellen bestehen und mindestens einen Großbuchstaben sowie eine Zahl
                enthalten.
            </p>
            <div class="password-strength">
                <div class="password-strength-indicator">
                            <span *ngFor="let i of [0,1,2,3,4]"
                                  [style.background]="determinePasswordStrengthIndicatorColor(i)"
                                  [class.active]="i === currentPasswordScore"></span>
                </div>
                <div class="password-score-label eon-regular">
                    Passwort: <span class="eon-bold"
                                    [style.color]="determinePasswordStrengthColor()">
                            {{determinePasswordScoreRatingText()}}</span>
                </div>
            </div>

            <fieldset class="eon-input">
                <legend>Neues Passwort (wiederholen)</legend>
                <input id="newPassword2nd" class="eon-input" type="password" formControlName="newPassword2">
                <label for="newPassword2nd"></label>
            </fieldset>

            <button class="eon-button-new"
                    type="submit"
                    [disabled]="submitDisabled">
                Ändern
            </button>
        </form>
    </div>
    <div class="close-overlay-control center-contents" (click)="close(false)">
        <i class="close-s dark-grey"></i>
    </div>
</div>
