<div id="container">
    <aside class="sidebar lft">
        <a href="#" id="hamburger" title="Menü" class="mobi">
            <span class="burger">
                <i class="top"></i>
                <i class="middle"></i>
                <i class="bottom"></i>
            </span>
            <span class="text"> Menü</span>
        </a>

        <app-menu activeElement="help"></app-menu>
    </aside>

    <div id="inner">
        <main class="page">
            <div class="page-card">
                <iframe #frame class="help-iframe"
                        (load)="onFrameLoaded()"></iframe>
            </div>
        </main>
    </div>

</div>
