import {AfterViewChecked, Component, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../services/api.service';
import {Globals} from '../../services/globals.service';
import {UserService} from '../../services/user.service';
import {AnimationItem} from 'lottie-web';
import {constants, validVoucherPrefixes} from '../../shared/constants/constants';
import {ApplicationService} from '../../services/application.service';
import {RegistrationService} from '../../services/registration.service';
import {AuthService} from '../../services/auth.service';
import * as moment from 'moment';
import {onboardingSteps} from '../../shared/constants/onboarding.constants';
import {macFormat} from '../../shared/constants/misc.constants';
import {MeterService} from '../../services/meter.service';
import {TrackAnalyticsService, TRACKING_EVENT} from '../../services/track-analytics.service';
import {HeartbeatService} from '../../services/heartbeat.service';
import {Popover} from '../../popovers/popover/popover.service';
import {
    FirmwareUpdateAvailablePopover,
    FirmwareUpdatePopover,
    ManualPinEntryInfoPopoverConfig,
    ManualPinEntryPopoverConfig,
    OnboardingNoContactPopoverConfig,
    OnboardingWrongSerialPopoverConfig,
    PinEntryPopoverConfig,
    PinFailedPopoverConfig,
    RadioLinkLostPopover
} from '../../popovers/static.popover.config';
import {OpticalReaderService} from '../../services/optical-reader.service';
import {of, retryWhen, Subject, takeUntil, throwError, timer} from 'rxjs';
import {catchError, map, mergeMap, switchMap, tap, concatMap} from 'rxjs/operators';
import {FirmwareUpdateService} from '../../services/firmware-update.service';
import {MeterStatuses} from '../../shared/enums/meter-statuses.enum';
import {TextMaskConfig} from 'angular2-text-mask';
import {StorageAttributes} from '../../shared/constants/storage-attributes.constants';
import {LocalOptInService} from '../../services/local-opt-in.service';
import {
    FormControl,
    FormGroup,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import zxcvbnCommonPackage from '@zxcvbn-ts/language-common';
import {zxcvbn, zxcvbnOptions} from '@zxcvbn-ts/core';
import {AnimationOptions} from 'ngx-lottie';
import {OptInService} from '../../services/opt-in.service';
import {ErrorDialogComponent} from '../../components/error-dialog/error-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {SupportModalComponent} from '../../components/support-modal/support-modal.component';

@Component({
    selector: 'iona-app',
    templateUrl: './register.component.html',
    styleUrls: [
        'register-base.component.scss',
        'register.component.scss',
        'register-connection.component.scss',
        'register-sections.component.scss',
    ],
    viewProviders: [ApiService],
    providers: [Globals]
})
export class RegisterComponent implements OnInit, OnDestroy, AfterViewChecked {


    constructor(
        public apiService: ApiService,
        private title: Title,
        private router: Router,
        private route: ActivatedRoute,
        private toast: ToastrService,
        private globals: Globals,
        private userService: UserService,
        private application: ApplicationService,
        private registration: RegistrationService,
        private auth: AuthService,
        private meter: MeterService,
        private tracking: TrackAnalyticsService,
        private heartbeat: HeartbeatService,
        private popover: Popover,
        private opticalReader: OpticalReaderService,
        private updateService: FirmwareUpdateService,
        private localOptInService: LocalOptInService,
        private optInService: OptInService,
        public dialog: MatDialog
    ) {
    }

    private readonly plugMacValidationRegex = /^[0-9A-Fa-f]{12}$/g;
    private readonly opticalReaderMacValidationRegex = /^([0-9A-Fa-f]{2}[:-]?){7}([0-9A-Fa-f]{2})$/g;
    private readonly registrationOnboardRetryDuration = 300000;
    private readonly registrationOnboardRetryInterval = 5000;
    private readonly firmwareUpdateSearchDuration = 300000;
    private readonly firmwareUpdateSearchInterval = 5000;
    private readonly firmwareUpdateMaxDuration: 7200000;

    private popoverOpen = false;
    private preventPinErrorPopover = false;
    private preventPinInfoPopover = false;
    private meterPin = null;

    private previousStatus = '';
    private registrationTries = 0;
    private registrationSub = null;
    private lastRegistrationCall = new Date().getTime();

    private passwordRegex = /^(?=.*?[A-Z])(?!.*[ßüöäÜÖÄ])(?=.*?[0-9]).{8,}$/;
    private zxcvbnLocalOptions = {
        graphs: zxcvbnCommonPackage.adjacencyGraphs,
        dictionary: {
            ...zxcvbnCommonPackage.dictionary,
        },
    };

    readonly onboardingSteps = onboardingSteps;
    readonly moment = moment;
    readonly macAddressMask: TextMaskConfig = {
        mask: [
            /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, ':',
            /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, ':',
            /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/
        ]
    };
    readonly macPlaceholder: string = '15E1:8000:89F1';
    readonly opticalReaderMacAddressMask: TextMaskConfig = {
        mask: [
            /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, ':',
            /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, ':',
            /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, ':',
            /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/
        ]
    };
    readonly opticalReaderMacPlaceholder: string = '15E1:8000:89F1:0805';

    refresh: any = [];
    checkMeterStatusInterval: any = [];

    currentStep = this.onboardingSteps.accountCreation;
    meterStatus = 0;

    device = 'box';
    deviceTitle: string;

    // structure the amount of failed connections by status
    statusTries = {step0: 0, step1: 0, step2: 0};
    // status error to be displayed
    statusError = {title: '', text: ''};
    connect = {type: null, tries: 0};
    // registered user
    user = {voucher: null, email: null, password: null, mac: null};
    format = macFormat;
    input: any = {mac: ''};
    private noDataPopoverShown = false;

    // animation
    lottieConfig2: AnimationOptions = {
        path: 'assets/anim/onboarding_connection.json',
        autoplay: false,
        name: 'Smart Meter Animation',
        loop: true,
        // renderer: 'canvas'
    };
    loadingLottieConfig = {
        path: 'assets/anim/onboarding-loading.json',
        autoplay: true,
        name: 'Loading Animation',
        loop: true,
        renderer: 'svg'
    };
    animationReady = false;
    anim: AnimationItem;

    isEDGUser = false;
    readerPIN: number = null;
    pinEntrySubmissionDisabled = true;

    plugMac = null;
    deviceMac = null;
    opticalReaderMac = null;

    firstMac = null;
    secondMac = null;

    registrationOnboardSuccessful = false;
    firmwareUpdateStatus: 'loading' | 'uptodate' | 'error' = 'loading';
    commissionStatus: 'initial' | 'loading' | 'success' | 'error' = 'initial';
    firmwareIsUpdating = false;
    firmwareUpdateFinishedOrFailed = false;
    lastMeterStatus = null;

    navigationFromDashboard = false;

    passwordForm = new UntypedFormGroup({
        password1: new UntypedFormControl('', [Validators.required]),
        password2: new UntypedFormControl('', [Validators.required])
    });
    setPasswordDisabled = true;
    currentPasswordScore = 0;
    voucherForm = new UntypedFormGroup({
        email: new UntypedFormControl('', [Validators.required]),
        code: new UntypedFormControl('', [Validators.required])
    });
    deviceMacForm = new FormGroup({
        mac: new FormControl('', [
            Validators.required,
            Validators.minLength(12),
        ])
    });
    readerMacForm = new FormGroup({
        mac: new FormControl('', [
            Validators.required,
            // Validators.minLength(16),
        ])
    });


    protected readonly macFormat = macFormat;

    getDeviceTitle() {
        return this.deviceTitle === 'E.ON Smart Control Box 2.0' ? 'E.ON Smart Control Box 2.0' : 'E.ON Smart Control Stecker';
    }

    isSmartBox2() {
        return this.deviceTitle === 'E.ON Smart Control Box 2.0';
    }

    ngOnInit() {
        this.heartbeat.destroy();
        localStorage.setItem('userOnboardingInProgress', 'in_progress');
        this.title.setTitle('Registrieren | E.ON Smart Control');

        // get previously stored device
        const stored_device = this.userService.getUserDevice();
        if (stored_device !== null && stored_device !== undefined) {
            if (stored_device === constants.application.devices.box) {
                this.device = 'box';
                this.deviceTitle = 'E.ON Smart Control-Box';
            } else if (stored_device === constants.application.devices.plug) {
                this.device = 'plug';
                this.deviceTitle = 'E.ON Smart Control Stecker';
            } else if (stored_device === constants.application.devices.plug_optical) {
                this.device = 'plug';
                this.deviceTitle = 'E.ON Smart Control Stecker';
                this.isEDGUser = true;
            } else if (stored_device === constants.application.devices.smart_box ||
                stored_device === constants.application.devices.smart_box2) {
                this.device = 'plug';
                this.deviceTitle = 'E.ON Smart Control Box 2.0';
                this.isEDGUser = true;
            }
        }

        this.initializeRouteParamterHandling();

        if (this.globals.getIonaMacId() !== null && this.globals.getIonaMacId().length > 0) {
            this.input.mac = this.globals.getIonaMacId();
        }

        zxcvbnOptions.setOptions(this.zxcvbnLocalOptions);
        this.setupFormHandling();
    }


    ngOnDestroy() {
        clearInterval(this.refresh);
        clearInterval(this.checkMeterStatusInterval);
    }


    ngAfterViewChecked() {
    }

    resetMacAddressEntry(): void {
        this.deviceMacForm.setValue({mac: ''});
        this.plugMacValidationRegex.lastIndex = 0;
        this.readerMacForm.setValue({mac: ''});
        this.opticalReaderMacValidationRegex.lastIndex = 0;
        this.setStep(onboardingSteps.devices.plug.macEntry);
        this.commissionStatus = 'initial';
    }

    openSupportDialog(): void {
        const dialogRef = this.dialog.open(SupportModalComponent, {
            width: '400px',
            data: {
                text: 'ESC Dev konnte leider keine Verbindung zwischen E.ON Smart Control ' +
                    'Stecker und Optischen Ausleser herstellen. Bitte kontaktieren Sie unsere ' +
                    'Support-Team: 0800-9944021',
            showCloseButton: false,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The other modal was closed');
        });
    }

    openErrorDialog(): void {
        const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '400px',
            data: {
                title: 'Kopplung fehlgeschlagen',
                message: 'Die Verbindung zwischen Smart Control Stecker und Optischen Ausleser konnte nicht hergestellt werden. Bitte geben Sie die MAC-Adresse erneut ein oder kontaktieren Sie unseren Support.',
                showButton1: true,
                button1Text: 'Erneut eingeben',
                showButton2: true,
                button2Text: 'Support',
                showButton3: true,
                button3Text: 'FAQ',
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result === 'button1') {
                this.resetMacAddressEntry();
            } else if (result === 'button2') {
                this.openSupportDialog();
            } else if (result === 'button3') {
                this.openFAQ();
            }
        });
    }

    openFAQ(): void {
        window.open('https://app-content.n2g-iona.net/help/esc/home?showNav=true', '_blank');
    }

    hardwareNotReady(): void {
        this.apiService.logoutUser();
        this.router.navigate(['login']);
    }


    onPinChange(pin): void {
        if (pin.length >= 1) {
            this.readerPIN = parseInt(pin, 10);
            this.pinEntrySubmissionDisabled = false;
        } else {
            this.pinEntrySubmissionDisabled = true;
        }
    }


    determinePasswordScoreRatingText(): string {
        if (this.currentPasswordScore <= 2) {
            return 'unsicher';
        } else if (this.currentPasswordScore === 3) {
            return 'sicher';
        }
        return 'sehr sicher';
    }


    determinePasswordStrengthIndicatorColor(index): string {
        if (index <= this.currentPasswordScore) {
            return this.determinePasswordStrengthColor();
        }
        return 'transparent';
    }


    determinePasswordStrengthColor(): string {
        const colors = ['#b80000', '#ea1b0a', '#f05548', '#e3e000', '#1ea2b1'];
        return colors[this.currentPasswordScore];
    }


    /**
     * Voucher validieren
     */
    validateVoucher() {
        const values = this.voucherForm.value;
        const voucher = values.code;
        const email = values.email;

        const validities = validVoucherPrefixes.map(
            (el: string) => voucher.startsWith(el.toUpperCase()));
        if (validities.filter(el => el === true).length === 0) {
            this.toast.error('Ungültiger Code', 'Fehler');
            return;
        }
        this.apiService.validateVoucher(voucher.toUpperCase(), email).subscribe(
            // this.registration.validateVoucher(voucher, email).subscribe(
            () => {
                // Erfolg -> Email und Voucher im User setzen und weiter gehts zum nächsten Step
                this.user.voucher = voucher;
                this.user.email = email;
                this.setStep(this.onboardingSteps.passwordEntry);
                this.tracking.onboarding_voucher(true);
            },
            (error_res: any) => {
                if (error_res.error) {
                    switch (error_res.error.code) {
                        case 106: {
                            this.toast.error(
                                'Der eingegebene E.ON Smart Control-Code ist uns nicht bekannt!');
                            break;
                        }
                        default: {
                            this.toast.error(
                                'Validierung des E.ON Smart Control-Code fehlgeschlagen!');
                        }
                    }
                    this.tracking.onboarding_voucher(false);
                } else {
                    this.toast.error(
                        'Bitte geben Sie E-Mail-Adresse und E.ON Smart Control-Code ein!');
                }
            }
        );
    }


    /**
     * Nutzer registrieren
     */
    registerUser() {
        const values = this.passwordForm.value;
        if (values.password1 !== values.password2) {
            this.toast.error('Ihre Passwörter stimmen nicht überein!');
            return;
        }

        const user = {
            email_address: this.user.email,
            pincode: values.password1,
            voucher_code: this.user.voucher.toUpperCase()
        };

        this.apiService.registerUser(user).subscribe(
            (response) => {
                this.user.password = values.password1;
                this.apiService.loginUser(this.user.email, this.user.password, true);

                this.apiService.onLoggedIn.subscribe(() => {
                        this.heartbeat.destroy();
                        this.apiService.getRegistrationModel().subscribe(
                            (model_response: any) => {
                                if (model_response.status === 'ok') {
                                    if ('model_identifier' in model_response.data) {
                                        switch (model_response.data.model_identifier) {
                                            case constants.application.devices.plug:
                                                this.selectDevice('plug');
                                                break;
                                            case constants.application.devices.plug_optical:
                                                this.selectDevice('plug', true);
                                                break;
                                            case constants.application.devices.smart_box:
                                                this.selectDevice('plug', false, true, constants.application.devices.smart_box);
                                                break;
                                            case constants.application.devices.smart_box2:
                                                this.selectDevice('plug', false, true, constants.application.devices.smart_box2);
                                                break;
                                            default:
                                                this.selectDevice('box');
                                        }
                                    }
                                }
                            },
                            (error) => {
                                console.log('Error:', error);
                            }
                        );

                    }
                );
            },
            (err: any) => {
                if ('error' in err) {
                    if ('error' in err.error) {
                        if (err.error.error.code === 264) {
                            const msg = err.error.error.message;
                            if (msg.includes('MAX length')) {
                                this.toast.error(
                                    'Das gewählte Passwort ist zu lang. ' +
                                    'Bitte verwenden Sie maximal 16 Zeichen.',
                                    'Passwort ungültig', {timeOut: 6000});
                            } else if (msg.includes('Special characters')) {
                                this.toast.error('Das gewählte Passwort enthält unzulässige Sonderzeichen. Bitte verwenden Sie nur Buchstaben und Zahlen, sowie die folgenden Sonderzeichen: ! \" ( ) = [ ] { } ? \\ + * ~ # , ; . - _ Umlaute und ß sind nicht möglich.', 'Passwort ungültig', {timeOut: 6000});
                            }
                        }
                        if (err.error.error.code === 102) {
                            const msg = err.error.error.message;
                            if (msg.includes('already active on')) {
                                this.toast.error(
                                    'Sie haben bereits ein Konto erstellt.',
                                    'Fehler', {timeOut: 6000});
                            }

                        }
                    }
                }
                if (err._body) {
                    const error: any = JSON.parse(err._body);
                    switch (error.error.code) {
                        case 102: {
                            this.toast.info('Sie sind bereits registriert!');
                            this.setStep(this.onboardingSteps.devices.box.powerConnect);
                            break;
                        }
                        case 264: {
                            console.log('CASE 264 ');
                            break;
                        }
                        default: {
                            this.toast.error('Registrierung fehlgeschlagen!');
                        }
                    }
                } else {
                    this.toast.error('Registrierung fehlgeschlagen!');
                }
            }
        );
    }


    /**
     * Meter registrieren
     * @param mac
     * @param doThrowError
     */
    registerDevice(mac: string, doThrowError: boolean = true) {
        let newMac = mac.replace(/:/g, '');
        const macPart1 = newMac.substring(0, 6);
        const macPart2 = newMac.substring(6);
        newMac = macPart1 + '0000' + macPart2;

        this.apiService.registerDevice(newMac).subscribe(
            (response: any) => {
                this.registerDeviceSuccess(newMac);
                this.selectConnectionStep(this.device);
            },
            (error: any) => {
                if (error.error.error.code === 102) {
                    if (this.device === 'plug') {
                        if (this.isEDGUser) {
                            this.setStep(this.onboardingSteps.devices.plug.connectingOR);
                        } else {
                            this.setStep(this.onboardingSteps.devices.plug.connecting);
                        }
                    } else {
                        this.setStep(this.onboardingSteps.devices.box.connecting);
                    }
                    this.registerDeviceSuccess(newMac);
                } else if (error.error.error.code === 110) {
                    this.toast.error('Registrierung fehlgeschlagen');
                } else if (error.error.error.code === 999) {
                    this.toast.error('Registrierung fehlgeschlagen');
                } else if ('_body' in error) {
                    try {
                        const jsonError = JSON.parse(error._body);
                    } catch (e) {
                        if (doThrowError) {
                            this.registerDeviceError();
                        }
                    }
                } else if (doThrowError) {
                    this.registerDeviceError();
                }
            },
        );
    }


    private enterMeterStatusUpdate() {
        this.checkMeterStatus();

        this.tracking.onboarding_status(TRACKING_EVENT.ONBOARDING_FINISH,
            {connection_type: this.connect.type, user_mac: this.user.mac});

        this.apiService.optInDevice().subscribe(
            (response: any) => {
                // console.log('opt in succeeded', response);
            },
            (e) => {
                // console.log('opt in failed with:', e.error.error.code, e.error.error.message);
            }
        );

        this.setStep(this.onboardingSteps.devices.plug.connecting);
    }


    private connectMeterWithPin(): void {
        this.meter.putOpticalReaderPin(this.readerPIN).subscribe((res) => {
        });
    }


    private registerDeviceSuccess(newMac: any) {
        this.checkMeterStatus();

        this.tracking.onboarding_status(TRACKING_EVENT.ONBOARDING_FINISH,
            {connection_type: this.connect.type, user_mac: this.user.mac});

        this.apiService.optInDevice().subscribe(
            res => null,
            (e) => console.log('Opt-In failed with:', e.error.error.code, e.error.error.message)
        );
    }


    registerDeviceError() {
        this.connect.tries++;
        switch (this.connect.type) {
            case 'LAN': {
                this.input.mac = '';
                this.globals.resetIonaMacId();
                this.setStep(this.onboardingSteps.devices.box.notFoundError);
                break;
            }
            case 'AP': {
                if (this.connect.tries >= 3) {
                    this.setStep('Ü');
                } else {
                    this.setStep(this.onboardingSteps.devices.box.connectionError);
                }
                break;
            }
        }
    }


    handleAnimation(anim: any): void {
        this.anim = anim;
        this.anim.stop();
        this.playAnimationSegment(0);
        setTimeout(() => {
            this.playAnimationSegment(0);
            this.animationReady = true;
            this.checkMeterStatus(this.navigationFromDashboard);
        }, 100);
    }


    playAnimationSegment(step: number): void {
        if (this.anim === null || this.anim === undefined) {
            return;
        }
        if (!this.animationReady) {
            return;
        }
        if (step === 0) {
            setTimeout(() => {
                this.anim.playSegments([0, 85], true);
            }, 150);
        } else if (step === 1) {
            setTimeout(() => {
                this.anim.playSegments([205, 318], true);
            }, 150);
        } else if (step === 2) {
            setTimeout(() => {
                this.anim.playSegments([350, 508], true);
            }, 150);
        } else if (step === 3) {
            setTimeout(() => {
                this.anim.playSegments([657, 959], true);
            }, 150);
            // setTimeout(() => {
            //     this.anim.playSegments([655, 655], true);
            // }, 150);
        } else if (step === 4) {
            setTimeout(() => {
                this.anim.playSegments([657, 959], true);
            }, 150);
        }
    }


    /**
     * Regelmäßig den aktuellen Status der Meter-Registrierung abfragen
     */
    checkMeterStatus(alreadyStarted = false) {
        if (this.isEDGUser) {
            if (this.animationReady) {
                this.playAnimationSegment(0);
            }

            const sub = timer(0, 5000).pipe(
                mergeMap((t) => {
                    if (!alreadyStarted) {
                        return this.meter.putOpticalReaderPin(this.readerPIN).pipe(
                            map((res) => true),
                            catchError(() => of(false))
                        );
                    }
                    return of(true);
                }),
                map((res) => {
                    if (res) {
                        return true;
                    }
                })
            ).subscribe((res) => {
                if (res) {
                    sub.unsubscribe();
                    this.meterStatusCallERNA();
                    this.checkMeterStatusInterval = setInterval(() => {
                        this.meterStatusCallERNA();
                    }, 5000);
                }
            });
            return;
        }

        this.meterStatusCall();
        this.checkMeterStatusInterval = setInterval(() => {
            this.meterStatusCall();
        }, 5000);
    }


    meterStatusCallERNA() {
        this.opticalReader.getOpticalReaderStatus(true).subscribe(
            // this._apiService.getDeviceStatus().subscribe(
            (response: any) => {
                if (!('current_status' in response.electricity)) {
                    this.increaseMeterStatusErrorTries();
                    return;
                }
                const data = response.electricity;

                const status = data.current_status.toUpperCase();
                if (this.previousStatus === MeterStatuses.UPDATE_INSTALLING
                    && status !== MeterStatuses.UPDATE_INSTALLING) {
                    this.updateService.onUpdateStateReceived.next(null);
                }
                this.previousStatus = status;

                this.meterPin = data.smartreader.pincode;
                const pincodeThere = data.smartreader.pincode
                    ? data.smartreader.pincode === '' || data.smartreader.pincode == null
                    : false;
                const entryModeValid = data.smartreader.pin_entry_mode === 'optical' ||
                    data.smartreader.pin_entry_mode === 'unknown';
                const pinInputRequired = data.smartreader.pin_entry_mode === 'manual_push_button' ||
                    data.smartreader.pin_entry_mode === 'manual_torch';
                const meterUnlocked = data.smartreader.meter_unlocked;

                if (!meterUnlocked && pinInputRequired) {
                    if (!this.preventPinInfoPopover) {
                        this.showManualPinEntryOverlay();
                    }
                }

                const skipToPinVerification = false;
                switch (status) {
                    case MeterStatuses.READY_FOR_METER_INCLUSION:
                        if (this.meterStatus !== 1) {
                            this.meterStatus = 1;
                            this.globals.resetIsMeterConnected();
                            this.playAnimationSegment(1);
                        }
                        break;
                    case MeterStatuses.CONNECTED_WITH_METER:
                    // tslint:disable-next-line:no-switch-case-fall-through
                    case 'COMMISSION_IN_PROGRESS':
                        if (this.meterStatus !== 2) {
                            if (data.smartreader.meter_unlocked) {
                                this.globals.setIsMeterConnected();
                                if (!skipToPinVerification) {
                                    clearInterval(this.checkMeterStatusInterval);
                                    this.setStep(onboardingSteps.accountSetup);
                                }
                                // break;
                            }
                            this.meterStatus = 2;
                            this.playAnimationSegment(2);
                            this.globals.resetIsMeterConnected();
                            this.showUpdateAvailableOverlay();
                        }
                        break;
                    case MeterStatuses.VERIFYING_PIN:
                        if (this.meterStatus !== 3) {
                            this.meterStatus = 3;
                            this.globals.setIsMeterConnected();
                            this.playAnimationSegment(3);
                        }

                        break;
                    case MeterStatuses.PIN_FAILED:
                        this.handlePinFailedState();
                        break;
                    case MeterStatuses.CONTACT_NO_DATA_FROM_METER:
                        this.openErrorPopover(data);
                        break;
                    case MeterStatuses.CONTACT_WRONG_METER_SERIAL:
                        this.openErrorPopover(data);
                        break;
                    case MeterStatuses.UPDATE_INSTALLING:
                        if ('firmware_update_progress' in data.smartreader) {
                            const progress = data.smartreader.firmware_update_progress;
                            this.updateService.onUpdateStateReceived.next(progress);
                            this.popover.open(FirmwareUpdatePopover).afterClosed$.subscribe(
                                (res) => {
                                    this.popoverOpen = false;
                                }
                            );
                            this.popoverOpen = true;
                        }
                        break;
                    case MeterStatuses.RADIO_LINK_LOST:
                        if (!this.shouldTriggerTimeBasedOverlay(StorageAttributes.RADIO_LINK_LOST_INFO, 'hours', 24)) {
                            break;
                        }
                        if (this.popoverOpen) {
                            break;
                        }
                        this.popover.open(RadioLinkLostPopover).afterClosed$.subscribe(
                            () => {
                            });
                        this.popoverOpen = true;
                        break;
                    default:
                        // not sure about thatone
                        this.meterStatus = 0;
                        this.globals.resetIsMeterConnected();
                        if (this.animationReady) {
                            this.playAnimationSegment(0);
                        }
                }
                this.increaseMeterStatusErrorTries();
            },
            (error) => {
                console.log('Error:', error);
                this.increaseMeterStatusErrorTries();
            });
    }


    meterStatusCall() {
        this.apiService.getDeviceStatus().subscribe(
            (response: any) => {
                if (!('data' in response)) {
                    this.increaseMeterStatusErrorTries();
                    return;
                }

                if (!('current_status' in response.data)) {
                    this.increaseMeterStatusErrorTries();
                    return;
                }

                switch (response.data.current_status.toUpperCase()) {
                    case MeterStatuses.READY_FOR_METER_INCLUSION:
                        if (this.animationReady) {
                            if (this.meterStatus !== 1) {
                                this.playAnimationSegment(1);
                                this.meterStatus = 1;
                                this.globals.resetIsMeterConnected();
                            }
                        }

                        break;
                    case MeterStatuses.CONTACT_WITH_METER:
                        if (this.animationReady) {
                            if (this.meterStatus !== 2) {
                                this.playAnimationSegment(2);
                                this.meterStatus = 2;
                                this.globals.resetIsMeterConnected();
                            }
                        }

                        break;
                    case MeterStatuses.CONNECTED_WITH_METER:
                        if (this.animationReady) {
                            if (this.meterStatus !== 3) {
                                this.playAnimationSegment(3);
                                this.meterStatus = 3;
                                this.globals.setIsMeterConnected();
                                clearInterval(this.checkMeterStatusInterval);
                            }
                        }

                        break;
                    default:
                        this.playAnimationSegment(0);
                }

                this.increaseMeterStatusErrorTries();
            },
            (error) => {
                console.log('Error:', error);
                this.increaseMeterStatusErrorTries();
            });
    }


    increaseMeterStatusErrorTries() {
        // Versuche erhöhen
        switch (this.meterStatus) {
            case 0:
                this.statusTries.step0++;
                break;
            case 1:
                this.statusTries.step1++;
                break;
            case 2:
                this.statusTries.step2++;
                break;
        }

        // Prüfen ob Maximum erreicht
        switch (this.meterStatus) {
            case 0: // Step0 5 Minuten -> 60 Calls
                if (this.statusTries.step0 >= 60) {
                    this.statusError.title = 'Keine Anmeldung möglich';
                    this.statusError.text =
                        'Bei der Vorbereitung der Hardware ist ein Fehler aufgetreten. ' +
                        'Soll ich es weiter versuchen oder möchten Sie den Support kontaktieren?';
                    this.statusTries = {step0: 0, step1: 0, step2: 0, };
                    this.setStep(this.onboardingSteps.detailFailed);
                    clearInterval(this.checkMeterStatusInterval);
                }
                break;
            case 1: // Step1 2 Minuten -> 24 Calls
                // if (this.statusTries.step1 >= 60) {
                if (this.statusTries.step1 >= 24) {
                    this.statusError.title = 'Smart Meter nicht gefunden';
                    this.statusError.text =
                        'E.ON Smart Control konnte bislang keinen Smart Meter finden. ' +
                        'Soll ich es weiter versuchen oder möchten Sie den Support kontaktieren?';
                    this.statusTries = {step0: 0, step1: 0, step2: 0, };
                    this.setStep(this.onboardingSteps.detailFailed);
                    clearInterval(this.checkMeterStatusInterval);
                }
                break;
            case 2: // Step2 10 Minuten -> 120 Calls
                if (this.statusTries.step2 >= 120) {
                    this.statusError.title = 'Keine Smart Meter-Verbindung';
                    this.statusError.text =
                        'E.ON Smart Control konnte bislang keine Verbindung zum Smart Meter aufbauen. ' +
                        'Soll ich es weiter versuchen oder möchten Sie den Support kontaktieren?';
                    this.statusTries = {step0: 0, step1: 0, step2: 0, };
                    this.setStep(this.onboardingSteps.detailFailed);
                    clearInterval(this.checkMeterStatusInterval);
                }
                break;
        }
    }


    /**
     * Zurücksetzen der Meter-Registrierung und von vorn beginnen
     */
    retryMeterStatus() {
        this.statusTries = {step0: 0, step1: 0, step2: 0, };
        this.checkMeterStatus();
        if (this.device === 'plug') {
            if (this.isEDGUser) {
                this.setStep(this.onboardingSteps.devices.plug.connectingOR);
            } else {
                this.setStep(this.onboardingSteps.devices.plug.connecting);
            }
        } else {
            this.setStep(this.onboardingSteps.devices.box.connecting);
        }
    }

    /**
     * Aktuellen Step der Registrierung setzen -> Im Frontend die Steps durchwechseln
     *
     * @param step
     */
    setStep(step: string) {
        this.currentStep = step;
        switch (this.currentStep) {
            case this.onboardingSteps.devices.box.powerConnect:
            case this.onboardingSteps.devices.box.lanConnect: {
                this.connect = {type: 'LAN', tries: 0};
                break;
            }
            case this.onboardingSteps.devices.box.macEntry: {
                if (this.input.mac !== null && this.input.mac.length > 0) {
                    this.registerDevice(this.input.mac, false);
                }
                break;
            }
            case 'W':
            case 'X': {
                this.connect = {type: 'AP', tries: 0};
                break;
            }
            case this.onboardingSteps.devices.plug.connecting:
            case this.onboardingSteps.devices.box.connecting:
                if (this.user.email !== null && this.user.email !== undefined) {
                    this.apiService.loginUser(this.user.email, this.user.password);
                }
                break;
            case this.onboardingSteps.accountSetup:
                // this.heartbeat.startLiveUpdate();
                break;
            case this.onboardingSteps.opticalReader.deviceSelection:
                this.startInitialRegistration();
                break;
        }

        // analytics stuff
        switch (this.currentStep) {
            case this.onboardingSteps.devices.box.lanConnect:
            case 'X': {
                this.tracking.onboarding_status(TRACKING_EVENT.ONBOARDING_START,
                    {connection_type: this.connect.type, user_mac: this.user.mac});
                break;
            }
            case 'I': {
                if (this.connect.type !== null) {
                    this.tracking.onboarding_status(TRACKING_EVENT.ONBOARDING_CANCEL,
                        {connection_type: this.connect.type, user_mac: this.user.mac});
                }
                break;
            }
        }
    }


    /**
     * On Device selected
     * @param device
     * @param optical_reader
     */
    selectDevice(device, optical_reader = false, smart_reader = false, model= ''): void {
        this.device = device;
        let device_to_set = '';
        switch (device) {
            case 'box':
                this.deviceTitle = 'E.ON Smart Control Box';
                device_to_set = constants.application.devices.box;
                break;
            case 'plug':
                if (optical_reader) {
                    this.isEDGUser = true;
                    device_to_set = constants.application.devices.plug_optical;
                    this.deviceTitle = 'E.ON Smart Control Stecker';
                } else if (smart_reader) {
                    device_to_set = model;
                    this.isEDGUser = true;
                    this.deviceTitle = 'E.ON Smart Control Box 2.0';
                } else {
                    device_to_set = constants.application.devices.plug;
                    this.deviceTitle = 'E.ON Smart Control Stecker';
                }
                break;
            default:
                this.deviceTitle = 'E.ON Smart Control Box';
                device_to_set = constants.application.devices.box;
                break;
        }
        this.userService.updateUserDevice(device_to_set);
        this.setStep(this.onboardingSteps.hardwarePresent);
    }


    checkOptInResponse(
        trackingEnabled: boolean,
        optInEnabled: boolean,
        dataOptInEnabled: boolean
    ): void {
        this.tracking.changeTrackingState(trackingEnabled);
        if (!optInEnabled) {
            this.localOptInService.triggerOptInPopover(true)
                .subscribe(finalResult => {
                    if (finalResult) {
                        // this.continueOnboardingAfterPermissionCheck();
                        this.sendDataAnalyticsOptin(dataOptInEnabled);
                    } else {
                        this.apiService.logoutUser();
                    }
                });
        } else {
            // this.continueOnboardingAfterPermissionCheck();
            this.sendDataAnalyticsOptin(dataOptInEnabled);
        }
    }


    afterConnectionFinished(): void {
        if (this.navigationFromDashboard) {
            this.router.navigate(['/']);
            return;
        }
        this.setStep(onboardingSteps.accountSetup);
    }


    setPIN(pin: string): void {
        this.readerPIN = parseInt(pin, 10);

        this.connectMeterWithPin();
        this.enterMeterStatusUpdate();
    }


    determineBackgroundColor(): string {
        return this.isCurrentStepColored() ? '#00A1B0' : '#e8e8e8';
    }


    isBright(): boolean {
        return !this.isCurrentStepColored();
    }


    setFirstMACAddress(value: string): void {
        this.firstMac = value.replace(/:/g, '');
        this.setStep(this.onboardingSteps.devices.box.macEntry2);
    }


    setPlugMacAddress(): void {
        const plainMac = this.deviceMacForm.value.mac.replace(/:/g, '');
        if (this.plugMacValidationRegex.test(plainMac)) {
            this.plugMac = plainMac;
            this.deviceMac = plainMac;
            this.setStep(this.onboardingSteps.devices.plug.orSetup.findAndEnterORMacAddress);
            this.initializeContinuousRegistrationCheck();
            return;
        }
        this.toast.error('Die eingegebene MAC-Adresse entspricht nicht dem geforderten Format.');

    }

    /**
     * Sets the MAC Adress of the optical reader
     */
    setOpticalReaderMacAddress(): void {
        const plainMac = this.readerMacForm.value.mac.replace(/:/g, '');
        if (this.opticalReaderMacValidationRegex.test(plainMac)) {
            this.opticalReaderMac = plainMac;
            this.setStep(this.onboardingSteps.devices.plug.orSetup.positioningOR);
            return;
        }
        this.toast.error('Die eingegebene MAC-Adresse entspricht nicht dem geforderten Format.');
    }


    /**
     * Starts the manual commissioning process where the connection between the plug and the
     * optical reader is established.
     */

    startCommission(): void {
        const maxRetries = 60;

        this.opticalReader.startCommission(this.opticalReaderMac)
            .pipe(
                retryWhen(errors => errors.pipe(
                    concatMap((error, attempt) => {
                        if (attempt < maxRetries) {
                            console.log(`Retrying commissioning attempt ${attempt + 1}`);
                            this.commissionStatus = 'loading';

                            return timer(10000);
                        }

                        return throwError(new Error('Maximum retries exceeded'));
                    })
                ))
            )
            .subscribe({
                next: () => {
                    this.commissionStatus = 'success';
                },
                error: () => {
                    this.commissionStatus = 'error';
                    this.openErrorDialog();
                    this.toast.error('Fehler beim Starten der Inbetriebnahme des optischen Lesers.');
                }
            });
    }

    startFirmwareUpdate(): void {
        this.startContinuousFirmwareUpdateSearch();
        this.setStep(onboardingSteps.devices.plug.orSetup.infoProcessDuration);
    }

    afterOrOnboardingComplete(): void {
        if (this.isEDGUser) {
            this.checkPinExistence();
        } else {
            this.registerDevice(this.plugMac, true);
        }
    }


    private initializeRouteParamterHandling(): void {
        this.route.queryParams.subscribe(params => {
            const skipToOnboarding = params.jumpToOnboarding;
            const skipToMeterStatus = params.jumpToMeterstate;
            const skipToTutorial = params.jumpToTutorial;

            if (skipToOnboarding) {
                if (!this.userService.getActiveUserName()) {
                    this.router.navigate(['/registrieren']);
                    return;
                }
                this.registration.getModel().subscribe(
                    (model_response: any) => {
                        switch (model_response.model_identifier) {
                            case constants.application.devices.plug:
                                this.selectDevice('plug');
                                break;
                            case constants.application.devices.plug_optical:
                                this.isEDGUser = true;
                                this.selectDevice('plug', true);
                                break;
                            case constants.application.devices.smart_box:
                                this.isEDGUser = true;
                                this.selectDevice('plug', false, true, constants.application.devices.smart_box);
                                break;
                            case constants.application.devices.smart_box2:
                                this.isEDGUser = true;
                                this.selectDevice('plug', false, true, constants.application.devices.smart_box2);
                                break;
                            default:
                                this.selectDevice('box');
                        }
                    },
                    (error) => {
                        console.log('Error:', error);
                    }
                );
                return;
            }

            if (skipToMeterStatus) {
                if (!this.userService.getActiveUserName()) {
                    this.router.navigate(['/registrieren']);
                }
                this.registration.getModel().subscribe(
                    (res) => {
                        this.determineUserMode(res);
                        if (this.device === constants.application.devices.plug) {
                            if (this.isEDGUser) {
                                this.setStep(this.onboardingSteps.devices.plug.connectingOR);
                            } else {
                                this.setStep(this.onboardingSteps.devices.plug.connecting);
                            }
                        } else if (this.device === constants.application.devices.plug_optical) {
                            this.setStep(this.onboardingSteps.devices.plug.connectingOR);
                        } else {
                            this.setStep(this.onboardingSteps.devices.box.connecting);
                        }
                        this.navigationFromDashboard = true;
                        this.checkMeterStatus(true);
                    },
                    error => {
                        this.router.navigate(['/registrieren']);
                    }
                );
                return;
            }

            if (skipToTutorial) {
                this.registration.getModel().subscribe((modelResponse) => {
                    this.userService.setActiveUserProvider(modelResponse.labelpartner);
                    this.isEDGUser = this.userService.isEDGUser();
                    switch (modelResponse.model_identifier) {
                        case constants.application.devices.plug:
                            this.selectDevice('plug', true);
                            if (this.isEDGUser) {
                                this.setStep(this.onboardingSteps.devices.plug.macEntry);
                            }
                            break;
                        case constants.application.devices.plug_optical:
                        case constants.application.devices.smart_box:
                        case constants.application.devices.smart_box2:
                            this.isEDGUser = true;
                            this.selectDevice('plug', true);
                            this.setStep(this.onboardingSteps.devices.plug.macEntry);
                            break;
                        default:
                            this.selectDevice('box');
                            this.setStep(this.onboardingSteps.devices.box.powerConnect);
                    }
                });
                return;
            }
        });
    }


    private determineUserMode(data): void {
        this.device = data.model_identifier;
        switch (data.model_identifier) {
            case constants.application.devices.plug:
                this.deviceTitle = 'E.ON Smart Control Stecker';
                break;
            case constants.application.devices.plug_optical:
                this.deviceTitle = 'E.ON Smart Control Stecker';
                this.isEDGUser = true;
                break;
            case constants.application.devices.smart_box:
            case constants.application.devices.smart_box2:
                this.deviceTitle = 'E.ON Smart Control Box 2.0';
                this.isEDGUser = true;
                break;
            default:
                this.deviceTitle = 'E.ON Smart Control Box';
        }
    }


    /**
     * Starts the continuous search for firmware updates
     * @private
     */
    private startContinuousFirmwareUpdateSearch(): void {
        const destroy$ = new Subject();
        let updateStarted = false;

        let currentCycle = 0;
        const totalCycles =
            this.firmwareUpdateSearchDuration / this.firmwareUpdateSearchInterval;
        const totalCyclesUpdating =
            this.firmwareUpdateMaxDuration / this.firmwareUpdateSearchInterval;
        timer(0, this.firmwareUpdateSearchInterval).pipe(
            takeUntil(destroy$),
            mergeMap(() => this.opticalReader.getOpticalReaderStatus()),
            mergeMap((orStatus) => {
                console.log('current or status', orStatus);
                const currentStatus = orStatus.status;
                const firmwareStatus = orStatus.firmware_status.toLowerCase();

                let action: 'done' | 'startUpdate' | 'updating' | 'wait';

                if (this.lastMeterStatus === MeterStatuses.UPDATE_INSTALLING
                    && currentStatus !== MeterStatuses.UPDATE_INSTALLING) {
                    action = 'done';
                    return of({action, orStatus});
                }

                if (firmwareStatus === 'ok') {
                    action = 'done';
                } else if (firmwareStatus === 'update_installing' ||
                    currentStatus === MeterStatuses.UPDATE_INSTALLING) {
                    action = 'updating';
                } else {
                    if (!firmwareStatus || firmwareStatus === '') {
                        action = 'wait';
                    } else if (firmwareStatus === 'update_available') {
                        action = 'startUpdate';
                    } else {
                        console.log('undefined state');
                        action = 'wait';
                    }
                }
                this.lastMeterStatus = currentStatus;
                return of({action, orStatus});
            }),
            switchMap(({action, orStatus}) => {
                console.log('current action', action);
                switch (action) {
                    case 'done':
                        this.updateService.onUpdateStateReceived.next(null);
                        return of(true);
                    case 'startUpdate':
                        return of(updateStarted).pipe(
                            mergeMap((started) => {
                                if (!started) {
                                    updateStarted = true;
                                    this.firmwareUpdateStatus = 'loading';
                                    return this.updateService.startUpdate()
                                        .pipe(map(() => false));
                                }
                                return of(false);
                            })
                        );
                    case 'updating':
                        if ('update_progress' in orStatus) {
                            this.updateService.onUpdateStateReceived.next(
                                orStatus.update_progress);
                        }
                        if (!this.popoverOpen) {
                            this.popoverOpen = true;
                            return this.popover.open(FirmwareUpdatePopover).afterClosed$
                                .pipe(map(() => {
                                    this.popoverOpen = false;
                                    return true;
                                }));
                        }
                        return of(false);
                    case 'wait':
                        this.firmwareUpdateStatus = 'loading';
                        return of(false);
                }
            })
        ).subscribe({
            next: result => {
                if (result) {
                    destroy$.next(null);
                    this.firmwareUpdateFinishedOrFailed = true;
                    this.firmwareUpdateStatus = 'uptodate';
                }

                ++currentCycle;
                if (updateStarted) {
                    if (currentCycle === totalCyclesUpdating) {
                        destroy$.next(null);
                        this.firmwareUpdateStatus = 'error';
                    }
                } else {
                    if (currentCycle === totalCycles) {
                        destroy$.next(null);
                        this.firmwareUpdateStatus = 'error';
                    }
                }
            }
        });
    }


    /**
     * Starts a timer that checks the meter status every 5 seconds
     * @private
     */
    private initializeContinuousRegistrationCheck(): void {
        const destroy$ = new Subject();
        const totalNumberOfTries =
            this.registrationOnboardRetryDuration / this.registrationOnboardRetryInterval;
        let currentNumberOfTries = 0;
        timer(0, 5000)
            .pipe(
                takeUntil(destroy$),
                switchMap(() =>
                    this.registration.registerDevice(this.plugMac).pipe(
                        mergeMap((response) => of(true)),
                        catchError((error) => {
                            try {
                                if (error.error.error.code === 102) {
                                    return of(true);
                                }
                            } catch (error) {
                                return of(false);
                            }
                        }),
                    )
                )
            )
            .subscribe({
                next: (result) => {
                    ++currentNumberOfTries;
                    if (currentNumberOfTries === totalNumberOfTries) {
                        destroy$.next(null);
                    }
                    if (result) {
                        destroy$.next(null);
                        this.registrationOnboardSuccessful = true;
                    }
                }
            });
    }


    private shouldTriggerTimeBasedOverlay(item: string, timeframe: any, time: number): boolean {
        const lastTriggered = localStorage.getItem(item);
        if (!lastTriggered) {
            localStorage.setItem(item, moment().toDate().toString());
            return true;
        }

        const date = new Date(lastTriggered);
        if (date <= moment().subtract(time, timeframe).toDate()) {
            localStorage.setItem(item, moment().toDate().toString());
            return true;
        }
        return false;
    }


    private shouldTriggerAvailableUpdateOverlay(): boolean {
        const storageItem = 'lastUpdateInfo';
        const lastTriggered = localStorage.getItem(storageItem);
        if (!lastTriggered) {
            localStorage.setItem(storageItem, moment().toDate().toString());
            return true;
        }

        const date = new Date(lastTriggered);
        if (date <= moment().subtract(24, 'hour').toDate()) {
            localStorage.setItem(storageItem, moment().toDate().toString());
            return true;
        }
        return false;
    }


    private showUpdateAvailableOverlay(): void {
        if (!this.shouldTriggerAvailableUpdateOverlay()) {
            return;
        }
        if (this.popoverOpen) {
            return;
        }
        this.popover.open(FirmwareUpdateAvailablePopover).afterClosed$.pipe(
            mergeMap(res => of({type: MeterStatuses.CONNECTED_WITH_METER, res}))
        );
        this.popoverOpen = true;
    }


    private showManualPinEntryOverlay(): void {
        if (!this.popoverOpen) {
            const overlayDef = ManualPinEntryInfoPopoverConfig;
            if (this.meterPin) {
                overlayDef.data.text +=
                    ` Ihre aktuelle Zähler-Pin lautet: ${this.meterPin}.`;
            }
            this.popover.open(overlayDef).afterClosed$.subscribe(() => {
                this.popoverOpen = false;
                this.preventPinInfoPopover = true;
            });
            this.popoverOpen = true;
        }
    }


    /**
     * Handle the PIN_FAILED Meter State
     */
    private handlePinFailedState(): void {
        const storageItem = 'lastPinInfo';

        let triggerOverlay = false;
        const lastTriggered = localStorage.getItem(storageItem);
        if (!lastTriggered) {
            localStorage.setItem(storageItem, moment().toDate().toString());
            triggerOverlay = true;
        }

        const date = new Date(lastTriggered);
        if (date <= moment().subtract(1, 'hour').toDate()) {
            localStorage.setItem(storageItem, moment().toDate().toString());
            triggerOverlay = true;
        }

        if (!triggerOverlay) {
            return;
        }

        const sub = this.popover.open(PinFailedPopoverConfig).afterClosed$.pipe(
            mergeMap((dialogData) => {
                if (dialogData.data === null) {
                    this.popoverOpen = false;
                    return null;
                }
                let overlayDef: any = ManualPinEntryPopoverConfig;
                if (dialogData.data) {
                    overlayDef = PinEntryPopoverConfig;
                }
                if (this.meterPin) {
                    overlayDef.data.text +=
                        ` Ihre aktuelle Zähler-Pin lautet: ${this.meterPin}.`;
                }
                return this.popover.open(overlayDef).afterClosed$;
            })
        ).subscribe((result: any) => {
            if (result === null) {
                this.popoverOpen = false;
                return;
            }
            if (typeof result.data === 'string') {
                const pin = parseInt(result.data, 10);
                this.preventPinErrorPopover = true;
                this.meter.startContinuousPinEntry(pin);
                this.meter.onPinEntrySuccess.subscribe((res) => {
                    if (res) {
                        this.preventPinErrorPopover = false;
                    }
                });
            }
            if (result) {
                this.popoverOpen = false;
            }

            this.popoverOpen = false;
        });

        this.popoverOpen = true;
    }


    private isCurrentStepColored(): boolean {
        const colored_steps = [
            this.onboardingSteps.deviceSelection,
            this.onboardingSteps.devices.box.powerConnect,
            this.onboardingSteps.devices.box.lanConnect,
            this.onboardingSteps.devices.box.connecting,
            this.onboardingSteps.devices.plug.noteMac,
            this.onboardingSteps.devices.plug.powerConnect,
            this.onboardingSteps.devices.plug.wpsConnect,
            this.onboardingSteps.devices.plug.wpsRouter,
            this.onboardingSteps.devices.plug.wifiConnected,
            this.onboardingSteps.devices.plug.connecting,
            this.onboardingSteps.devices.plug.orSetup.findAndEnterORMacAddress,
            this.onboardingSteps.devices.plug.orSetup.positioningOR,
            this.onboardingSteps.devices.plug.orSetup.preparingPlug,
            this.onboardingSteps.devices.plug.orSetup.powerReaderAndStartCommission,
            this.onboardingSteps.devices.plug.orSetup.infoProcessDuration,
            this.onboardingSteps.devices.plug.orSetup.readerInstallationStep1,
            this.onboardingSteps.devices.plug.orSetup.readerInstallationStep2,
            this.onboardingSteps.devices.plug.orSetup.readerInstallationStep3,
            this.onboardingSteps.devices.plug.orSetup.readerInstallationStep4,
            this.onboardingSteps.opticalReader.deviceSelection,
            this.onboardingSteps.opticalReader.installation.step1,
            this.onboardingSteps.opticalReader.installation.step2,
            this.onboardingSteps.opticalReader.installation.step3,
            this.onboardingSteps.opticalReader.installation.step4,
            this.onboardingSteps.devices.plug.connectingOR,
            onboardingSteps.accountSetup
        ];
        return colored_steps.findIndex((val) => this.currentStep === val) >= 0;
    }


    private openErrorPopover(data: any): void {
        if (this.popoverOpen) {
            return;
        }

        let config = null;
        let doReallyShow = true;
        switch (data.current_status.toUpperCase()) {
            case 'CONTACT_WRONG_METER_SERIAL': {
                config = OnboardingWrongSerialPopoverConfig;
                const read = data.smartreader.meter_id_read;
                const provisioned = data.smartreader.meter_id_provisioned;
                doReallyShow = read !== provisioned;
                config.data.text = `Der mit dem optischen Ausleser verbundene Stromzähler (${read}) besitzt nicht die korrekte Zählernummer. Bitte stellen Sie sicher, dass der Ausleser mit dem Zähler ${provisioned} verbunden ist.`;
                break;
            }
            case 'CONTACT_NO_DATA_FROM_METER': {
                if (this.noDataPopoverShown) {
                    return;
                }
                config = OnboardingNoContactPopoverConfig;
                break;
            }
            default:
                config = OnboardingNoContactPopoverConfig;
                break;
        }

        if (!doReallyShow) {
            return;
        }

        this.popover.open(config).afterClosed$.subscribe(() => {
            this.connectMeterWithPin();
            this.popoverOpen = false;

            if (data.current_status.toUpperCase() === 'CONTACT_NO_DATA_FROM_METER') {
                this.noDataPopoverShown = true;
            }
        });

        this.popoverOpen = true;
    }


    private startInitialRegistration(): void {
        if (new Date().getTime() - this.lastRegistrationCall <= 5000) {
            this.startInitialRegistration();
            return;
        }
        const mac = this.secondMac.replace(/:/g, '');
        const macPart1 = mac.substring(0, 6);
        const macPart2 = mac.substring(6);
        const finalMac = macPart1 + '0000' + macPart2;
        this.registrationSub = this.registration.registerDevice(finalMac).pipe(
            mergeMap(res => res ? of(true) : throwError(undefined)),
            catchError(error => {
                let isSuccessError = false;
                try {
                    isSuccessError = error.error.code === 102;
                } catch (e) {
                    console.log('Error:', e);
                    return throwError(undefined);
                }
                return isSuccessError ? of(true) : throwError(undefined);
            })
        ).subscribe(
            () => {
                this.registrationSub.unsubscribe();
                this.registrationSub = null;
            },
            (error) => {
                if (this.registrationTries < 60) {
                    this.startInitialRegistration();
                } else {
                    this.registrationSub.unsubscribe();
                    this.registrationSub = null;
                }
                this.lastRegistrationCall = new Date().getTime();
            },
        );
    }


    private sendDataAnalyticsOptin(value: boolean): void {
        this.optInService.setAnalyticsOptIn(value).subscribe({
            next: (res) => {
                this.continueOnboardingAfterPermissionCheck();
            }
        });

    }


    private continueOnboardingAfterPermissionCheck(): void {
        switch (this.device) {
            case 'plug':
                if (this.isEDGUser) {
                    this.currentStep = this.onboardingSteps.devices.plug.noteMac;
                    break;
                }
                this.currentStep = this.onboardingSteps.devices.plug.noteMac;
                break;
            default:
                this.setStep(this.onboardingSteps.devices.box.powerConnect);
                break;
        }
    }


    private selectConnectionStep(device: string): void {
        if (device === 'plug') {
            if (this.isEDGUser) {
                this.setStep(this.onboardingSteps.devices.plug.connectingOR);
            } else {
                this.setStep(this.onboardingSteps.devices.plug.connecting);
            }
        } else {
            this.setStep(this.onboardingSteps.devices.box.connecting);
        }
    }


    private setupFormHandling(): void {
        this.passwordForm.valueChanges.pipe(
            tap(values => this.currentPasswordScore = zxcvbn(values.password1).score),
            map((value) => {
                const first = this.passwordRegex.test(value.password1);
                const second = this.passwordRegex.test(value.password2);
                return first && second;
            })
        ).subscribe(result => {
            this.setPasswordDisabled = !result;
        });
    }


    private checkPinExistence(): void {
        this.opticalReader.getOpticalReaderStatus(true).subscribe({
            next: (orStatus) => {
                try {
                    const pincode = orStatus.electricity.smartreader.pincode;
                    if (pincode || pincode === '') {
                        this.setPIN(pincode);
                    } else {
                        this.setStep(this.onboardingSteps.opticalReader.pinEntry);
                    }
                } catch (error) {
                    this.setStep(this.onboardingSteps.opticalReader.pinEntry);
                }
            }
        });
    }
}
