import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Globals} from '../../../services/globals.service';
import {BaseComponent} from '../../../classes/base-component';
import {TILE_TYPE, TileService} from '../../../services/tile.service';
import {TrackAnalyticsService} from '../../../services/track-analytics.service';
import {MeterReadingService} from '../../../services/meter-reading.service';
import {UserService} from '../../../services/user.service';
import {MeterTileTexts} from '../../../shared/texts/tiles/meter-tile.texts';
import {ViewState} from '../../../shared/enums/view-state.enum';
import {InternalMeterConnectionStatus} from '../../../shared/enums/meter-status.enum';
import {MeterDataProviderService} from '../../../services/meter-data-provider.service';

@Component({
    selector: 'app-meter-tile',
    templateUrl: './meter-tile.component.html',
    styleUrls: ['./meter-tile.component.scss'],
    providers: [Globals]
})

export class MeterTileComponent extends BaseComponent implements OnInit {
    private readonly type: TILE_TYPE = TILE_TYPE.METER;

    protected readonly ViewState = ViewState;
    protected readonly InternalMeterConnectionStatus = InternalMeterConnectionStatus;

    readonly TEXTS = MeterTileTexts;

    @Output() tileClicked = new EventEmitter<string>();

    showMeterReadingsBadge = false;

    meterData$ = this.meterDataProvider.combinedMeterData$.asObservable();


    constructor(
        private globals: Globals,
        private analytics: TrackAnalyticsService,
        private tiles: TileService,
        private meterReadings: MeterReadingService,
        private userService: UserService,
        private meterDataProvider: MeterDataProviderService
    ) {
        super();
    }


    ngOnInit() {
        this.meterDataProvider.initializeCombinedMeterDataUpdate();
        this.initializeMeterReadingFeature();
    }


    /**
     * Handles detail page open
     */
    onTileClicked(): void {
        this.detailEntered();
        this.tiles.openDetailView(this.type);
    }


    /**
     * Determine whether the meter is connected.
     * @param status
     */
    isMeterConnected(status: InternalMeterConnectionStatus): boolean {
        return status === InternalMeterConnectionStatus.CONNECTED;
    }


    /**
     * Determine meter status message
     * @param status
     */
    determineMeterStatusMessage(status: InternalMeterConnectionStatus): string {
        switch (status) {
            case InternalMeterConnectionStatus.CONNECTED:
                return this.TEXTS.LABEL_CONNECTED;
            default:
                return this.TEXTS.LABEL_DISCONNECTED;
        }
    }


    /**
     * Request whether the user has meter reading transmission feature
     */
    private initializeMeterReadingFeature(): void {
        if (!this.userService.isMMEWMSBUser()) {
            return;
        }
        const s = this.meterReadings.getMeterReadings().subscribe(
            (res) => {
                try {
                    if (res.length > 0) {
                        this.showMeterReadingsBadge = true;
                    }
                } catch (error) {
                }
            }
        );
    }


    /*
     * ANALYTICS
     * =============================================================================================
     */
    private detailEntered() {
        if (!(this.globals.getFirstDetailsViewed())) {
            this.trackFirstDetailView();
        }
        this.globals.setFirstDetailsViews();
        this.trackDetailsEntered();
    }


    private trackDetailsEntered(): void {
        this.analytics.trackEvent({
            action: 'dashboard_tile_tapped',
            properties: {
                category: 'Tiles',
                label: 'Tile: Meter'
            }
        });
    }


    private trackFirstDetailView(): void {
        // Erstes aufrufen eines Detail Screens
        this.analytics.trackEvent({
            action: 'first_detail_view',
            properties: {
                category: 'Screens',
                label: 'Screen: Meter-Details'
            }
        });
    }
}
