import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {SingleDayComparisonData} from '../../../shared/interfaces/today-tile-data.interfaces';
import {TodayTileLabels} from '../../../shared/texts/tiles/today-tile.labels';

@Component({
    selector: 'app-today-tile-stats',
    templateUrl: './today-tile-stats.component.html',
    styleUrls: ['./today-tile-stats.component.scss']
})
export class TodayTileStatsComponent implements OnChanges {
    protected readonly LABELS = TodayTileLabels;

    @Input() today: SingleDayComparisonData;
    @Input() comparisonDate: SingleDayComparisonData;


    constructor() {
    }


    ngOnChanges(changes: SimpleChanges) {
    }
}
