export enum TodayDetailLabels {
    TITLE = 'Heute',
    UNIT_COST = '€',
    UNIT_CONSUMPTION = 'kWh',
    LABEL_COMPARISON_TODAY = 'HEUTE',
    MORE = 'mehr',
    LESS = 'weniger',
    IDENTICAL = 'identisch',
    LABEL_FRAGMENT_LAST = 'als letzten',
    LABEL_FRAGMENT_COMPARED_TO = 'als zum',
    LABEL_FRAGMENT_TO = 'zum',
    LABEL_FRAGMENT_IDENTICAL = 'identisch',
    LABEL_FRAGMENT_TO_LAST = 'zum letzten',
    LABEL_FRAGMENT_PERCENT = '%',
    OVERVIEW_HOURS = 'Übersicht in Stunden',
    LIST_TODAY = 'Heute',
    HOUR_START_SUFFIX = ':00',
    HOUR_END_SUFFIX = ':59',
    TIME_SUFFIX = 'Uhr',
    TIME_DIVIDER = ' - ',
    LABEL_LOADING = 'Daten werden noch geladen...',
    LABEL_ERROR_STATE = 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
}
