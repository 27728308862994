import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {Globals} from '../../../services/globals.service';
import {MockDataService} from '../../../services/mock-data.service';
import {UserService} from '../../../services/user.service';
import {PowercheckerStateService} from '../../../services/powerchecker-state.service';
import {PowerCheckerService} from '../../../services/powerchecker.service';
import {ApplicationService} from '../../../services/application.service';
import {TILE_TYPE, TileService} from '../../../services/tile.service';
import {TrackAnalyticsService} from '../../../services/track-analytics.service';
import {ConfigurationService, ConfigurationUserStore} from '../../../services/configuration.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-powerchecker-tile',
    templateUrl: './powerchecker-tile.component.html',
    styleUrls: ['./powerchecker-tile.component.scss'],
    providers: [Globals]
})

export class PowerCheckerTileComponent implements OnInit, OnDestroy {
    private readonly type: TILE_TYPE = TILE_TYPE.POWER_CHECKER;
    private powerCheckerStateChangeSub: Subscription = null;

    plugAttributes = {
        name: '',
        room: ''
    };
    plugName = 'Mein Gerät';
    plugRoom = 'Mein Zimmer';

    state = false;

    constructor(private globals: Globals,
                private mockDataService: MockDataService,
                private userService: UserService,
                private powercheckerState: PowercheckerStateService,
                private powerchecker: PowerCheckerService,
                private application: ApplicationService,
                private analytics: TrackAnalyticsService,
                private tiles: TileService,
                private renderer: Renderer2,
                private configuration: ConfigurationService) {
    }

    ngOnInit() {
        this.initPlugAttributes();
        this.getPlugAttributes();

        // subscribe to external changes
        this.userService.plugAttributesChanged.subscribe({
            next: (values: any) => {
                this.plugAttributes = values;
                this.plugName = this.plugAttributes.name;
                this.plugRoom = this.plugAttributes.room;
            }
        });

        // subscribe to state changes
        this.powerCheckerStateChangeSub = this.powercheckerState.stateChange.subscribe({
            next: (value) => {
                this.state = value;
            }
        });

        if (this.application.isDemoMode()) {
            this.mockDataService.getPlugStatus().subscribe({
                next: (response: any) => {
                    this.state = response.data.on_off === 1;
                }
            });
            return;
        }
        this.initPowerCheckerStateChange();
    }


    ngOnDestroy() {
        if (this.powerCheckerStateChangeSub) {
            this.powerCheckerStateChangeSub.unsubscribe();
            this.powerCheckerStateChangeSub = null;
        }
    }


    /**
     * On tile clicked event
     * @param event
     */
    onTileClicked(event): void {
        if (event.target.id === 'switch') {
            return;
        }
        this.detailEntered();
        this.tiles.openDetailView(this.type);
    }


    /**
     * On toggle state change
     * @param state
     */
    onStateChange(state: boolean): void {
        this.state = state;
        this.powercheckerState.stateChange.next(state);
        if (this.application.isDemoMode()) {
            return;
        }

        this.powerchecker.setState(state ? 1 : 0).subscribe({
            next: (res) => {
                if (!res) {
                    return;
                }
                if ('relay' in res) {
                    if ('on_off' in res) {
                        const value = res.relay.on_off;
                        this.state = value === 1;
                    }
                }
            },
            error: (error) => null
        });
    }


    /**
     * Initialize the plugs attributes
     */
    private initPlugAttributes(): void {
        this.plugAttributes = this.userService.getPlugAttributes();
        if (this.plugAttributes === null || this.plugAttributes === undefined) {
            this.getPlugAttributes();
            return;
        }
        this.plugName = this.plugAttributes.name;
        this.plugRoom = this.plugAttributes.room;
    }


    /**
     * Fetch the currently saved plug attributes from the user store API
     * @private
     */
    private getPlugAttributes(): void {
        if (this.application.isDemoMode()) {
            return;
        }

        this.configuration.requestDashboardConfiguration().subscribe({
            next: (storedData) => {
                if (storedData.status === 'success') {
                    const storedAttributes = storedData.data as ConfigurationUserStore;
                    this.plugAttributes = storedData.data;
                    if (storedAttributes.power_checker.name !== '') {
                        this.plugName = storedAttributes.power_checker.name;
                    }
                    if (storedAttributes.power_checker.name !== '') {
                        this.plugRoom = storedAttributes.power_checker.room;
                    }
                    this.userService.updatePlugAttributes(storedAttributes.power_checker);
                }
            }
        });

    }


    /**
     * Initialize PowerChecker State change
     * @private
     */
    private initPowerCheckerStateChange(): void {
        this.powerchecker.getState().subscribe({
            next: (res) => {
                if (!res) {
                    return;
                }
                if ('relay' in res) {
                    if ('on_off' in res.relay) {
                        const value = res.relay.on_off;
                        this.state = value === 1;
                    }
                }
            }
        });
    }


    /*
     * TRACKING
     * =============================================================================================
     */
    private detailEntered() {
        if (!(this.globals.getFirstDetailsViewed())) {
            this.trackFirstDetailView();
        }
        this.globals.setFirstDetailsViews();
        this.trackDetailsEntered();
    }

    private trackDetailsEntered(): void {
        this.analytics.trackEvent({
            action: 'dashboard_tile_tapped',
            properties: {
                category: 'Tiles',
                label: 'Tile: E.ON Smart Control Stecker'
            }
        });
    }

    private trackFirstDetailView(): void {
        this.analytics.trackEvent({
            action: 'first_detail_view',
            properties: {
                category: 'Screens',
                label: 'Screen: Powerchecker-Details'
            }
        });
    }

}
