export enum AppliancesDetailLabels {
    TITLE = 'Geräte',
    TAB_TITLE_OVERVIEW_MONTHS = 'Monatsübersicht',
    TAB_TITLE_OVERVIEW_YEARS = 'Jahresübersicht',
    TAB_TITLE_RETRAINING = 'Gerät neu anlernen',
    BTN_RETRAINING = 'Gerät neu anlernen',
    UNIT_CONSUMPTION = 'kWh',
    UNIT_COSTS = '€',
    BTN_RETRAIN_ALL = 'Alle neu anlernen',
}
