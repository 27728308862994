<div class="overlay">
    <div class="overlay-container-cdk">
        <div class="">
            <h1>Bereich hinzufügen</h1>
        </div>
        <div class="row-grid">
            <div *ngFor="let tile of currentTiles"
                 [class.disabled]="tile.selected">
                <div class="tile-selector" (click)="onTileToggle(tile)">
                    <img class="m-b-m" [src]="tile.thumbnail" width="100%" alt="">
                    <h2>{{tile.title}}</h2>
                    <p>{{tile.info}}</p>
                </div>
            </div>
        </div>
        <div class="close-overlay-control center-contents" (click)="close()">
            <i class="close-sm dark-grey"></i>
        </div>
    </div>
</div>
