<div id="container">
    <aside class="sidebar lft">
        <a href="#" id="hamburger" title="Menü" class="mobi">
            <span class="burger">
                <i class="top"></i>
                <i class="middle"></i>
                <i class="bottom"></i>
            </span>
            <span class="text"> Menü</span>
        </a>
        <app-menu activeElement="profile"></app-menu>
    </aside>

    <div id="inner">
        <main class="page">

            <div class="page-card">

                <h1>{{TEXTS.VIEW_TITLE}}</h1>
                <section>
                    <h2>{{TEXTS.PERSONAL_DATA_HEADING}}</h2>
                    <div class="row">
                        <div>
                            <strong>{{TEXTS.PERSONAL_DATA_REAL_NAME}}</strong><br>
                            <strong>{{TEXTS.PERSONAL_DATA_USERNAME}}</strong>
                        </div>
                        <div>
                            {{contract.name}} <br>
                            {{determineDisplayUsername()}}
                        </div>
                    </div>

                    <div class="m-v-l">
                        <button class="eon-button-new"
                                (click)="openChangeEmailPopover()"
                                [disabled]="accountRewrite.accountRewriteEnabled()">
                            {{TEXTS.CHANGE_EMAIL_BUTTON}}
                        </button>
                    </div>

                    <div class="m-v-l">
                        <button class="eon-button-new"
                                (click)="openResetPasswordPopover()"
                                [disabled]="accountRewrite.accountRewriteEnabled()">
                            {{TEXTS.CHANGE_PASSWORD_BUTTON}}
                        </button>
                    </div>

                    <div class="m-v-l" *ngIf="!application.isDemoMode()">
                        <button class="eon-button-new"
                                (click)="enableDisableMFA()"
                                [disabled]="accountRewrite.accountRewriteEnabled()">
                            {{mfaEnabled ? TEXTS.MFA_DISABLE_BUTTON : TEXTS.MFA_ENABLE_BUTTON}}
                        </button>
                    </div>
                </section>

                <section>
                    <h2>{{TEXTS.PROVIDER_HEADING}}</h2>
                    <!--                    <div class="eon-logo"></div>-->
                    <div class="row">
                        <div>
                            <strong>{{TEXTS.PROVIDER_PRODUCT}}</strong><br>
                            <strong>{{TEXTS.PROVIDER_BASEPRICE}}</strong><br>
                            <strong>{{TEXTS.PROVIDER_WORKPRICE}}</strong>
                        </div>
                        <div>
                            {{ contract.tarif }} <br>
                            {{ contract.basicprice}} <br>
                            {{ contract.workingprice}}
                        </div>
                    </div>

                </section>

                <section>
                    <h2>Mein E.ON</h2>
                    <h3>Hier können Sie alles rund um Ihren Vertrag schnell und einfach online ändern</h3>
                    <ul>
                        <li>Vertragsdaten anpassen</li>
                        <li>Abschlag anpassen</li>
                        <li>Rechnung einsehen</li>
                    </ul>
                    <button class="eon-button-new"
                            (click)="openLink()">
                        Mein E.ON
                    </button>
                </section>

            </div>
        </main>
    </div>
</div>
