<div class="overlay">
    <div class="detail-overlay">

        <div class="detail-view">

            <section class="detail-head">
                <header class="detail-header">
                    <h1 class="detail-heading">Live</h1>

                    <button angulartics2On="click" angularticsCategory="Detailansichten"
                            angularticsAction="detail_info_text" angularticsLabel="Live"
                            (click)="infoVisible = !infoVisible">
                        <i class="info-sm dark-grey"></i>
                    </button>

                    <button (click)="close()">
                        <i class="close-sm dark-grey"></i>
                    </button>
                </header>

                <section>
                    <div class="center-contents current-live-value eon-black"
                         [class.muted]="isNotRealtime"
                         [class.feedin]="currentConsumption < 0"
                         [class.consumption]="currentConsumption >= 0">
                        <i [class.feedin]="currentConsumption < 0"
                           [class.consumption]="currentConsumption >= 0">
                        </i>
                        {{currentConsumptionFormatted()}} Watt
                    </div>
                    <ng-container *ngIf="!status.noZone; else noZoneTemplate">
                        <div class="consumption-zones">
                            <div>
                                <div class="zone-title"
                                     [style.visibility]="status.trend === 0 ? 'visible' : 'hidden'">
                                    niedrig
                                </div>
                                <div class="zone-bar" [class.active]="status.trend === 0"></div>
                                <div class="zone-duration"
                                     [style.visibility]="status.trend === 0 ? 'visible' : 'hidden'">
                                    {{ status.since}}
                                </div>
                            </div>
                            <div>
                                <div class="zone-title"
                                     [style.visibility]="status.trend === 1 ? 'visible' : 'hidden'">
                                    mittel
                                </div>
                                <div class="zone-bar" [class.active]="status.trend === 1"></div>
                                <div class="zone-duration"
                                     [style.visibility]="status.trend === 1 ? 'visible' : 'hidden'">
                                    {{ status.since}}
                                </div>
                            </div>
                            <div>
                                <div class="zone-title" [style.visibility]="status.trend === 2 ? 'visible' : 'hidden'">
                                    hoch
                                </div>
                                <div class="zone-bar" [class.active]="status.trend === 2"></div>
                                <div class="zone-duration"
                                     [style.visibility]="status.trend === 2 ? 'visible' : 'hidden'">
                                    {{ status.since}}
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #noZoneTemplate>
                        <div class="center-contents zone-unavailable">Verbrauchszone kann nicht ermittelt werden</div>
                    </ng-template>
                </section>

            </section>

            <section class="detail-info" *ngIf="infoVisible">
                <div class="info-icon">
                    <i class="info-sm dark-grey"></i>
                </div>
                <div class="info-text">
                    <h2 class="eon-black">Live: Was ist das?</h2>
                    <p>
                        <ng-container *ngIf="userService.isEDGUser()">
                            E.ON Smart Control stellt Ihren Stromverbrauch in Echtzeit dar. So sehen Sie jederzeit, wie
                            viel Strom aktuell verbraucht wird. Ob sich dieser Wert im niedrigen, mittleren oder hohen
                            Bereich in Bezug zur Grundlast befindet, erkennen Sie an den oberen drei Balken. Um die
                            Batterien der Funkeinheit zu schonen, werden Daten nur übertragen, wenn es größere
                            Veränderungen (>50W) im Stromverbrauch gab. Wenn möglich kann ein Netzteil angeschlossen
                            werden, wodurch die Daten kontinuierlich übertragen werden. Über die Schaltflächen können
                            Sie jederzeit in die Vergangenheit navigieren, um sich ein Bild Ihres Stromverbrauchs zu
                            machen.
                        </ng-container>
                        <ng-container *ngIf="!userService.isEDGUser()">
                            E.ON Smart Control stellt Ihren Stromverbrauch in Echtzeit dar. So sehen Sie jederzeit, wie
                            viel Strom aktuell verbraucht wird. Ob sich dieser Wert im niedrigen, mittleren oder hohen
                            Bereich in Bezug zur Grundlast befindet, erkennen Sie an den oberen drei Balken. Über die
                            Schaltfächen können Sie jederzeit in die Vergangenheit navigieren, um sich ein Bild Ihres
                            Stromverbrauchs zu machen.
                        </ng-container>
                    </p>
                </div>
            </section>

            <section class="energy-saver-warning" *ngIf="energySaverWarningVisible">
                <div class="heading" (click)="toggleWarning()">
                    <div class="collapse">
                        <ng-container [ngSwitch]="energySaverWarningCollapsed">
                            <ng-container *ngSwitchCase="true">
                                <i class="chevron-down-sm dark-grey"></i>
                            </ng-container>
                            <ng-container *ngSwitchCase="false">
                                <i class="chevron-up-sm dark-grey"></i>
                            </ng-container>
                        </ng-container>
                    </div>
                    <div class="title"> Energiesparmodus</div>
                    <div class="close" (click)="hideWarning()">
                        <i class="close-s dark-grey"></i>
                    </div>
                </div>
                <ng-container *ngIf="!energySaverWarningCollapsed">
                    <div class="content-wrapper">
                        <div class="content">
                            <div></div>
                            <div>
                                <p>
                                    Ihr optischer Ausleser ist aktuell im Energiesparmodus. Eine Datenübertragung findet
                                    nur nach größeren Veränderungen statt.
                                    <ng-container *ngIf="lastValueTimestamp">
                                        Der letzte Wert ist vom {{lastValueTimestamp}}.
                                    </ng-container>
                                </p>
                                <button class="eon-button-new turquoise"
                                        (click)="routeToSettings()">
                                    Energiesparmodus
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </section>

            <section class="detail-content">

                <div class="live-diagram-head">
                    <ng-container *ngFor="let zoom of zoomLevels">
                        <div *ngIf="zoom.level === currentZoomLevelIdx" class="center-contents eon-bold current-zoom">
                            {{ zoom.name }}
                            <span>&nbsp;</span><small class="eon-regular" *ngIf="zoom.hint">({{ zoom.hint }})</small>
                        </div>
                    </ng-container>

                    <div class="live-chart-legend">
                        <div class="eon-bold">
                            <span class="dot consumption"></span> Verbrauch
                        </div>
                        <div class="eon-bold">
                            <span class="dot feedin"></span> Einspeisung
                        </div>
                    </div>

                    <div class="live-diagram-zoom-controls">
                        <button class="eon-icon-button small turquoise"
                                [disabled]="disabled || currentZoomLevelIdx === zoomLevels.length"
                                (click)="zoomOut()"
                                angulartics2On="click" angularticsCategory="Detailansichten"
                                angularticsAction="detail_time_interval_change" angularticsLabel="Live">

                            <i class="minus-s white"></i>
                        </button>

                        <div class="center-contents zoom-level">
                            <div class="dot" title="{{ zoom.name }}"
                                 [class.active]="zoom.level === currentZoomLevelIdx"
                                 *ngFor="let zoom of zoomLevels" (click)="setZoom(zoom.level)">
                            </div>
                        </div>

                        <button class="eon-icon-button small turquoise"
                                [disabled]="disabled || currentZoomLevelIdx===1"
                                (click)="zoomIn()"
                                angulartics2On="click" angularticsCategory="Detailansichten"
                                angularticsAction="detail_time_interval_change" angularticsLabel="Live">
                            <i class="plus-s white"></i>
                        </button>
                    </div>
                </div>

                <app-live-chart #liveChart isDetail="true"></app-live-chart>

                <div class="diagram-controls">
                    <div class="left">
                        <app-date-picker-button
                            [displaySelectedDate]="true"
                            (dateChanged)="onDatePickerDateChange($event)"
                            [currentDate]="baseDate">
                        </app-date-picker-button>

                        <div class="m-h-s"></div>
                        <button class="eon-icon-button small turquoise"
                                [disabled]="disabled"
                                (click)="stepBack()">
                            <i class="chevron-left-s white"></i>
                        </button>

                    </div>
                    <div class="center center-contents eon-regular">
                        <strong> {{baseDate ? specifiedDateDisplay : ''}} </strong>
                    </div>
                    <div class="right">
                        <button class="eon-icon-button small turquoise"
                                [disabled]="disabled || (position === 1 && !specificDateMode)"
                                (click)="stepForward()">
                            <i class="chevron-right-s white"></i>
                        </button>
                        <div class="m-h-s"></div>
                        <button class="eon-button-new small turquoise"
                                [disabled]="disabled || (position ===1 && !specificDateMode)"
                                (click)="resetPosition()">
                            Jetzt
                        </button>
                    </div>
                </div>

            </section>

        </div>
    </div>
</div>




