import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Chart} from 'angular-highcharts';
import {ReplaySubject} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {
    HouseholdComparisonDiagramData
} from '../../shared/interfaces/household-comparison.interfaces';
import {HouseholdComparisonTimeframe} from '../../shared/enums/household-comparison-timeframe.enum';
import * as moment from 'moment';


@Component({
    selector: 'app-household-comparison-chart',
    templateUrl: './household-comparison-chart.component.html',
    styleUrls: ['./household-comparison-chart.component.scss']
})
export class HouseholdComparisonChartComponent implements OnInit, OnChanges {
    @Input() diagramData: HouseholdComparisonDiagramData;
    @Input() isDetailChart = false;

    private chartLoaded$ = new ReplaySubject();

    private readonly regularSeriesColor = '#1ea2b1';
    private readonly highlightedSeriesColor = '#e3e000';

    chart: Chart = null;


    constructor() {
    }


    ngOnInit(): void {
        this.initializeChart();
    }


    ngOnChanges(changes: SimpleChanges) {
        if (this.diagramData.data.length === 0) {
            return;
        }

        this.chartLoaded$.pipe(
            mergeMap(() => this.chart.ref$)
        ).subscribe((chart) => {
            // remove all existing series
            while (chart.series.length > 0) {
                chart.series[0].remove(false);
            }

            const chartTitle = this.isDetailChart ? null
                : this.determineTitleBasedOnTimeframe(this.diagramData.timeframe);
            // update the chart
            chart.update({
                xAxis: {
                    tickPositions: [
                        this.diagramData.data.first().x,
                        this.diagramData.data.last().x
                    ],
                    plotLines: [{
                        color: this.regularSeriesColor,
                        width: 1.5,
                        value: this.diagramData.averageBucketIdx + 0.5,
                        dashStyle: 'Dash',
                        label: {
                            text: `⌀ ${this.diagramData.averageConsumption} kWh`,
                            verticalAlign: 'bottom',
                            style: {
                                fontFamily: 'EONBrixSans, sans-serif',
                                fontSize: this.isDetailChart ? '18px' : '12px',
                                color: this.regularSeriesColor,
                            },
                            align: 'center',
                            rotation: 0,
                            y: this.isDetailChart ? 27 : 20,
                        }
                    }]
                },
                title: {
                    text: chartTitle
                },
                series: [
                    {
                        type: 'column',
                        name: 'test',
                        data: this.diagramData.data.map((data) => ({
                            y: data.y,
                            x: data.x,
                            color: data.highlighted
                                ? this.highlightedSeriesColor : this.regularSeriesColor,
                            custom: {
                                highlighted: data.highlighted,
                                calloutValue: this.diagramData.highlightedBucketCalloutValue
                            }
                        }))
                    }
                ],
            }, true, true);
        });
    }


    /**
     * Determines the title based on the timeframe.
     * @param timeframe
     * @private
     */
    private determineTitleBasedOnTimeframe(timeframe: HouseholdComparisonTimeframe): string {
        switch (timeframe) {
            case HouseholdComparisonTimeframe.CURRENT_YEAR:
                return 'Aktuelles Jahr';
            case HouseholdComparisonTimeframe.LAST_YEAR:
                return 'Letztes Jahr';
            case HouseholdComparisonTimeframe.LAST_MONTH:
                return moment().locale('de-DE').format('MMMM YYYY');
            // return 'Aktueller Monat';
        }
    }


    /**
     * Initializes the chart.
     * @private
     */
    private initializeChart(): void {
        const self = this;
        this.chart = new Chart({
            chart: {
                type: 'column',
                backgroundColor: 'transparent',
                events: {
                    load() {
                        self.chartLoaded$.next(null);
                    }
                },
                marginLeft: this.isDetailChart ? 60 : 50,
                marginTop: this.isDetailChart ? 50 : null,
            },
            title: {
                text: null,
                style: {
                    fontFamily: 'EONBrixSans, sans-serif',
                    fontSize: self.isDetailChart ? '18px' : '12px',
                    color: '#39393a',
                },
                y: 18,
                align: 'right'
            },
            xAxis: {
                labels: {
                    style: {
                        fontFamily: 'EONBrixSans, sans-serif',
                        fontSize: self.isDetailChart ? '18px' : '12px',
                        color: '#39393a',
                    },
                    formatter(evt) {
                        if (evt.isFirst) {
                            return 'niedrig';
                        }
                        if (evt.isLast) {
                            return 'hoch';
                        }
                        return '';
                    },
                    rotation: 0,
                    autoRotation: [0]
                },
            },
            yAxis: {
                endOnTick: false,
                title: {
                    text: 'kWh',
                    align: 'high',
                    textAlign: 'right',
                    x: this.isDetailChart ? 40 : 30,
                    y: this.isDetailChart ? -20 : -10,
                    rotation: 0,
                    style: {
                        fontFamily: 'EONBrixSans, sans-serif',
                        fontSize: self.isDetailChart ? '18px' : '12px',
                        fontWeight: 'bold',
                        color: '#39393a',
                    }
                },
                labels: {
                    align: 'right',
                    x: -8,
                    y: 5,
                    style: {
                        fontFamily: 'EONBrixSans, sans-serif',
                        fontSize: self.isDetailChart ? '18px' : '12px',
                        fontWeight: 'bold',
                        color: '#39393a',
                    },
                    formatter(evt) {
                        if (evt.isFirst) {
                            return '';
                        }
                        return `${evt.value}`;
                    }
                }
            },
            tooltip: {
                useHTML: true,
                formatter() {
                    const style = `style="border-color: ${this.color}"`;
                    const unit = 'kWh';

                    let valueFormatted = this.y.toFixed(0);

                    const p = this.point as any;
                    const highlighted = p.custom.highlighted;
                    if (highlighted) {
                        valueFormatted = p.custom.calloutValue.toFixed(0);
                    }

                    const value = `<div class="body"> ${valueFormatted} ${unit}</div>`;
                    const title = `Mein Verbrauch`;
                    const calloutTitleStr = `${highlighted ? title : ''}`;

                    return `<div class="column-callout" ${style}>${calloutTitleStr} ${value}</div>`;
                },
                borderColor: 'white',
                borderWidth: 0,
                borderRadius: 0,
                backgroundColor: 'transparent',
                shadow: false,
            },
            legend: {
                enabled: false
            },
            plotOptions: {
                column: {
                    borderRadius: 3,
                    groupPadding: 0,
                    pointPadding: 0.1,
                    states: {
                        hover: {
                            enabled: false
                        }
                    },
                },
            },
            credits: {
                enabled: false
            }
        });

    }

}
