import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {NilmService} from '../../../services/nilm.service';
import {ApplicationService} from '../../../services/application.service';
import {PopoverRef} from '../../../popovers/popover/popover-ref';
import {BasePopover} from '../../../classes/BasePopover';
import {TrackAnalyticsService} from '../../../services/track-analytics.service';
import {map, share} from 'rxjs/operators';
import {zip} from 'rxjs';
import {Router} from '@angular/router';
import {ProfileUpdateService} from '../../../services/profile-update.service';
import {AppliancesDetailLabels} from '../../../shared/texts/detail/appliances-detail.labels';
import {AppliancesDetailTexts} from '../../../shared/texts/detail/appliances-detail-texts';
import {ViewState} from '../../../shared/enums/view-state.enum';
import {
    AppliancesDetailDataMode,
    AppliancesDetailDisplayMode
} from '../../../shared/enums/appliances-detail-data-mode.enum';
import {
    appliancesProfileUpdateBanner,
    appliancesRetrainingBanner
} from '../../../shared/constants/banners/appliances-detail-banners.constants';
import {ComponentPortal, Portal} from '@angular/cdk/portal';
import {
    AppliancesDetailDataProviderService
} from '../../../services/data-provider/appliances-detail-data-provider.service';
import {
    ApplianceDetailDataDisplayComponent
} from '../../../components/appliances/appliance-detail-data-display/appliance-detail-data-display.component';
import {
    AppliancesRetrainingComponent
} from '../../../components/appliances/appliances-retraining/appliances-retraining.component';


@Component({
    selector: 'app-appliances-details',
    templateUrl: './appliances-detail.component.html',
    styleUrls: ['appliances-detail.component.scss'],
    viewProviders: []
})

export class AppliancesDetailsComponent extends BasePopover implements OnInit {
    protected readonly LABELS = AppliancesDetailLabels;
    protected readonly TEXTS = AppliancesDetailTexts;
    protected readonly ViewState = ViewState;

    protected DataMode = AppliancesDetailDataMode;
    protected DisplayMode = AppliancesDetailDisplayMode;

    protected profileUpdateBanner = appliancesProfileUpdateBanner;
    protected retrainingBanner = appliancesRetrainingBanner;

    currentMode: AppliancesDetailDataMode = AppliancesDetailDataMode.MONTH;
    currentDisplayMode: AppliancesDetailDisplayMode = AppliancesDetailDisplayMode.DATA;
    timeframeOffset = 0;

    infoVisible = false;
    showProfileUpdateReminder = false;
    profileUpdateBannerVisible = true;
    retrainingBannerVisible = false;

    selectedPortal: Portal<any>;
    dataDisplayPortal: ComponentPortal<ApplianceDetailDataDisplayComponent>;
    retrainSelectionPortal: ComponentPortal<any>;

    currentNilmPhaseAllowsRetraining$ = this.nilmService.getNilmStatusData().pipe(
        share(),
        map(data => {
            return this.application.isDemoMode() ? false : data.nilmStatus === 3;
        })
    );

    detailBanner$ = zip(
        this.profileUpdateService.onShowProfileIndicator,
        this.nilmService.onHasAppliancesInRetraining
    ).pipe(
        map(([showProfileUpdate, hasAppliancesInRetraining]) => {
            return {
                showRetrainingBanner: hasAppliancesInRetraining,
                showProfileUpdateBanner: showProfileUpdate
            };
            // if (hasAppliancesInRetraining) {
            //     return {showRetrainingBanner: true, showProfileUpdateBanner: false};
            // }
            // if (showProfileUpdate) {
            //     return {showRetrainingBanner: false, showProfileUpdateBanner: true};
            // }
        })
    );


    constructor(
        protected popoverRef: PopoverRef,
        private application: ApplicationService,
        private router: Router,
        private analytics: TrackAnalyticsService,
        private nilmService: NilmService,
        private profileUpdateService: ProfileUpdateService,
        private appliancesDetailDataProvider: AppliancesDetailDataProviderService,
        private cdRef: ChangeDetectorRef
    ) {
        super(popoverRef);
    }


    ngOnInit() {
        this.initPortals();
        this.initializeDetailView();
        this.profileUpdateService.onShowProfileIndicator.subscribe(show => {
            this.showProfileUpdateReminder = show;
        });
        this.profileUpdateService.checkProfileUpdateIndicatorDisplayDue(true);
        this.nilmService.triggerHasAppliancesInRetraining();
    }


    close(value: boolean = false) {
        super.close(value);
        this.appliancesDetailDataProvider.setCurrentDataMode(AppliancesDetailDataMode.MONTH, false);
        this.appliancesDetailDataProvider.resetApplianceData();
    }


    /**
     * Set data display mode
     * @param mode
     */
    setMode(mode: AppliancesDetailDataMode) {
        this.setDisplayMode(AppliancesDetailDisplayMode.DATA);
        if (mode === this.currentMode) {
            return;
        }
        this.currentMode = mode;
        this.appliancesDetailDataProvider.setCurrentDataMode(mode);
        this.timeframeOffset = 0;
        this.triggerModeTrackingEvent();
    }


    /**
     * Set display mode
     * @param mode
     */
    setDisplayMode(mode: AppliancesDetailDisplayMode) {
        if (mode === this.currentDisplayMode) {
            return;
        }
        if (mode === AppliancesDetailDisplayMode.DATA) {
            this.selectedPortal = this.dataDisplayPortal;
        } else {
            this.selectedPortal = this.retrainSelectionPortal;
        }
        this.currentDisplayMode = mode;
    }


    /**
     * Called when a component is attached to the portal.
     * @param ref
     */
    onComponentAttachedToPortal(ref: any): void {
        const instance = ref.instance;
        if (instance instanceof ApplianceDetailDataDisplayComponent) {
            instance.enterRetraining$.subscribe((enter) => {
                if (enter) {
                    this.setDisplayMode(AppliancesDetailDisplayMode.RETRAINING);
                }
            });
        }

        this.cdRef.detectChanges();
    }


    /**
     * Hide profile update reminder
     */
    hideProfileUpdateBanner(): void {
        this.profileUpdateBannerVisible = false;
        this.profileUpdateService.removeProfileUpdateIndicatorFlag();
    }


    /**
     * Route to 'profile' component
     */
    onProfileUpdateBannerInteraction(): void {
        this.close();
        this.router.navigate(['mein-haushalt']);
        this.profileUpdateService.removeProfileUpdateIndicatorFlag();
    }


    /**
     * Show retraining banner
     */
    showRetrainingView(): void {
        this.setDisplayMode(AppliancesDetailDisplayMode.RETRAINING);
    }


    /**
     * Initializes the detail view, assigning the default portal
     * @private
     */
    private initializeDetailView(): void {
        this.selectedPortal = this.dataDisplayPortal;
    }


    /**
     * Initialize available portals
     * @private
     */
    private initPortals() {
        this.dataDisplayPortal = new ComponentPortal(ApplianceDetailDataDisplayComponent);
        this.retrainSelectionPortal = new ComponentPortal(AppliancesRetrainingComponent);
    }


    /*
     * TRACKING
     * =============================================================================================
     */
    private triggerModeTrackingEvent(): void {
        this.analytics.trackEvent({
            action: 'screen_view',
            properties: {
                category: 'Screens',
                label: 'screen: Meine Geräte - ' +
                    (this.currentMode === AppliancesDetailDataMode.YEAR ? 'Jahresübersicht' : 'Monatsübersicht') +
                    '; previous_screen: Meine Geräte - ' +
                    (this.currentMode === AppliancesDetailDataMode.MONTH ? 'Monatsübersicht' : 'Jahresübersicht')
            }
        });
    }

}

