<div class="overlay">
    <div class="detail-overlay">
        <div class="detail-view">

            <section class="detail-head">
                <header class="detail-header">
                    <h1 class="detail-heading">Stecker</h1>

                    <button angulartics2On="click" angularticsCategory="Detailansichten"
                            angularticsAction="detail_info_text" angularticsLabel="PowerChecker"
                            (click)="infoVisible = !infoVisible">
                        <i class="info-sm dark-grey"></i>
                    </button>

                    <button (click)="close()">
                        <i class="close-sm dark-grey"></i>
                    </button>
                </header>
                <section class="powerchecker-details-head">

                    <div>
                        <app-eon-toggle
                            [state]="state"
                            (stateChanged)="onPlugStateChange($event)">
                        </app-eon-toggle>
                    </div>

                    <div>
                        <div class="plug-labels" *ngIf="!editModeEnabled; else editMode">
                            <div> {{plugRoom}} </div>
                            <div>{{plugName}}</div>
                        </div>
                        <ng-template #editMode>
                            <div class="plug-label-inputs">
                                <div>
                                    <fieldset class="eon-input">
                                        <legend>Mein Zimmer</legend>
                                        <input #room
                                               type="text" class="eon-input"
                                               [value]="plugRoom">
                                    </fieldset>
                                </div>
                                <div>
                                    <fieldset class="eon-input">
                                        <legend>Mein Gerät</legend>
                                        <input #name
                                               type="text" class="eon-input"
                                               [value]="plugName">
                                    </fieldset>
                                </div>
                            </div>
                        </ng-template>
                    </div>

                    <div>
                        <div class="pointer" (click)="onSaveChanges()">
                            <ng-container *ngIf="!editModeEnabled; else editModeIcon">
                                <i class="edit-sm dark-grey"></i>
                            </ng-container>
                            <ng-template #editModeIcon>
                                <i class="check-sm dark-grey"></i>
                            </ng-template>
                        </div>
                    </div>


                </section>
            </section>

            <section class="detail-info" *ngIf="infoVisible">
                <div class="info-icon">
                    <i class="info-sm dark-grey"></i>
                </div>
                <div class="info-text">
                    <h2 class="eon-black">E.ON Smart Control Stecker: Was ist das?</h2>
                    <p>
                        Gibt es einen Stromfresser in Ihrem Haushalt? Finden Sie es mit E.ON Smart Conrtol heraus. In
                        der ersten Woche gibt Ihnen E.ON Smart Control einen ersten Überblick und zeigt Ihnen
                        statistische Verbrauchswerte Ihrer Geräte an. Sie lernt dabei jeden Tag Ihre Geräte besser
                        kennen. Bereits nach einer Woche erkennt E.ON Smart Control Verbraucher, die ständig laufen.
                        Nach etwa vier Wochen hat E.ON Smart Control ihre zweite Lernphase abgeschlossen und
                        identifiziert zusätzliche Gerätekategorien wie Kochen und Waschen.
                    </p>
                </div>
            </section>

            <section class="detail-content">
                <h3>Entdecke, was dein E.ON Smart Control Stecker kann</h3>
                <p></p>

                <div class="m-b-m carousel-container">
                    <ngu-carousel #carousel [inputs]="carouselTileConfig" [dataSource]="slideshowAnimations">
                        <ngu-tile *nguCarouselDef="let item; let j = index" class="image-tile">
                            <div lottie [options]="item"></div>
                        </ngu-tile>


                        <div NguCarouselPoint
                             class="image-tile-point-list m-a-s">
                            <div *ngFor="let image of slideshowAnimations; let i = index"
                                 [class.active]="i===carousel.activePoint"
                                 (click)="carousel.moveTo(i)"
                                 class="image-tile-point m-a-s">
                            </div>
                        </div>

                        <div NguCarouselPrev class="carousel-control carousel-prev">
                            <button class="eon-icon-button small turquoise"
                                    [disabled]="carousel.isFirst">
                                <i class="chevron-left-s white"></i>
                            </button>
                        </div>
                        <div NguCarouselNext class="carousel-control carousel-next">
                            <button class="eon-icon-button small turquoise"
                                    [disabled]="carousel.isLast">
                                <i class="chevron-right-s white"></i>
                            </button>
                        </div>

                    </ngu-carousel>
                </div>
            </section>
        </div>
    </div>
</div>

<!--<div class="sidebar-head counter-wrap">-->

<!--    <div class="btn-wrap fltrt">-->
<!--        <button type="button" angulartics2On="click" angularticsCategory="Detailansichten"-->
<!--                angularticsAction="detail_info_text" angularticsLabel="PowerChecker" class="icon-info">-->
<!--            <span>Hilfe</span>-->
<!--        </button>-->
<!--        <button type="button" class="icon-close">-->
<!--            <span>Schließen</span>-->
<!--        </button>-->
<!--    </div>-->

<!--    &lt;!&ndash; title &ndash;&gt;-->
<!--    <h2>PowerChecker</h2>-->

<!--    &lt;!&ndash; Powerchecker stats &ndash;&gt;-->
<!--    <div class="m-t-x" fxLayout="row">-->
<!--        <div fxFlex="grow" fxLayout="column" class="m-r-x powerchecker-attributes">-->
<!--            <input #titleInput-->
<!--                   [disabled]="!editModeEnabled"-->
<!--                   [ngClass]="{'edit': editModeEnabled, 'text': !editModeEnabled, 'selectable' : editModeEnabled}"-->
<!--                   (focus)="onInputFocus($event)"-->
<!--                   type="text" class="title" value="{{plugName}}"-->
<!--                   placeholder="{{plugName === 'Mein Gerät' || plugName === '' || titleInput.value === '' ? 'Name' : plugName}}">-->
<!--            <input #locationInput-->
<!--                   [disabled]="!editModeEnabled"-->
<!--                   [ngClass]="{'edit': editModeEnabled, 'text': !editModeEnabled, 'selectable' : editModeEnabled}"-->
<!--                   (focus)="onInputFocus($event)"-->
<!--                   type="text" class="location" value="{{plugRoom}}"-->
<!--                   placeholder="{{plugRoom === 'Mein Zimmer' || plugRoom === '' || locationInput.value === ''? 'Raum' : plugRoom}}">-->
<!--        </div>-->
<!--        <div fxFlex="" fxLayout="column" fxLayoutAlign="center end">-->
<!--            <button class="edit-button" (click)="onSaveChanges(titleInput.value, locationInput.value)">-->
<!--                <ng-container *ngIf="editModeEnabled; else save">-->
<!--                    <img class="btn-icon" src="assets/images/ui-icons/confirm/cornfirmTransparent@3x.png" alt="">-->
<!--                </ng-container>-->
<!--                <ng-template #save>-->
<!--                    <img class="btn-icon" src="assets/images/ui-icons/edit/editTransparent@3x.png" alt="">-->
<!--                </ng-template>-->
<!--            </button>-->
<!--        </div>-->
<!--    </div>-->

<!--    <div class="" fxLayout="row" fxLayoutAlign="center center">-->
<!--        <app-power-switch [style]="{containerWidth: 200, elementSize: 75, darkTheme: true}"-->
<!--                          [state]="state"-->
<!--                          (stateChange)="onPlugStateChange($event)">-->
<!--        </app-power-switch>-->
<!--    </div>-->

<!--</div>-->

<!--<div class="myDevices-content">-->
<!--    <div class="msg-wrap hidden">-->
<!--        <div class="msg info icon-info">-->
<!--            <button class="icon-close" type="button">-->
<!--                <span>Schließen</span>-->
<!--            </button>-->
<!--            <h3>PowerChecker: Was ist das?</h3>-->
<!--            <p> Schalten Sie einen Verbraucher einfach und unkompliziert von Unterwegs Ein und Aus. Der E.ON Smart Control-->
<!--                PowerChecker ermöglicht es Ihnen, Ihren smarten Energiehaushalt nicht nur im Blick zu haben, sondern-->
<!--                auch aktiv zu steuern. Einfach mal ausprobieren!</p>-->
<!--        </div>-->
<!--    </div>-->

<!--    <div class="content-container">-->
<!--        <h3>Entdecke, was dein PowerChecker kann</h3>-->
<!--        &lt;!&ndash;        <p> Entfalten Sie zusammen mit innogy SmartHome das ganze Potenzial Ihres PowerCheckers! </p>&ndash;&gt;-->
<!--        &lt;!&ndash;        removed 27.04.2020&ndash;&gt;-->
<!--        <p></p>-->

<!--        <div class="m-b-m">-->
<!--            <ngu-carousel #carousel [inputs]="carouselTileConfig" [dataSource]="slideshowAnimations">-->
<!--                <ngu-tile *nguCarouselDef="let item; let j = index" class="image-tile">-->
<!--                    <lottie-animation-view-->
<!--                        [options]="item">-->
<!--                    </lottie-animation-view>-->
<!--                </ngu-tile>-->


<!--                <div NguCarouselPoint-->
<!--                     class="image-tile-point-list m-a-s"-->
<!--                     fxLayout="row" fxLayoutAlign="center center">-->
<!--                    <div *ngFor="let image of slideshowAnimations; let i = index"-->
<!--                         [class.active]="i===carousel.activePoint"-->
<!--                         (click)="carousel.moveTo(i)"-->
<!--                         class="image-tile-point m-a-s">-->
<!--                    </div>-->
<!--                </div>-->

<!--                <button NguCarouselPrev class="carousel-control carousel-prev"-->
<!--                        [style.opacity]="carousel.isFirst ? 0.3: 1">-->
<!--                    <i class="icon-arrow-rt"></i>-->
<!--                </button>-->
<!--                <button NguCarouselNext class="carousel-control carousel-next"-->
<!--                        [style.opacity]="carousel.isLast ? 0.3: 1">-->
<!--                    <i class="icon-arrow-rt"></i>-->
<!--                </button>-->

<!--            </ngu-carousel>-->
<!--        </div>-->

<!--        &lt;!&ndash;        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="24px">&ndash;&gt;-->
<!--        &lt;!&ndash;            <div>&ndash;&gt;-->
<!--        &lt;!&ndash;                <a target="_blank" href="https://iam.innogy.com/fuer-zuhause/smarthome" class="btn button">Mehr&ndash;&gt;-->
<!--        &lt;!&ndash;                    Informationen</a>&ndash;&gt;-->
<!--        &lt;!&ndash;            </div>&ndash;&gt;-->
<!--        &lt;!&ndash;            <h3>innogy SmartHome App</h3>&ndash;&gt;-->
<!--        &lt;!&ndash;            <div fxLayout="row" fxLayoutAlign="center center" class="store-links">&ndash;&gt;-->
<!--        &lt;!&ndash;                <a target="_blank" class="center-contents"&ndash;&gt;-->
<!--        &lt;!&ndash;                   href='https://play.google.com/store/apps/details?id=com.innogy.smarthome&hl=de&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>&ndash;&gt;-->
<!--        &lt;!&ndash;                    <img class="p-r-l" alt='Jetzt bei Google Play' src='assets/images/badges/googleplay.png'/>&ndash;&gt;-->
<!--        &lt;!&ndash;                </a>&ndash;&gt;-->
<!--        &lt;!&ndash;                <a target="_blank" class="center-contents"&ndash;&gt;-->
<!--        &lt;!&ndash;                   href="https://apps.apple.com/de/app/innogy-smarthome/id1133163302?mt=8">&ndash;&gt;-->
<!--        &lt;!&ndash;                    <img class="p-l-l" src="assets/images/badges/appstore.png" alt="Laden im AppStore">&ndash;&gt;-->
<!--        &lt;!&ndash;                </a>&ndash;&gt;-->
<!--        &lt;!&ndash;            </div>&ndash;&gt;-->
<!--        &lt;!&ndash;        </div>&ndash;&gt;-->

<!--    </div>-->
<!--</div>-->

