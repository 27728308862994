<main class="register-container" [ngStyle]="{background : determineBackgroundColor()}">

    <div class="info-button"
         [ngClass]="{bright: isBright()}"
         (click)="openFAQ()">
    </div>

    <!--
      -- ACCOUNT CREATION --------------------------------------
      -->
    <section class="white" *ngIf="currentStep === onboardingSteps.accountCreation">
        <div class="top-logo center-contents">
            <div class="eon-logo-new"></div>
        </div>
        <div class="app-logo-container center-contents">
            <div class="app-icon"></div>
        </div>

        <form class="login-form"
              [formGroup]="voucherForm"
              (ngSubmit)="validateVoucher()">
            <div class="grow-space">
                <fieldset class="eon-input">
                    <legend>E-Mail-Adresse</legend>
                    <input id="email" class="eon-input" type="text"
                           formControlName="email">
                    <label for="email"></label>
                </fieldset>

                <fieldset class="eon-input">
                    <legend>Code</legend>
                    <input id="code" class="eon-input" type="text"
                           formControlName="code">
                    <label for="code"></label>
                </fieldset>
            </div>

            <div class="center-contents btn-container">
                <button class="eon-button-new" type="submit">
                    Weiter
                </button>
            </div>
            <div class="center-contents">
                <a href="javascript:;" target="_self" class="eon-link-new"
                   routerLink="/">
                    Anmelden
                </a>
            </div>
        </form>
    </section>

    <!---- SET PASSWORD ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.passwordEntry">
        <div class="top-logo center-contents">
            <div class="eon-logo-new"></div>
        </div>
        <div class="app-logo-container center-contents">
            <div class="app-icon"></div>
        </div>
        <h1>Wählen Sie ein Passwort und bestätigen Sie es.</h1>
        <form class="login-form" [formGroup]="passwordForm" (ngSubmit)="registerUser()">
            <div class="grow-space">
                <div class="btn-container">
                    <fieldset class="eon-input">
                        <legend>Passwort</legend>
                        <input id="password1" class="eon-input" type="password"
                               formControlName="password1">
                        <label for="password1"></label>
                    </fieldset>
                    <p class="password-info">
                        Das Passwort muss aus 8 Stellen bestehen und mindestens einen Großbuchstaben sowie eine Zahl
                        enthalten.
                    </p>
                    <div class="password-strength">
                        <div class="password-strength-indicator">
                            <span *ngFor="let i of [0,1,2,3,4]"
                                  [style.background]="determinePasswordStrengthIndicatorColor(i)"
                                  [class.active]="i === currentPasswordScore"></span>
                        </div>
                        <div class="password-score-label eon-regular">
                            Passwort: <span class="eon-bold"
                                            [style.color]="determinePasswordStrengthColor()">
                            {{determinePasswordScoreRatingText()}}</span>
                        </div>
                    </div>
                </div>
                <div class="btn-container">
                    <fieldset class="eon-input">
                        <legend>Passwort wiederholen</legend>
                        <input id="password2" class="eon-input" type="password"
                               formControlName="password2">
                        <label for="password2"></label>
                    </fieldset>
                </div>
            </div>
            <div class="btn-container center-contents">
                <button class="eon-button-new"
                        type="submit"
                        [disabled]="setPasswordDisabled">
                    Weiter
                </button>
            </div>
        </form>
    </section>

    <!---- HARDWARE PRESENT ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.hardwarePresent">
        <div class="top-logo center-contents">
            <div class="eon-logo-new"></div>
        </div>
        <h1 class="m-b-x">Haben Sie alle notwendigen Komponenten für die Installation vorliegen?</h1>
        <div class="checklist-container">
            <ul class="checklist">
                <li class="eon-bold">{{ getDeviceTitle() }}</li>
                <li class="eon-bold">Optischer Ausleser</li>
                <li class="eon-bold">4-stellige Geräte-PIN des Stromzählers*</li>
                <li class="eon-bold">3 AA-Batterien oder USB-Netzteil</li>
                <li class="eon-bold">Zugang zu Ihrem Stromzähler</li>
            </ul>
            <p class="subtext">
                *Diese erhalten Sie in der Regel telefonisch von Ihrem Messstellenbetreiber. Die Information, wer Ihr
                Messstellenbetreiber ist, finden Sie auf Ihrer Jahresabrechnung oder ggf. auf dem Stromzähler.
            </p>
        </div>

        <div class="center-contents btn-container">
            <button class="eon-button-new"
                    (click)="setStep(onboardingSteps.analyticsOptIn)">
                Ja, jetzt einrichten
            </button>
        </div>
        <div class="center-contents">
            <a href="javascript:;" target="_self"
               class="eon-link-new"
               (click)="hardwareNotReady()">
                Nein, noch nicht.
            </a>
            <!--            <eon-ui-link href="javascript:;" text="Nein, noch nicht." target="_self"-->
            <!--                         (click)="hardwareNotReady()"></eon-ui-link>-->
        </div>

    </section>


    <!---- ANALYTICS ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.analyticsOptIn">
        <h1 class="m-b-x">Stimmen Sie zu, unseren Service vollumfänglich zu nutzen?</h1>

        <form class="login-form"
              (submit)="checkOptInResponse(trackingCb.checked, optinCb.checked, optinAnalyticsDataCb.checked)">
            <div class="permissions-list">
                <div class="grow-space">
                    <label class="eon-checkbox" for="tracking-checkbox">
                        <input #trackingCb type="checkbox" name="tracking-checkbox" id="tracking-checkbox">
                        Google Analytics
                    </label>
                    <div class="btn-container font-regular">
                        Wir nutzen Google Analytics, um das Nutzungsverhalten auf unserer App zu analysieren und um
                        die Funktionen und die Nutzung der App zu verbessern. Zudem speichert Google Analytics
                        Informationen auf ihrem Endgerät und greift auf Informationen zu. Die erzeugten
                        Informationen über die Benutzung der App werden an Google übertragen und dort gespeichert.
                        Mit der Aktivierung willigen Sie in die vorgenannte Verarbeitung Ihrer personenbezogenen
                        Daten ein. Ihre Einwilligung können Sie jederzeit, mit Wirkung für die Zukunft widerrufen,
                        indem Sie Google Analytics über den Schalter deaktivieren.
                    </div>
                </div>

                <div class="grow-space">
                    <label class="eon-checkbox" for="optin-checkbox">
                        <input #optinCb type="checkbox" name="optin-checkbox" id="optin-checkbox">
                        Online Modus
                    </label>
                    <div class="btn-container font-regular">
                        Wenn Sie alle Funktionen von E.ON Smart Control nutzen möchten, können Sie hier für die Zukunft
                        in
                        den Online-Modus wechseln. Damit geben Sie uns Ihre Einwilligung um auf die
                        Verbrauchsdaten {{device === 'plug' && this.deviceTitle.includes('2.0') ? 'Ihrer E.ON Smart Control Box 2.0' : device === 'plug' ? 'Ihres E.ON Smart Control Steckers' : ' Ihrer E.ON Smart Control Box' }}
                        zugreifen zu dürfen. Dies beinhaltet unter anderem ihren Stromverbrauch in Sekunden-Auflösung.
                        Dieser Zugriff ist notwendig, damit wir Ihnen den vollen Funktionsumfang - der in den
                        Nutzungsbedingungen der App beschrieben wird – anbieten können. Die weitere Verarbeitung dieser
                        Daten wird in unseren Datenschutzhinweisen und den folgenden Einwilligungserklärungen
                        beschrieben.
                        Sie können Ihre Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen indem Sie die
                        Option
                        in den Einstellungen wieder deaktivieren.
                    </div>
                </div>

                <div class="grow-space">
                    <label class="eon-checkbox" for="data-checkbox">
                        <input #optinAnalyticsDataCb type="checkbox" name="data-checkbox" id="data-checkbox">
                        Opt-In Datenverarbeitung
                    </label>
                    <div class="btn-container font-regular">
                        Ich bin damit einverstanden, dass die Messwerte meiner Messeinrichtung und die freiwilligen
                        Angaben in meinem Haushaltsprofil durch die E.ON Energie Deutschland GmbH dazu verwendet werden,
                        dass Analysen zur Produkt-, Projekt-, und Prozessverbesserung (z.B. Lastprofil, Beschaffung,
                        Stromverbrauchsmuster) sowie zu Presse- und Kommunikationszwecken erstellt werden. Diese
                        Einwilligung können Sie jederzeit mit Wirkung für die Zukunft widerrufen, indem Sie den
                        Schieberegler in den Einstellungen zurückschieben.
                    </div>
                </div>
            </div>

            <div class="center-contents btn-container">
                <button type="submit" class="eon-button-new">
                    Weiter
                </button>
            </div>
        </form>
    </section>

    <!---- BOX POWER CONNECT ---------------------------------------->
    <section *ngIf="currentStep === onboardingSteps.devices.box.powerConnect">
        <h1 class="m-b-x">Bitte verbinden Sie die {{ isSmartBox2() ? getDeviceTitle() : 'Hardware' }} jetzt mit der Stromversorgung.</h1>
        <p class="white">
            Für eine gute Verbindungsqualität platzieren Sie das Gerät bitte in W-LAN-Reichweite so nah wie möglich bei
            Ihrem Zähler. Die Geräte müssen sich zu diesem Zeitpunkt noch nicht beim Zähler befinden. WLAN muss verfügbar sein.
        </p>
        <div class="device-video-container">
            <video class="m-v-x" width="100%" height="100%" autoplay muted loop>
                <source type="video/mp4" [src]="isSmartBox2() ? '../../../assets/media/onboarding/onboarding_connect_power_2.mp4' : '../../../assets/media/onboarding/onboarding_connect_power.mp4'">
            </video>
        </div>

        <div class="last-growing">
            <button class="eon-button-new turquoise-inverted"
                    (click)="setStep(onboardingSteps.devices.box.lanConnect)">
                Hab ich gemacht
            </button>
        </div>
    </section>

    <!---- BOX LAN CONNECT ---------------------------------------->
    <section *ngIf="currentStep === onboardingSteps.devices.box.lanConnect">
        <h1 class="m-b-x">Verbinden Sie die E.ON Smart Control-Box über das mitgelieferte Netzwerkkabel mit Ihrem
            Router.</h1>
        <div class="device-video-container">
            <video width="100%" height="100%" autoplay muted loop>-->
                <source src="../../../assets/media/onboarding/onboarding_connect_lan.mp4" type="video/mp4">
            </video>
        </div>
        <div class="last-growing">
            <button class="eon-button-new turquoise-inverted"
                    (click)="setStep(onboardingSteps.devices.box.macEntry)">
                Hab ich gemacht
            </button>
        </div>
    </section>

    <!---- BOX MAC ENTRY ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.box.macEntry">
        <div class="top-logo center-contents">
            <div class="eon-logo-new"></div>
        </div>
        <h1>Geben Sie die MAC-ID von der Rückseite Ihrer E.ON Smart Control Box ein.</h1>
        <h2>Damit verknüpfen wir Ihre E.ON Smart Control Box mit Ihrem Account.</h2>
        <form class="login-form m-t-x" (submit)="setFirstMACAddress(mac.value)">
            <div class="grow-space">
                <div class="btn-container">
                    <fieldset class="eon-input">
                        <legend>MAC-ID</legend>
                        <input #mac class="eon-input" type="text"
                               [textMask]="macAddressMask"
                               [(ngModel)]="firstMac" [ngModelOptions]="{standalone: true}"
                               [placeholder]="macPlaceholder">
                    </fieldset>
                </div>
            </div>

            <div class="center-contents btn-container">
                <button type="submit" class="eon-button-new">
                    Weiter
                </button>
            </div>
        </form>
    </section>

    <!---- BOX MAC ENTRY 2 ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.box.macEntry2">
        <div class="top-logo center-contents">
            <div class="eon-logo-new"></div>
        </div>
        <h1>Bitte überprüfen Sie die eingegebene Adresse erneut. Stimmt Sie mit dem Aufkleber auf der E.ON Smart Control
            Box überein?</h1>
        <form class="login-form m-t-x" (submit)="registerDevice(mac2.value,true)">
            <div class="grow-space">
                <div class="btn-container">
                    <fieldset class="eon-input">
                        <legend>MAC-ID</legend>
                        <input #mac2 class="eon-input" type="text"
                               [textMask]="macAddressMask"
                               [(ngModel)]="secondMac" [ngModelOptions]="{standalone: true}"
                               [placeholder]="macPlaceholder">
                    </fieldset>
                </div>
            </div>

            <div class="center-contents btn-container">
                <button type="submit" class="eon-button-new">
                    Weiter
                </button>
            </div>
        </form>
    </section>

    <!---- BOX CONNECTION ---------------------------------------->
    <section *ngIf="currentStep === onboardingSteps.devices.box.connecting"
             class="big connection">
        <h1>Wir verbinden jetzt Ihre E.ON Smart Control Box mit Ihrem Smart Meter</h1>
        <div>
            <ng-lottie class="loading-smartmeter" height="108px" [options]="lottieConfig2"
                       (animationCreated)="handleAnimation($event)"></ng-lottie>
            <ul class="connection-progress">
                <li [ngClass]="{'finished': (meterStatus > 0), 'progress': (meterStatus <= 0)}">
                    <div class="progress-indicator" [ngClass]="{done: meterStatus > 0}"></div>
                    <div class="progress-label">E.ON Smart Control-Box vorbereiten</div>
                </li>
                <li [ngClass]="{'finished': (meterStatus > 1), 'progress': (meterStatus == 1)}">
                    <div class="progress-indicator" [ngClass]="{done: meterStatus > 1}"></div>
                    <div class="progress-label">Smart Meter suchen</div>
                </li>
                <li [ngClass]="{'finished': (meterStatus > 2), 'progress': (meterStatus == 2)}">
                    <div class="progress-indicator" [ngClass]="{done: meterStatus > 2}"></div>
                    <div class="progress-label">Mit Smart Meter verbinden</div>
                </li>
            </ul>
            <ng-container *ngIf="meterStatus > 0">
                <div>
                    <button type="submit" class="eon-button-new turquoise-inverted"
                            (click)="isEDGUser ? setStep(onboardingSteps.tariffEntry) :
                                   setStep(onboardingSteps.accountSetup)">
                        Schon ab ins Dashboard
                    </button>
                </div>
            </ng-container>
        </div>
    </section>

    <!---- PLUG NOTE MAC ---------------------------------------->
    <section *ngIf="currentStep === onboardingSteps.devices.plug.noteMac">
        <h1>Bitte notieren Sie sich die MAC-Adresse von der Rückseite {{isSmartBox2() ? 'Ihres E.ON Smart Control Box 2.0' : 'Ihres E.ON Smart Control Steckers'}}.</h1>
        <div class="center-contents m-v-x">
            <img
                [src]="!isSmartBox2() ? '../../../assets/img/onboarding/plug-mac.png' : '../../../assets/img/onboarding/mac_box.png'"
                alt="" [style.height]="isSmartBox2() ? '170px' : '400px'">
        </div>
        <div class="last-growing">
            <button type="submit" class="eon-button-new turquoise-inverted"
                    (click)="setStep(onboardingSteps.devices.plug.powerConnect)">
                Weiter
            </button>
        </div>
    </section>

    <!---- PLUG POWER CONNECT ---------------------------------------->
    <section *ngIf="currentStep === onboardingSteps.devices.plug.powerConnect">
        <h1 class="m-b-x">Bitte verbinden Sie die {{ isSmartBox2() ? getDeviceTitle() : 'Hardware' }} jetzt mit der Stromversorgung.</h1>
        <p class="white">
            Für eine gute Verbindungsqualität platzieren Sie das Gerät bitte in W-LAN-Reichweite so nah wie möglich bei
            Ihrem Zähler. Die Geräte müssen sich zu diesem Zeitpunkt noch nicht beim Zähler befinden. WLAN muss
            verfügbar sein.
        </p>
        <div class="device-video-container">
        <video class="m-v-x" width="100%" autoplay muted loop>
            <source type="video/mp4" [src]="isSmartBox2() ? '../../../assets/media/onboarding/onboarding_connect_power_2.mp4' : '../../../assets/media/onboarding/onboarding_connect_power.mp4'">
        </video>
        </div>
        <div class="last-growing">
            <button type="submit" class="eon-button-new turquoise-inverted"
                    (click)="setStep(onboardingSteps.devices.plug.appSelect)">
                Weiter
            </button>
        </div>
    </section>

    <!---- APP SELECT ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.plug.appSelect">
        <h1>Der {{ getDeviceTitle() }} muss nun mit Ihrem WLAN verbunden werden. Einfach und unkompliziert
            funktioniert dies über WPS.</h1>
        <div class="center-contents m-v-x">
            <button type="submit" class="eon-button-new"
                    (click)="setStep(onboardingSteps.devices.plug.wpsConnect)">
                Weiter
            </button>
        </div>
        <div>
            <p>
                Sollte Ihr Router kein WPS unterstützen, so verwenden Sie bitte die E.ON Smart Control-App auf dem
                Smartphone/Tablet.
                Nähere Informationen zu WPS finden Sie in der Bedienungsanleitung Ihres Routers.
            </p>
            <div class="store-links m-b-l">
                <a target="_blank" class="center-contents"
                   href='https://play.google.com/store/apps/details?id=com.iona_energy.android&hl=en'>
                    <img class="p-r-l" alt='Jetzt bei Google Play' src='../../../assets/images/badges/googleplay.png'/>
                </a>
                <a target="_blank" class="center-contents"
                   href="https://apps.apple.com/de/app/iona/id1434046824">
                    <img class="p-l-l" src="../../../assets/images/badges/appstore.png" alt="Laden im AppStore">
                </a>
            </div>
        </div>

    </section>

    <!---- WPS CONNECT ---------------------------------------->
    <section *ngIf="currentStep === onboardingSteps.devices.plug.wpsConnect">
        <h1 *ngIf="isSmartBox2()">Drücken Sie den Ein-Aus-Schalter der E.ON Smart Control Box 2.0 ca. 10 Sekunden bis diese schnell blau blinkt.</h1>
        <h1 *ngIf="!isSmartBox2()">Drücken Sie den Ein-Aus-Schalter des E.ON Smart Control Steckers ca. 10 Sekunden bis dieser schnell grün
            blinkt.</h1>
        <div class="m-v-x">
            <video width="100%" height="100%" autoplay muted loop>
                <source type="video/mp4" [src]=" isSmartBox2() ? '../../../assets/media/box_2.mp4' : '../../../assets/media/powerchecker-2.mp4'">
            </video>
        </div>
        <div class="last-growing">
            <button type="submit" class="eon-button-new turquoise-inverted"
                    (click)="setStep(onboardingSteps.devices.plug.wpsRouter)">
                Weiter
            </button>
        </div>
    </section>

    <!---- WPS ROUTER ---------------------------------------->
    <section *ngIf="currentStep === onboardingSteps.devices.plug.wpsRouter">
        <h1>Aktivieren Sie jetzt innerhalb von 2 Minuten die WPS-Funktion an Ihrem Router.</h1>
        <p class="info-white">
            In der Regel erfolgt dies durch längeres Drücken eines entsprechend bezeichneten Knopfes. Nähere
            Informationen zu WPS finden Sie in der Bedienungsanleitung Ihres Routers.
        </p>
        <div class="m-v-x">
            <video width="100%" height="100%" autoplay muted loop>
                <source src="../../../assets/media/wlan.mp4" type="video/mp4">
            </video>
        </div>
        <div class="last-growing">
            <button type="submit" class="eon-button-new turquoise-inverted"
                    (click)="setStep(onboardingSteps.devices.plug.wifiConnected)">
                Weiter
            </button>
        </div>
    </section>

    <!---- WPS CONNECTED---------------------------------------->
    <section *ngIf="currentStep === onboardingSteps.devices.plug.wifiConnected">
        <h1>Bitte warten Sie, bis der {{ getDeviceTitle() }} konstant blau leuchtet. Dies kann bis zu 3 Minuten
            dauern.</h1>
        <div class="m-v-x">
            <img [src]="isSmartBox2() ? '../../../assets/images/box-onboarding-final-state.png' : '../../../assets/images/powerchecker-onboarding-final-state.png'"
                 alt="Blau leuchtender Powerchecker" [style.height]="isSmartBox2() ? '300px' : '400px'">
        </div>
        <div class="last-growing">
            <button type="submit" class="eon-button-new turquoise-inverted"
                    (click)="setStep(onboardingSteps.devices.plug.macEntry)">
                Weiter
            </button>
        </div>
    </section>

    <!---- PLUG MAC ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.plug.macEntry">
        <div class="top-logo center-contents">
            <div class="eon-logo-new"></div>
        </div>
        <h1>Geben Sie die MAC-ID von der Rückseite Ihres {{isSmartBox2() ? getDeviceTitle() : 'E.ON Smart Control Steckers'}} ein.</h1>
        <h2>Damit verknüpfen wir Ihren {{ getDeviceTitle() }} mit Ihrem Account.</h2>
        <form class="login-form m-t-x"
              [formGroup]="deviceMacForm"
              (submit)="setPlugMacAddress()">
            <div class="grow-space">
                <div class="btn-container">
                    <fieldset class="eon-input">
                        <legend>MAC-ID</legend>
                        <input class="eon-input" type="text"
                               [formControl]="deviceMacForm.controls.mac"
                               [textMask]="macAddressMask"
                               [placeholder]="macPlaceholder">
                    </fieldset>
                </div>
            </div>

            <div class="center-contents btn-container">
                <button type="submit" class="eon-button-new"
                        [disabled]="deviceMacForm.invalid">
                    Weiter
                </button>
            </div>
        </form>
    </section>


    <!---- READER MAC ENTRY ---------------------------------------->
    <!----------------------------------------------------------------------------------------------------------------->
    <section *ngIf="currentStep === onboardingSteps.devices.plug.orSetup.findAndEnterORMacAddress">
        <h1>Verknüpfung des Optischen Auslesers durch Eingabe der MAC-ID</h1>
        <p class="white">
            Die MAC-Adresse der Sendeeinheit Ihres optischen Auslesers finden Sie auf der Rückseite des Gerätes.
        </p>
        <form class="login-form m-t-x"
              [formGroup]="readerMacForm"
              (ngSubmit)="setOpticalReaderMacAddress()">
            <div class="grow-space">
                <fieldset class="eon-input white">
                    <legend>MAC-ID</legend>
                    <input type="text" class="iona-input"
                           [minlength]="16"
                           [formControl]="readerMacForm.controls.mac"
                           [textMask]="opticalReaderMacAddressMask"
                           [placeholder]="opticalReaderMacPlaceholder">
                </fieldset>
            </div>
            <div class="grow-space">
                <img src="/assets/img/onboarding/optical-reader-mac.png" width="100%">
            </div>
            <div class="center-contents btn-container">
                <button class="eon-button-new turquoise-inverted"
                        type="submit"
                        [disabled]="readerMacForm.invalid">
                    Weiter
                </button>
            </div>
        </form>
    </section>


    <!-- READER & PLUG POSITIONING -->
    <!----------------------------------------------------------------------------------------------------------------->
    <section *ngIf="currentStep === onboardingSteps.devices.plug.orSetup.positioningOR">
        <h1>Vorbereitung der Hardware</h1>
        <p *ngIf="isSmartBox2()" class="white">
            Bitte legen Sie die Sendeeinheit des Optischen Auslesers in direkte Nähe der Smart Control Box 2.0. Lassen Sie die Smart Control Box 2.0 mit dem Strom und Internet verbunden.
        </p>
        <p *ngIf="!isSmartBox2()" class="white">
            Bitte legen Sie die Sendeeinheit des optischen Auslesers in die direkte Nähe des E.ON SmartControl Steckers. Lassen Sie den Stecker mit dem Strom verbunden.
        </p>
        <div class="grow-space">
            <div class="center-contents p-a-x">
                <img [src]="isSmartBox2() ? '/assets/img/onboarding/box-2.0-smart-connect.png' : '/assets/img/onboarding/optical-reader-powerchecker-placement.png'" [style.width]="isSmartBox2() ? '135%' : '115%'">
            </div>
        </div>
        <div class="center-contents btn-container">
            <button class="eon-button-new turquoise-inverted"
                    (click)="setStep(onboardingSteps.devices.plug.orSetup.preparingPlug)">
                Weiter
            </button>
        </div>
    </section>


    <!-- PRECONDITION STATE -->
    <!----------------------------------------------------------------------------------------------------------------->
    <section *ngIf="currentStep === onboardingSteps.devices.plug.orSetup.preparingPlug">
        <h1>Vorbereitung der Hardware</h1>
        <p *ngIf="isSmartBox2()" class="white">
            Die Smart Control Box 2.0 wird nun mit dem System verbunden. Dies kann einige Minuten dauern.
        </p>
        <p *ngIf="!isSmartBox2()" class="white">
            Ihr E.ON Smart Control Stecker wird jetzt im System registriert. Dies kann einige Minuten dauern. Bitte
            lassen Sie den Stecker mit dem und dem Internet verbunden.
        </p>
        <div class="flex-col grow-space">
            <div class="center-contents p-a-x">
                <img [src]="isSmartBox2() ? '/assets/img/onboarding/smart-box-2.0.png' : '/assets/img/onboarding/powerchecker-ready.webp'" [style.width]="isSmartBox2() ? '100%' : '30%'">
            </div>
            <div class="flex grow-space center-contents">
                <ng-container *ngIf="!registrationOnboardSuccessful">
                    <ng-lottie [options]="loadingLottieConfig" width="150px" height="150px"></ng-lottie>
                </ng-container>
                <ng-container *ngIf="registrationOnboardSuccessful">
                    <img src="/assets/img/onboarding/loading-done.webp" width="65px" height="65px">
                </ng-container>
            </div>
        </div>
        <div class="center-contents btn-container">
            <button class="eon-button-new turquoise-inverted"
                    [disabled]="!registrationOnboardSuccessful"
                    (click)="setStep(onboardingSteps.devices.plug.orSetup.powerReaderAndStartCommission)">
                Weiter
            </button>
        </div>
    </section>


    <!-- START COMMISSIONING -->
    <!----------------------------------------------------------------------------------------------------------------->
    <section *ngIf="currentStep === onboardingSteps.devices.plug.orSetup.powerReaderAndStartCommission">
        <h1 *ngIf="isSmartBox2()">Kopplung des Optischen Auslesers</h1>
        <h1 *ngIf="!isSmartBox2()">Kopplung PowerChecker und Sendeeinheit des optischen Auslesers</h1>
        <p *ngIf='commissionStatus !== "initial"' class="white">
            {{getDeviceTitle()}} und Optischer Ausleser werden jetzt im System miteinander verbunden.
            <b>Dies kann bis zu 10 Minuten dauern.</b> Bitte lassen Sie den {{getDeviceTitle()}} und die Sendeeinheit des
            Optischen Auslesers weiterhin nebeneinander liegen.
        </p>
        <p *ngIf='commissionStatus === "initial"' class="white">
            Schalten Sie die Sendeeinheit des optischen Auslesers ein, indem Sie entweder 3 AA-Batterien einlegen oder
            ein 5V-USB-Netzteil verbinden. Drücken Sie den Knopf auf der Sendeeinheit des optischen Auslesers für ca. 1
            Sekunde. Die blaue LED sollte kurz aufblinken. Bitte lassen Sie {{getDeviceTitle()}} und Sendeeinheit nebeneinander
            liegen.
        </p>
        <div class="flex-col grow-space center-contents">
            <ng-container [ngSwitch]="commissionStatus">
                <ng-container *ngSwitchCase="'loading'">
                    <ng-lottie [options]="loadingLottieConfig" width="150px" height="150px"></ng-lottie>
                    <p class="m-t-l center-text white">
                        Verbindung zwischen {{getDeviceTitle()}} und Optischen Ausleser wird hergestellt.
                    </p>
                </ng-container>
                <ng-container *ngSwitchCase="'success'">
                    <img src="/assets/img/onboarding/loading-done.webp" width="65px" height="65px">
                    <p class="m-t-l center-text white">
                        {{getDeviceTitle()}} und Optischer Ausleser wurden erfolgreich miteinander verbunden.
                    </p>
                    <div class="center-contents btn-container">
                        <button class="eon-button-new turquoise-inverted"
                                [disabled]="false"
                                (click)="startFirmwareUpdate()">
                            Weiter
                        </button>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'error'">
                    <img src="/assets/img/onboarding/loading-info.webp" width="75px" height="65px">
                    <p class="m-t-l center-text white">
                        Die Verbindung zwischen {{isSmartBox2() ? getDeviceTitle() :'Smart Control Stecker'}}und Optischen Ausleser konnte nicht hergestellt werden.
                        Bitte geben Sie die MAC-Adresse erneut ein oder kontaktieren Sie unseren Support.
                    </p>
                    <div class="center-contents btn-container">
                        <button class="eon-button-new turquoise-inverted commissionErrorButtons" (click)="resetMacAddressEntry()" [disabled]="false">
                        Erneut eingeben
                        </button>
                    </div>
                    <div class="center-contents btn-container">
                        <button class="eon-button-new turquoise-inverted commissionErrorButtons" [disabled]="false" (click)="openSupportDialog()">
                            Support
                        </button>
                    </div>
                    <div class="center-contents btn-container">
                        <button class="eon-button-new turquoise-inverted commissionErrorButtons" [disabled]="true">
                            Weiter
                        </button>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'initial'">
                    <div class="m-v-x">
                        <video width="100%" height="100%" autoplay muted loop>-->
                            <source src="assets/media/onboarding/power-and-button.mp4" type="video/mp4">
                        </video>
                    </div>
                    <div class="center-contents btn-container">
                        <button class="eon-button-new turquoise-inverted"
                                (click)="startCommission()">
                            Weiter
                        </button>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </section>


    <!-- AFTER UPDATE POWERCYCLE OR-->
    <!----------------------------------------------------------------------------------------------------------------->
    <section *ngIf="currentStep === onboardingSteps.devices.plug.orSetup.infoProcessDuration">
        <h1>Suche nach Firmware-Update</h1>
        <p class="white">
            Es wird nun nach einem Update des Optischen Auslesers gesucht. Dies kann einige Minuten in Anspruch nehmen.
            Bitte lassen Sie den {{getDeviceTitle()}} und die Sendeeinheit des Optischen Auslesers weiterhin nebeneinander
            liegen.
        </p>
        <div class="flex-col grow-space center-contents">
            <ng-container [ngSwitch]="firmwareUpdateStatus">
                <ng-container *ngSwitchCase="'loading'">
                    <ng-lottie [options]="loadingLottieConfig" width="150px" height="150px"></ng-lottie>
                    <p class="m-t-l center-text white">
                        iONA überprüft, ob ihr optischer Ausleser ein Firmware-Update benötigt.
                    </p>
                </ng-container>
                <ng-container *ngSwitchCase="'uptodate'">
                    <img src="/assets/img/onboarding/loading-done.webp" width="65px" height="65px">
                    <p class="m-t-l center-text white">
                        Die Firmware ihres optischen Auslesers ist aktuell.
                    </p>
                </ng-container>
                <ng-container *ngSwitchCase="'error'">
                    <img src="/assets/img/onboarding/loading-info.webp" width="65px" height="65px">
                    <p class="m-t-l center-text white">
                        iONA konnte nicht überprüfen, ob die Firmware ihres optischen Auslesers ein Update
                        benötigt.
                    </p>
                    <p class="white">
                        Sie können fortfahren und es später erneut versuchen.
                    </p>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="firmwareIsUpdating">
                iONA überprüft, ob ihr optischer Ausleser ein Firmware-Update benötigt
            </ng-container>
        </div>
        <div class="center-contents btn-container">
            <button class="eon-button-new turquoise-inverted"
                    [disabled]="!(firmwareUpdateStatus === 'uptodate' || firmwareUpdateStatus === 'error')"
                    (click)="setStep(onboardingSteps.deviceSelection)">
                Weiter
            </button>
        </div>
    </section>

    <!-- OPTICAL READER DEVICE SELECTION -->
    <section *ngIf="currentStep === onboardingSteps.deviceSelection">
        <h1>Aufbau</h1>
        <p class="white">
            Der Optische Ausleser liest die Verbrauchsdaten des Zählers über die optische Schnittstelle aus und sendet
            diese mittels des {{getDeviceTitle()}} an das E.ON Smart Control - Rechenzentrum zur Darstellung in
            der App. Für eine gute Verbindungsqualität platzieren Sie {{getDeviceTitle()}} bitte in
            W-LAN-Reichweite so nah wie möglich bei Ihrem Zähler.
        </p>
        <div class="flow-image-container">
            <div>
                <img src="assets/img/graphics/optical-reader-flow.png" alt="" width="100%">
            </div>
        </div>

        <div class="last-growing">
            <button class="eon-button-new turquoise-inverted"
                    (click)="setStep(onboardingSteps.devices.plug.orSetup.readerInstallationStep1)">
                Verstanden!
            </button>
        </div>
    </section>


    <!-- OPTICAL READER INSTALLATION STEP 1 -->
    <!----------------------------------------------------------------------------------------------------------------->
    <section *ngIf="currentStep === onboardingSteps.devices.plug.orSetup.readerInstallationStep1">
        <h1>
            Installation des Optischen Auslesers 1/4
        </h1>
        <p class="white">
            Bitte gehen Sie jetzt mit dem Optischen Ausleser (Auslesekopf und Sendeeinheit) zu Ihrem Stromzähler.
            Platzieren Sie den Auslesekopf auf der optischen Schnittstelle Ihres Zählers. Für eine korrekte Ausrichtung
            muss das Kabel gerade nach unten zeigen.
        </p>

        <div class="m-v-x">
            <video width="100%" height="100%" autoplay muted loop>-->
                <source src="../../../assets/media/onboarding/optical_reader/erna-04.mp4" type="video/mp4">
            </video>
        </div>

        <div class="last-growing">
            <button class="eon-button-new turquoise-inverted"
                    (click)="setStep(onboardingSteps.devices.plug.orSetup.readerInstallationStep2)">
                Schritt 1 von 4 erledigt
            </button>
        </div>
    </section>


    <!-- OPTICAL READER INSTALLATION STEP 2 -->
    <!----------------------------------------------------------------------------------------------------------------->
    <section *ngIf="currentStep === onboardingSteps.devices.plug.orSetup.readerInstallationStep2">
        <h1>Installation des Optischen Auslesers 2/4 </h1>
        <p class="white">
            Verbinden Sie das Kabel des Auslesekopfes mit der Sendeeinheit des optischen Auslesers
        </p>
        <div class="m-v-x">
            <video width="100%" height="100%" autoplay muted loop>-->
                <source src="../../../assets/media/onboarding/optical_reader/erna-05.mp4" type="video/mp4">
            </video>
        </div>

        <div class="last-growing">
            <button class="eon-button-new turquoise-inverted"
                    (click)="setStep(onboardingSteps.devices.plug.orSetup.readerInstallationStep3)">
                Schritt 2 von 4 erledigt
            </button>
        </div>
    </section>


    <!-- OPTICAL READER INSTALLATION STEP 3 -->
    <!----------------------------------------------------------------------------------------------------------------->
    <section *ngIf="currentStep === onboardingSteps.devices.plug.orSetup.readerInstallationStep3">
        <h1>Installation des Optischen Auslesers 3/4 </h1>
        <p class="white">
            Bitte starten Sie den Optischen Ausleser neu, indem Sie ihn zunächst kurz von der Stromversorgung trennen.
            <br>
            Schalten Sie die Sendeeinheit des Optischen Auslesers erneut ein, indem Sie entweder 3 AA-Batterien einlegen
            oder ein 5V-USB-Netzteil verbinden.
        </p>

        <h1>Schalten Sie die Sendeeinheit des optischen Auslesers ein, indem Sie entweder 3 AA-Batterien einlegen oder
            ein 5V-USB-Netzteil verbinden.</h1>
        <div class="info-white">(nicht enthalten)</div>

        <div class="m-v-x">
            <video width="100%" height="100%" autoplay muted loop>-->
                <source src="../../../assets/media/onboarding/powercycle.mp4" type="video/mp4">
            </video>
        </div>

        <div class="last-growing">
            <button class="eon-button-new turquoise-inverted"
                    (click)="setStep(onboardingSteps.devices.plug.orSetup.readerInstallationStep4)">
                Schritt 3 von 4 erledigt
            </button>
        </div>
    </section>


    <!-- OPTICAL READER INSTALLATION STEP 4 -->
    <!----------------------------------------------------------------------------------------------------------------->
    <section *ngIf="currentStep === onboardingSteps.devices.plug.orSetup.readerInstallationStep4">
        <h1>Installation des Optischen Auslesers 4/4 </h1>
        <p class="white">
            Drücken Sie den Knopf auf der Sendeeinheit des optischen Auslesers für ca. 1 Sekunde. Die blaue LED sollte
            kurz aufblinken.
        </p>
        <div class="m-v-x">
            <video width="100%" height="100%" autoplay muted loop>-->
                <source src="../../../assets/media/onboarding/optical_reader/erna-07.mp4" type="video/mp4">
            </video>
        </div>

        <div class="last-growing">
            <button class="eon-button-new turquoise-inverted"
                    (click)="afterOrOnboardingComplete()">
                Alle Schritte erledigt
            </button>
        </div>
    </section>


    <!---- PLUG CONNECTION ---------------------------------------->
    <section *ngIf="currentStep === onboardingSteps.devices.plug.connecting"
             class="big connection">
        <h1>Wir verbinden jetzt Ihren {{getDeviceTitle()}} mit Ihrem Smart Meter.</h1>
        <div>
            <ng-lottie class="loading-smartmeter" height="108px" [options]="lottieConfig2"
                       (animationCreated)="handleAnimation($event)"></ng-lottie>
            <ul class="connection-progress">
                <li [ngClass]="{'finished': (meterStatus > 0), 'progress': (meterStatus <= 0)}">
                    <div class="progress-indicator" [ngClass]="{done: meterStatus > 0}"></div>
                    <div class="progress-label">{{getDeviceTitle()}} vorbereiten</div>
                </li>
                <li class="progress-label-sub"></li>
                <li [ngClass]="{'finished': (meterStatus > 1), 'progress': (meterStatus == 1)}">
                    <div class="progress-indicator" [ngClass]="{done: meterStatus > 1}"></div>
                    <div class="progress-label">Smart Meter suchen</div>
                </li>
                <li class="progress-label-sub"></li>
                <li [ngClass]="{'finished': (meterStatus > 2), 'progress': (meterStatus == 2)}">
                    <div class="progress-indicator" [ngClass]="{done: meterStatus > 2}"></div>
                    <div class="progress-label">Mit Smart Meter verbinden</div>
                </li>
                <li class="progress-label-sub"></li>
            </ul>
            <ng-container *ngIf="meterStatus>0">
                <div>
                    <button type="submit" class="eon-button-new turquoise-inverted"
                            (click)="isEDGUser ? setStep(onboardingSteps.tariffEntry) : setStep(onboardingSteps.accountSetup)">
                        Schon ab ins Dashboard
                    </button>
                </div>
            </ng-container>
        </div>
    </section>

    <!---- PLUG CONNECTION ---------------------------------------->
    <section *ngIf="currentStep === onboardingSteps.devices.plug.connectingOR"
             class="big connection">
        <h1>Wir verbinden jetzt Ihren {{getDeviceTitle()}} mit Ihrem Stromzähler. Dies kann einige Minuten in
            Anspruch nehmen.</h1>
        <div>
            <ng-lottie class="loading-smartmeter" height="108px" [options]="lottieConfig2"
                       (animationCreated)="handleAnimation($event)"></ng-lottie>
            <ul class="connection-progress">
                <li [ngClass]="{'finished': (meterStatus > 0), 'progress': (meterStatus <= 0)}">
                    <div class="progress-indicator" [ngClass]="{done: meterStatus > 0}"></div>
                    <div class="progress-label">{{getDeviceTitle()}} vorbereiten</div>
                </li>
                <li class="progress-label-sub"></li>
                <li [ngClass]="{'finished': (meterStatus > 1), 'progress': (meterStatus == 1)}">
                    <div class="progress-indicator" [ngClass]="{done: meterStatus > 1}"></div>
                    <div class="progress-label">Zähler suchen</div>
                </li>
                <li class="progress-label-sub">Knopf am Ausleser erneut drücken</li>
                <li [ngClass]="{'finished': (meterStatus > 2), 'progress': (meterStatus == 2)}">
                    <div class="progress-indicator" [ngClass]="{done: meterStatus > 2}"></div>
                    <div class="progress-label">Mit Zähler verbinden</div>
                </li>
                <li class="progress-label-sub"></li>
                <li [ngClass]="{'finished': (meterStatus > 3), 'progress': (meterStatus == 3)}">
                    <div class="progress-indicator" [ngClass]="{done: meterStatus > 3}"></div>
                    <div class="progress-label">Ihr Zähler wird freigeschaltet</div>
                </li>
                <li class="progress-label-sub">
                    {{meterStatus === 3 ? 'je nach Zählertyp kann dies bis zum nächsten Tag dauern.' : ''}}
                </li>
            </ul>
            <ng-container *ngIf="meterStatus>=0">
                <div>
                    <button type="submit" class="eon-button-new turquoise-inverted"
                            (click)="afterConnectionFinished()">
                        Schon ab ins Dashboard
                    </button>
                    <!--                    <eon-ui-button name="submit" text="Schon ab ins Dashboard" input-type="submit"-->
                    <!--                                   scheme="turquoiseInverted"-->
                    <!--                                   (click)="afterConnectionFinished()">-->
                    <!--                    </eon-ui-button>-->
                </div>
            </ng-container>
        </div>
    </section>

    <!---- ACCOUNT SETUP ---------------------------------------->
    <section *ngIf="currentStep === onboardingSteps.accountSetup">
        <div class="top-logo center-contents">
            <div class="eon-logo-new"></div>
        </div>
        <h1>Herzlich Willkommen!</h1>
        <h2>
            Wir haben jetzt ein bisschen Zeit und können uns um Ihr Benutzerprofil kümmern! <br><br>
            Damit wir Ihnen genauere Informationen zu Ihrem Stromverbrauch liefern können, möchten wir Sie gerne besser
            kennenlernen. Bitte beantworten Sie uns dazu ein paar Fragen rund um Ihren Haushalt.
        </h2>
        <div class="center-contents btn-container">
            <button type="submit" class="eon-button-new"
                    routerLink="/mein-haushalt">
                Los geht's
            </button>
            <!--            <eon-ui-button name="submit" text="Los geht's" input-type="submit"-->
            <!--                           routerLink="/mein-haushalt"></eon-ui-button>-->
        </div>
        <!--        <div class="center-contents">-->
        <!--            <eon-ui-link href="javascript:;" text="Registrieren" target="_self"-->
        <!--                         (click)="setIsOnboarding()"-->
        <!--                         angulartics2On="click" angularticsCategory="Onboarding"-->
        <!--                         angularticsAction="onboarding_profile_cancel">-->
        <!--            </eon-ui-link>-->
        <!--        </div>-->
    </section>

    <!---- BOX CONNECTION ERROR ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.box.connectionError">
        <div class="top-logo center-contents">
            <div class="eon-logo-new"></div>
        </div>
        <h1>Leider konnte E.ON Smart Control {{device == 'plug' ? 'den' : 'die'}} E.ON Smart
            Control {{device == 'plug' ? 'Stecker' : 'Box'}} {{isSmartBox2() && '2.0'}} nicht finden.</h1>
        <h2>Sie können die MAC-ID erneut eingeben oder erneut mit dem Router verbinden.</h2>
        <div class="last-growing">
            <div class="center-contents btn-container">
                <button class="eon-button-new"
                        (click)="setStep(onboardingSteps.devices.box.macEntry)">
                    MAC-ID erneut eingeben
                </button>
            </div>
            <div class="center-contents">
                <button class="eon-button-new"
                        (click)="device == 'plug' ?
                         setStep(onboardingSteps.devices.plug.macEntry) :
                          setStep(onboardingSteps.devices.box.macEntry)">
                    Erneut verbinden
                </button>
                <!--                <eon-ui-button text="Erneut verbinden" -->
                <!--                               (click)="device == 'plug' ?-->
                <!--                         setStep(onboardingSteps.devices.plug.macEntry) :-->
                <!--                          setStep(onboardingSteps.devices.box.macEntry)">-->
                <!--                </eon-ui-button>-->
            </div>
        </div>
    </section>

    <!---- BOX CONNECTION ERROR ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.box.notFoundError">
        <div class="top-logo center-contents">
            <div class="eon-logo-new"></div>
        </div>
        <h1>Leider konnte E.ON Smart Control {{device == 'plug' ? 'den' : 'die'}} E.ON Smart
            Control {{device == 'plug' ? 'Stecker' : 'Box'}} {{isSmartBox2() && '2.0'}} nicht finden.</h1>
        <h2>Sie können die MAC-ID erneut eingeben. Andernfalls kontaktieren Sie bitte den Support.</h2>
        <div class="last-growing">
            <div class="center-contents btn-container">
                <button class="eon-button-new"
                        (click)="device == 'plug' ?
                          setStep(onboardingSteps.devices.plug.macEntry) :
                          setStep(onboardingSteps.devices.box.macEntry)">
                    MAC-ID erneut eingeben
                </button>
            </div>
            <div class="center-contents">
                <a href="javascript:;" class="eon-link-new" target="_self"
                   (click)="openSupportDialog()"
                   angulartics2On="click" angularticsCategory="Onboarding"
                   angularticsAction="onboarding_profile_cancel">
                    Support anfordern
                </a>
            </div>
        </div>
    </section>

    <!---- SOMETHING FAILED ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.detailFailed">
        <div class="top-logo center-contents">
            <div class="eon-logo-new"></div>
        </div>
        <h1>{{statusError.title}}</h1>
        <h2>{{statusError.text}} </h2>
        <div class="last-growing">
            <button class="eon-button-new"
                    (click)="retryMeterStatus()">
                Weiter Versuchen
            </button>
        </div>
    </section>

    <!-- OPTICAL READER PIN ENTRY -->
    <section class="white" *ngIf="currentStep === onboardingSteps.opticalReader.pinEntry">
        <h1>Bitte geben Sie Ihre Zähler-PIN ein</h1>
        <h2>Die PIN wird benötigt, damit alle relevanten Daten von Ihrem Zähler übertragen werden können. <br>
            Diese erhalten Sie in der Regel telefonisch von Ihrem Messstellenbetreiber. <br>
            Die Information, wer Ihr Messstellenbetreiber ist, finden Sie auf Ihrer Jahresabrechnung oder ggf. auf dem
            Stromzähler.
        </h2>
        <form class="login-form m-t-x" (submit)="setPIN(pin.value)">
            <div class="grow-space">
                <div class="btn-container">
                    <fieldset class="eon-input">
                        <legend>Pin</legend>
                        <input #pin class="eon-input" type="text"
                               (keyup)="onPinChange(pin.value)"
                               [attr.maxlength]="4">
                    </fieldset>
                </div>
            </div>
            <div class="center-contents btn-container">
                <button type="submit" class="eon-button-new"
                        [disabled]="pinEntrySubmissionDisabled">
                    Akzeptieren
                </button>
            </div>
            <div class="center-contents">
                <a href="javascript:;" class="eon-link-new"
                   (click)="setPIN(null)">
                    Überspringen
                </a>
            </div>
        </form>

    </section>

</main>
