<div class="overlay-small">
    <div class="overlay-container">
        <h1>2-Faktor-Authentifizierung</h1>
        <p class="eon-regular">
            Bitte wechseln Sie jetzt in die Authenticator-App Ihrer Wahl und legen Sie einen neuen Account an. Zum
            Anlegen verwenden Sie den unten angezeigten <strong>Code</strong>:
        </p>
        <p class="secret-code" (click)="copyCode()">
            {{setupCode}}
            <i></i>
        </p>
        <div>
            <button class="eon-button-new"
                    (click)="close(true)">
                Weiter
            </button>
        </div>
    </div>
    <div class="close-overlay-control center-contents" (click)="close(false)">
        <i class="close-s dark-grey"></i>
    </div>
</div>
