import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AnimationItem} from 'lottie-web';
import {ToastrService} from 'ngx-toastr';
import {MfaService} from '../../services/mfa.service';
import {PopoverRef} from '../popover/popover-ref';

@Component({
    selector: 'app-mfa-code-entry-popover',
    templateUrl: './mfa-code-entry-popover.component.html',
    styleUrls: ['./mfa-code-entry-popover.component.scss']
})
export class MfaCodeEntryPopoverComponent implements OnInit {

    form = new UntypedFormGroup({
        code: new UntypedFormControl('', [Validators.required])
    });
    loadingAnimation = {
        path: 'assets/anim/loading-spinner.json',
        autoplay: true,
        name: 'Loading Spinner',
        loop: true,
        renderer: 'canvas'
    };
    anim: AnimationItem;

    isLoading = false;
    submitDisabled = true;

    private lastKeyCode = null;
    private shiftDown = false;
    private controlDown = false;
    private commandDown = false;
    private optionDown = false;

    constructor(public popoverRef: PopoverRef,
                private mfaService: MfaService,
                private toastr: ToastrService) {
        if (this.popoverRef) {
            this.initializePopoverData();
        }
    }

    ngOnInit() {
        this.form.valueChanges.subscribe((changes) => {
            this.submitDisabled = changes.code.length < 1;
        });

        this.mfaService.onTokenVerification.subscribe(
            (result) => {
                this.isLoading = false;
                this.form.get('code').enable();
                if (result) {
                    this.close(true);
                    return;
                } else {
                    this.toastr.error('Hoppla, da ist etwas schief gelaufen.', 'Fehler');
                }
            }
        );
    }

    submitCode(): void {
        const code = this.form.value.code;
        this.isLoading = true;
        this.form.get('code').disable();
        this.mfaService.verifyMFATokenStandalone(code);
    }

    close(value = false): void {
        this.popoverRef.close(value);
    }

    onKeyUp(event): void {
        switch (event.keyCode) {
            case 16:
                this.shiftDown = false;
                break;
            case 91:
            case 93:
                this.commandDown = false;
                break;
            case 17:
                this.controlDown = false;
                break;
            case 18:
                this.optionDown = false;
                break;
        }
    }

    onKeyDown(event): boolean {
        switch (event.keyCode) {
            case 16:
                this.shiftDown = true;
                break;
            case 91:
            case 93:
                this.commandDown = true;
                break;
            case 17:
                this.controlDown = true;
                break;
            case 18:
                this.optionDown = true;
                break;
        }

        const allowedCodes = [16, 8, 46, 37, 39, 91, 93, 13];
        const currentCode = event.keyCode;
        let inputAcceptable = false;
        if (currentCode >= 48 && currentCode <= 57) {
            inputAcceptable = true;
        }
        if (currentCode >= 96 && currentCode <= 105) {
            inputAcceptable = true;
        }
        if (this.commandDown || this.controlDown) {
            if (currentCode === 67) { // copy
                inputAcceptable = true;
            } else if (currentCode === 86) {// paste
                inputAcceptable = true;
            } else if (currentCode === 65) { // select all
                inputAcceptable = true;
            }
        }
        // allow further keys
        if (allowedCodes.find(e => e === currentCode)) {
            inputAcceptable = true;
        }
        this.lastKeyCode = currentCode;
        return inputAcceptable;
    }

    private initializePopoverData() {
        this.popoverRef.overlay.backdropClick().subscribe(() => {
            if (this.popoverRef.data.nullableBackdrop) {
                this.close(null);
            } else {
                this.close(false);
            }
        });
    }

}
