<div class="overlay">
    <div class="detail-overlay">
        <div class="detail-view">

            <section class="detail-head">
                <header class="detail-header">
                    <h1 class="detail-heading">Zählerstand</h1>

                    <button angulartics2On="click" angularticsCategory="Detailansichten"
                            angularticsAction="detail_info_text" angularticsLabel="Zählerstand"
                            (click)="infoVisible = !infoVisible">
                        <i class="info-sm dark-grey"></i>
                    </button>

                    <button (click)="close()">
                        <i class="close-sm dark-grey"></i>
                    </button>
                </header>

                <section>
                    <ng-container *ngIf="meterData$ | async as meterData">
                        <div class="current-meter-values">
                            <app-meter-value-display
                                [forHeader]="true"
                                [value]="meterData.meterValues.offtake | paddedMeterValue"
                                [title]="LABELS.VALUE_CONSUMPTION"
                                icon='consumption'
                                [unit]="LABELS.UNIT">
                            </app-meter-value-display>
                            <app-meter-value-display
                                *ngIf="meterData.meterValues.feedin"
                                [forHeader]="true"
                                [value]="meterData.meterValues.feedin | paddedMeterValue"
                                [title]="LABELS.VALUE_FEEDIN"
                                icon='feedin'
                                [unit]="LABELS.UNIT">
                            </app-meter-value-display>
                        </div>

                        <div *ngIf="meterData.meterStats.meterSerialNumber"
                             class="center-contents eon-regular m-b-s">
                            {{LABELS.METER_NUMBER}}
                            &nbsp;
                            <strong>{{ meterData.meterStats.meterSerialNumber }}</strong>
                        </div>

                        <div class="connected">
                            <div class="connection-container">
                                <div class="p-r-s">
                                    <ng-container
                                        *ngIf="isMeterConnected(meterData.meterStats.connectionStatus); else disonnected">
                                        <i class="wifi-sm turquoise"></i>
                                    </ng-container>
                                    <ng-template #disonnected>
                                        <i class="alert-sm bordeaux"></i>
                                    </ng-template>
                                </div>
                                <h4 class="connection-text"
                                    [class.connected]="isMeterConnected(meterData.meterStats.connectionStatus)"
                                    [class.disconnected]="!isMeterConnected(meterData.meterStats.connectionStatus)">
                                    {{determineMeterStatusMessage(meterData.meterStats.connectionStatus)}}
                                </h4>
                            </div>
                        </div>
                    </ng-container>
                </section>
            </section>

            <section class="detail-info" *ngIf="infoVisible">
                <div class="info-icon">
                    <i class="info-sm dark-grey"></i>
                </div>
                <div class="info-text">
                    <h2 class="eon-black"> {{TEXTS.TILE_INFO.TITLE}} </h2>
                    <p> {{TEXTS.TILE_INFO.PARAGRAPH}} </p>
                </div>
            </section>

            <section class="detail-content">
                <ng-container *ngIf="userService.isMMEWMSBUser()">
                    <ng-container *ngIf="showMeterValueReportInfo; else mmeMessage">
                        <div class="meter-reading-info-field">
                            <div class="info-icon"></div>
                            <div class="info-text">
                                <strong>{{TEXTS.METER_READING.TITLE}}</strong>
                                <p> {{TEXTS.METER_READING.PARAGRAPH}} </p>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #mmeMessage>
                        <p>
                            {{TEXTS.METER_READING.MESSAGE.REGULAR}}
                            <strong>{{TEXTS.METER_READING.MESSAGE.STRONG}}</strong>.
                        </p>
                    </ng-template>
                    <p>
                        <button class="eon-button-new"
                                (click)="onMeterValueEntry()">
                            {{LABELS.BTN_LABEL_REPORT_METER_READING}}
                        </button>
                    </p>
                </ng-container>

                <div>
                    <strong>{{TEXTS.METER_VALUE_DATE.TITLE}}</strong>
                    <p>{{TEXTS.METER_VALUE_DATE.PARAGRAPH}} </p>
                </div>

                <div class="meter-value-date-select">
                    <div>
                        <app-date-picker-button
                            [displaySelectedDate]="true"
                            [currentDate]="today"
                            (dateChanged)="onDatePickerDateChange($event)">
                        </app-date-picker-button>
                    </div>
                </div>

                <div class="meter-values-container">
                    <ng-container *ngIf="dateSpecificMeterValues$ | async as dateValues">
                        <app-meter-value-display
                            [value]="dateValues.offtake | paddedMeterValue"
                            [title]="LABELS.VALUE_CONSUMPTION"
                            icon='consumption'
                            [unit]="LABELS.UNIT">
                        </app-meter-value-display>

                        <app-meter-value-display
                            *ngIf="hasFeedinData"
                            [value]="dateValues.feedin | paddedMeterValue"
                            [title]="LABELS.VALUE_FEEDIN"
                            icon="feedin"
                            [unit]="LABELS.UNIT">
                        </app-meter-value-display>
                    </ng-container>
                </div>
            </section>

        </div>

    </div>
</div>
