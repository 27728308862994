<div class="overlay overlay-nilm">
    <div class="overlay-container-cdk">
        <h1 class="font-bold">iONA hat ein neues Gerät erkannt</h1>
        <h2 class="font-regular">Wie viele {{appliancePlural}} gibt es aktuell in Ihrem Haushalt?</h2>

        <div #applianceIcon class="appliance-icon"></div>

        <div class="interaction-area">
            <button class="icon-only turqouise"
                    (click)="lowerAmount()">
                <i class="minus-s white"></i>
            </button>
            <span class="value">{{this.amount}}</span>
            <button class="icon-only turqouise"
                    (click)="raiseAmount()">
                <i class="plus-s white"></i>
            </button>
        </div>

        <div class="center-contents">
            <button class="eon-button-new turquoise"
                    (click)="confirm()">
                Übernehmen
            </button>
        </div>
    </div>
</div>
