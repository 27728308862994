<div class="overlay-minimal">
    <div class="overlay-container">
        <h1 class="eon-black">{{title}}</h1>

        <p>{{text}}</p>

        <div>
            <fieldset class="eon-input">
                <legend>{{placeholder}}</legend>
                <input #valueInput class="eon-input" type="text"
                       [value]="value ? value : ''" (keyup)="onInputChanges($event)"
                       [attr.maxlength]="maxlength">
            </fieldset>
        </div>

        <div class="text-field-error">
            <ng-container *ngIf="!inputValid">
                <ng-container [ngSwitch]="type">
                    <i class="alert-s bordeaux"></i>

                    <ng-container *ngSwitchCase="'number'">
                        <ng-container *ngIf="valueInput.value !== ''; else emptyError">
                            Bitte geben sie nur Zahlen ein
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'text'">

                    </ng-container>

                    <ng-template #emptyError>
                        Sie müssen eine Eingabe tätigen.
                    </ng-template>
                </ng-container>
            </ng-container>
        </div>

        <div class="splitrow m-t-l">
            <ng-container *ngIf="hasSkip">
                <button class="eon-button-new"
                        [class.turquoise]="turquoise"
                        (click)="close(false)">
                    Später
                </button>
            </ng-container>

            <button class="eon-button-new"
                    [class.turquoise]="turquoise"
                    [disabled]="!inputValid"
                    (click)="close(valueInput.value)">
                {{positive}}
            </button>

            <ng-container *ngIf="negative !== ''">
                <div class="m-h-s"></div>
                <button class="eon-button-new"
                        [class.turquoise]="turquoise"
                        (click)="close(false)">
                    {{negative}}
                </button>
            </ng-container>

        </div>
    </div>
</div>
