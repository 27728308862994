<div class="overlay">
    <div class="detail-overlay">

        <div class="detail-view detail-view-override">

            <section class="detail-head">
                <header class="detail-header">
                    <h1 class="detail-heading">
                        {{LABELS.TITLE}}
                    </h1>
                    <button angulartics2On="click" angularticsCategory="Detailansichten"
                            angularticsAction="detail_info_text" angularticsLabel="Haushaltsvergleich"
                            (click)="infoVisible = !infoVisible">
                        <i class="info-sm dark-grey"></i>
                    </button>

                    <button (click)="close()">
                        <i class="close-sm dark-grey"></i>
                    </button>
                </header>
            </section>

            <section class="detail-info" *ngIf="infoVisible">
                <div class="icon">
                    <i class="icon-info"></i>
                </div>
                <div class="info-text">
                    <h2 class="font-bold">
                        {{TEXTS.TILE_INFO.TITLE}}
                    </h2>
                    <p *ngFor="let p of TEXTS.TILE_INFO.PARAGRAPHS">
                        {{p}}
                    </p>
                </div>
                <div class="close-info">
                    <button class="iona-icon-only-button close" (click)="infoVisible = false"></button>
                </div>
            </section>

            <div class="grow-space">
                <ng-container *ngIf="dataProviderService.optedOut$ | async; else detailContent">
                    <div class="detail-content optout">
                        <p> {{TEXTS.OPTED_OUT}} </p>
                        <button class="eon-button-new" (click)="routeToSettings()">
                            {{LABELS.DETAIL_BTN_ADJUST_SETTINGS}}
                        </button>
                    </div>
                </ng-container>
                <ng-template #detailContent>
                    <div class="tab-bar household-comparison">
                        <div [class.active]="currentViewMode === ViewMode.TOTAL"
                             (click)="setMode(ViewMode.TOTAL)">
                            {{LABELS.TAB_TOTAL}}
                        </div>
                        <div [class.active]="currentViewMode === ViewMode.APPLIANCES"
                             (click)="setMode(ViewMode.APPLIANCES)">
                            {{LABELS.TAB_APPLIANCES}}
                        </div>
                        <div class="grow-space"></div>
                        <div [class.active]="currentViewMode === ViewMode.RANKING"
                             (click)="setMode(ViewMode.RANKING)">
                            <i class="rank-s dark"></i>
                        </div>
                    </div>

                    <div class="main-stats" [class.hidden]="sidebarHidden">
                        <div class="timeframe font-black">
                            {{dataProviderService.getCurrentTimeframeFormatted()}}
                        </div>
                        <div class="options p-t-m">
                            <div class="eon-dropdown">
                                <label for="hc-timeframe">Zeitraum</label>
                                <select id="hc-timeframe"
                                        (change)="timeframeChanged($event.target.value)"
                                        [value]="dataProviderService.getCurrentStoredTimeframe()">
                                    <option [value]="Timeframes.LAST_MONTH"
                                            [selected]="currentTimeframe === Timeframes.LAST_MONTH">
                                        {{ LABELS.TIMEFRAME_SELECT_LAST_MONTH }}
                                    </option>
                                    <option [value]="Timeframes.CURRENT_YEAR"
                                            [selected]="currentTimeframe === Timeframes.CURRENT_YEAR">
                                        {{ LABELS.TIMEFRAME_SELECT_CURRENT_YEAR }}
                                    </option>
                                    <option [value]="Timeframes.LAST_YEAR"
                                            [selected]="currentTimeframe === Timeframes.LAST_YEAR">
                                        {{ LABELS.TIMEFRAME_SELECT_LAST_YEAR }}
                                    </option>
                                </select>
                                <span></span>
                            </div>

                            <div class="spacer"></div>

                            <div class="current-rank"
                                 (click)="setMode(ViewMode.RANKING)">

                                <div class="current-rank-icon-wrapper">
                                    <i class="rank-sm white"></i>
                                </div>

                                <div class="current-rank-value font-bold">
                                    <ng-container *ngIf="householdComparisonData$ | async as householdComparisonData">
                                        <ng-container *ngIf="householdComparisonData.rank === 0">
                                            –
                                        </ng-container>
                                        <ng-container *ngIf="householdComparisonData.rank > 0">
                                            {{ householdComparisonData.rank | largeInt }}
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="detail-content"
                         [class.no-header]="sidebarHidden">
                        <div class="detail-visualization"
                             [class.full-width]="sidebarHidden">
                            <ng-template [cdkPortalOutlet]="selectedPortal"
                                         (attached)="onComponentAttachedToPortal($event)">
                            </ng-template>
                        </div>
                        <div class="detail-sidebar" [style.display]="determineSidebarDisplay()">
                            <app-household-comparison-detail-sidebar>
                            </app-household-comparison-detail-sidebar>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>

    </div>
</div>
