<div class="overlay-full-height sidebar-width turquoise">
    <div class="overlay-container">

        <div class="row-align-right m-v-l pointer">
            <i class="close-sm white"
               (click)="close()"></i>
        </div>

        <h1 class="eon-bold">Umsortieren</h1>

        <div class="tile-sort-list" cdkDropList (cdkDropListDropped)="dropElement($event)">
            <ng-container *ngFor="let tile of sortableTiles; let pos = index">
                <ng-container *ngIf="tile.selected; else invisible">
                    <div class="sort-tiles-item row eon-regular pointer"
                         cdkDrag>
                        <div class="p-r-m center-contents-v">
                            <i class="more-sm white"></i>
                        </div>
                        <div class="idx eon-bold p-h-s">{{ pos + 1 }}</div>
                        <div class="title grow-space">{{ tile.title }}</div>
                        <div class="center-contents-v pointer">
                            <i class="trash-sm white"
                               (click)="onRemoveTileClick(tile)"></i>
                        </div>
                    </div>
                </ng-container>
                <ng-template #invisible>
                    <div></div>
                </ng-template>
            </ng-container>
        </div>
    </div>
</div>
