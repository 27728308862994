<div id="container">
    <aside class="sidebar lft">
        <a href="#" id="hamburger" title="Menü" class="mobi">
            <span class="burger">
                <i class="top"></i>
                <i class="middle"></i>
                <i class="bottom"></i>
            </span>
            <span class="text"> Menü</span>
        </a>
        <app-menu activeElement="settings"></app-menu>
    </aside>

    <div class="page">
        <div id="inner">
            <div class="page-card">
                <h1>Einstellungen</h1>

                <section>
                    <h2 class="extra-space">Verbindungen</h2>
                    <div>
                        <h3>{{deviceIsPlug ? 'Ihr Smart Control Stecker' : isBox2 ? 'Ihre E.ON Smart Control Box 2.0' : 'Ihre Smart Control Box'}}</h3>
                        <div class="connection-container p-t-s">
                            <i class="wifi-lg"></i>
                            <div class="p-r-s">
                                <ng-container *ngIf="wifiConnected < 0; else disonnected">
                                    <i class="wifi-sm turquoise"></i>
                                </ng-container>
                                <ng-template #disonnected>
                                    <i class="alert.sm bordeaux"></i>
                                </ng-template>
                            </div>
                            <ng-container *ngIf="lanConnected else wifiConnection">
                                <div> verbunden</div>
                            </ng-container>
                            <ng-template #wifiConnection>
                                <div [style.color]="wifiConnected < 0 ? '#1ea2b1' : '#b00402'">
                                    {{ wifiConnected < 0 ? '(W-)LAN verbunden' : '(W-)LAN nicht verbunden' }} </div>
                            </ng-template>
                        </div>

                        <h3>Ihr Stromzähler</h3>
                        <div class="connection-container p-t-s p-r-s">
                            <div class="p-r-s">
                                <ng-container *ngIf="meterStatus === 'connected'; else disonnected">
                                    <i class="wifi-sm turquoise"></i>
                                </ng-container>
                                <ng-template #disonnected>
                                    <i class="alert-sm bordeaux"></i>
                                </ng-template>
                            </div>
                            <div [style.color]="meterStatus === 'connected' ? '#1ea2b1' : '#b00402'">
                                {{determineMeterStatusMessage()}} </div>
                        </div>

                        <ng-container *ngIf="pincode">
                            <h3>Aktuelle Zähler-PIN</h3>
                            <div class="p-r-s">
                                <div class="p-r-s">
                                    {{pincode}}
                                </div>
                            </div>
                        </ng-container>

                        <div class="m-t-l" *ngIf="isEDGUser && this.pinEntryUnknownOrOptical">
                            <button class="eon-button-new turquoise"
                                    (click)="onPinEntryOpen()">
                                Zähler-PIN eingeben
                            </button>
                        </div>

                        <ng-container *ngIf="isEDGUser">
                            <h3>Batteriestatus</h3>
                            <div class="p-t-s">
                                <app-battery-load [currentBatteryState]="currentBatteryState"
                                                  [isAvailable]="currentBatteryState > -1">
                                </app-battery-load>
                            </div>

                            <div class="m-t-l">
                                <button class="eon-button-new turquoise"
                                        [disabled]="currentBatteryState === 0"
                                        (click)="onEnergySaverOpen()">
                                    Energiesparen
                                </button>
                            </div>
                        </ng-container>
                    </div>
                </section>

                <section>
                    <h2 class="extra-space">Online-Modus</h2>
                    <label class="eon-checkbox" for="checkbox">
                        <input #optInCb type="checkbox" name="checkbox" id="checkbox"
                               [checked]="config.optIn"
                               (change)="onOptInCheckboxChange(optInCb.checked)">
                        Online-Modus erlauben
                    </label>
                    <p>
                        Wenn Sie alle Funktionen von E.ON Smart Control nutzen möchten, können Sie hier für die Zukunft
                        in den Online-Modus wechseln. Damit geben Sie uns Ihre Einwilligung um auf die
                        Verbrauchsdaten {{deviceIsPlug ? 'Ihres E.ON Smart Control Steckers' : isBox2 ? 'Ihrer E.ON Smart Control Box 2.0' : ' Ihrer E.ON Smart Control Box'}}
                        zugreifen zu dürfen. Dies beinhaltet unter anderem ihren Stromverbrauch in Sekunden-Auflösung.
                        Dieser Zugriff ist notwendig, damit wir Ihnen den vollen Funktionsumfang - der in den
                        Nutzungsbedingungen der App beschrieben wird – anbieten können. Die weitere Verarbeitung dieser
                        Daten wird in unseren Datenschutzhinweisen und den folgenden Einwilligungserklärungen
                        beschrieben. Sie können Ihre Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen indem
                        Sie die Option in den Einstellungen wieder deaktivieren.
                    </p>
                </section>

                <section>
                    <h2 class="extra-space">Anonyme Datenverarbeitung</h2>
                    <label class="eon-checkbox" for="benchmark-checkbox">
                        <input #hhcOptOutCb type="checkbox" name="checkbox" id="benchmark-checkbox"
                               [checked]="config.householdComparisonOptOut"
                               (change)="setHouseholdComparisonOptOut(hhcOptOutCb.checked)">
                        Anonyme Datenverarbeitung erlauben
                    </label>
                    <p>
                        Durch die Deaktivierung können Sie der Anonymisierung Ihres Verbrauchs- und
                        Haushaltsprofils zum Zweck des Haushaltsvergleichs widersprechen. Die Funktion
                        Haushaltsvergleich steht nach dem Widerspruch nicht mehr zur Verfügung.
                    </p>
                </section>

                <section>
                    <h2 class="extra-space">Opt-In Datenverarbeitung</h2>
                    <label class="eon-checkbox" for="data-optin-checkbox">
                        <input #dataOptInCheckbox type="checkbox" name="checkbox" id="data-optin-checkbox"
                               [checked]="config.dataOptIn"
                               (change)="setDataOptinCheckBox(dataOptInCheckbox.checked)">
                        Opt-In Datenverarbeitung erlauben
                    </label>
                    <p>
                        Ich bin damit einverstanden, dass die Messwerte meiner Messeinrichtung und die freiwilligen
                        Angaben in meinem Haushaltsprofil durch die E.ON Energie Deutschland GmbH dazu verwendet werden,
                        dass Analysen zur Produkt-, Projekt-, und Prozessverbesserung (z.B. Lastprofil, Beschaffung,
                        Stromverbrauchsmuster) sowie zu Presse- und Kommunikationszwecken erstellt werden. Diese
                        Einwilligung können Sie jederzeit mit Wirkung für die Zukunft widerrufen, indem Sie den
                        Schieberegler zurückschieben
                    </p>
                </section>

                <section>
                    <h2 class="extra-space">Sprache</h2>
                    <p>
                        Hier haben Sie die Möglichkeit die Sprache umzustellen und vielleicht noch mehr Text.
                        Hier haben Sie die Möglichkeit die Sprache umzustellen und vielleicht noch mehr Text.
                    </p>
                    <button class="eon-button-new" (click)="openLanguageModal()">
                        Sprache ändern
                    </button>
                </section>

                <button class="eon-button-new"
                        (click)="saveSettings()">
                    Einstellungen speichern
                </button>

            </div>
        </div>
    </div>
</div>
