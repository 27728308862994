<div class="connection-container">
    <div class="battery-state-container">
        <div class="battery-state-icon"
             [ngStyle]="{
                 'mask-image': getBatteryStateIconUrl(currentBatteryState),
                 '-webkit-mask-image': getBatteryStateIconUrl(currentBatteryState),
                 'background-color': currentBatteryState === 1 ? '#b00402' : '#1ea2b1'}"
        ></div>
    </div>
    <div [style.color]="determineTextColor()"
         [ngClass]="{'eon-bold': !inSettings}">
        <ng-container *ngIf="isAvailable; else notAvailable">
            {{currentBatteryState > 0 ? currentBatteryState * 2 + '0% geladen' : 'Netzteil verbunden'}}
        </ng-container>
        <ng-template #notAvailable>
            nicht verfügbar
        </ng-template>
    </div>
    <div class="warn-triangle" *ngIf="!inSettings && currentBatteryState === 1"></div>
</div>
