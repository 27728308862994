import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Globals} from '../../../services/globals.service';
import {BaseComponent} from '../../../classes/base-component';
import {TILE_TYPE, TileService} from '../../../services/tile.service';
import {TrackAnalyticsService} from '../../../services/track-analytics.service';
import {Observable} from 'rxjs';
import {map, mergeMap, share, tap} from 'rxjs/operators';
import {ProfileUpdateService} from '../../../services/profile-update.service';
import {AppliancesTileTexts} from '../../../shared/texts/tiles/appliances-tile.texts';
import {AppliancesTileData} from '../../../shared/interfaces/appliances-tile-data.interfaces';
import {
    AppliancesTileDataProviderService
} from '../../../services/data-provider/appliances-tile-data-provider.service';
import {ViewState} from '../../../shared/enums/view-state.enum';


@Component({
    selector: 'app-appliances-tile',
    templateUrl: './appliances-tile.component.html',
    styleUrls: ['./appliances-tile.component.scss'],
    providers: [Globals]
})

export class AppliancesTileComponent extends BaseComponent implements OnInit, OnDestroy {

    readonly TEXTS = AppliancesTileTexts;

    protected readonly ViewState = ViewState;
    private readonly type: TILE_TYPE = TILE_TYPE.APPLIANCES;

    showAttentionIndicator = false;

    combinedApplianceTileData$: Observable<{
        applianceTileData: AppliancesTileData
        profileUpdate: boolean
    }> = this.appliancesDataProvider.appliancesTileData$.pipe(
        share(),
        mergeMap(applianceTileData => (
            this.profileUpdate.onShowProfileIndicator.pipe(
                map(profileUpdate => ({applianceTileData, profileUpdate}))
            )
        )),
        tap((combined) => {
            this.showAttentionIndicator = this.determineAttentionIndicatorVisibility(combined);
            this.cdRef.detectChanges();
        })
    );

    constructor(
        private globals: Globals,
        private analytics: TrackAnalyticsService,
        private tiles: TileService,
        private profileUpdate: ProfileUpdateService,
        private appliancesDataProvider: AppliancesTileDataProviderService,
        private cdRef: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit(): void {
        this.profileUpdate.checkProfileUpdateIndicatorDisplayDue();
        this.appliancesDataProvider.setupAppliancesTileDataRetrieval();
    }


    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    /**
     * Handle on tile click
     */
    onTileClicked(): void {
        this.detailEntered();
        this.tiles.openDetailView(this.type);
    }


    /**
     * On Detail opened
     */
    detailEntered() {
        if (!(this.globals.getFirstDetailsViewed())) {
            this.trackFirstDetailView();
        }
        this.globals.setFirstDetailsViews();
        this.trackDetailsEntered();
    }

    /**
     * Determine whether the attention indicator should be displayed
     * @param combinedTileData
     * @private
     */
    private determineAttentionIndicatorVisibility(
        combinedTileData: {
            applianceTileData: AppliancesTileData,
            profileUpdate: boolean
        }
    ): boolean {
        if (combinedTileData.applianceTileData.nilmProfileComplete) {
            return combinedTileData.profileUpdate || combinedTileData.applianceTileData.nilmRetrainingInProgress;
        }
        return combinedTileData.profileUpdate;
        // return combinedTileData.profileUpdate
        //     || combinedTileData.applianceTileData.nilmProfileComplete
        //     || combinedTileData.applianceTileData.nilmRetrainingInProgress;
    }

    /*
   * TRACKING
   * =============================================================================================
   */
    private trackDetailsEntered(): void {
        this.analytics.trackEvent({
            action: 'dashboard_tile_tapped',
            properties: {
                category: 'Tiles',
                label: 'Tile: Appliances'
            }
        });
    }


    private trackFirstDetailView(): void {
        this.analytics.trackEvent({
            action: 'first_detail_view',
            properties: {
                category: 'Screens',
                label: 'Screen: Consumer-Details'
            }
        });
    }

    /**
     * legacy
     * @private
     */

    // private readonly type: TILE_TYPE = TILE_TYPE.APPLIANCES;
    // private nilmStatusSub: Subscription = null;
    // private showIndicatorSub: Subscription = null;
    // private colors = ['#1ea2b1', '#56b9c5', '#616161', '#747475'];
    //
    // @ViewChild('chart', {static: true}) private applianceChart: ApplianceChartComponent;
    //
    // currentMonth: string = null;
    //
    // showDiagrams = true;
    // nilmDataAvailable = false;
    // showAttentionIndicatorProfile = false;
    // showAttentionIndicatorNilm = false;
    //
    // constructor(private globals: Globals,
    //             private disaggregation: DisaggregationService,
    //             private nilm: NilmService,
    //             private application: ApplicationService,
    //             private analytics: TrackAnalyticsService,
    //             private tiles: TileService,
    //             private profileUpdate: ProfileUpdateService) {
    //     super();
    // }

    // ngOnInit(): void {
    //     this.currentMonth = getMonthName(0);
    //     this.profileUpdate.onShowProfileIndicator.subscribe(result => {
    //         this.showAttentionIndicatorProfile = result;
    //     });
    //     this.profileUpdate.checkProfileUpdateIndicatorDisplayDue();
    // }
    //
    //
    // ngOnDestroy(): void {
    //     super.ngOnDestroy();
    //     if (this.nilmStatusSub) {
    //         this.nilmStatusSub.unsubscribe();
    //         this.nilmStatusSub = null;
    //     }
    //     if (this.showIndicatorSub) {
    //         this.showIndicatorSub.unsubscribe();
    //         this.showIndicatorSub = null;
    //     }
    // }


    // /**
    //  * Handle on tile click
    //  */
    // onTileClicked(): void {
    //     this.detailEntered();
    //     this.tiles.openDetailView(this.type);
    // }
    //
    //
    // /**
    //  * On Chart initialized and loaded callback
    //  */
    // onChartLoaded(): void {
    //     this.initializeTile();
    // }


    //
    // /**
    //  * Initializer for the tile
    //  */
    // private initializeTile(): void {
    //     this.nilmStatusSub = this.nilm.onNewNilmStatusUpdate$.subscribe(
    //         (status) => {
    //             if (status === null) {
    //                 return;
    //             }
    //             this.nilmDataAvailable = status;
    //             this.requestElectricalAppliances();
    //         });
    // }
    //
    // /**
    //  * Requests disaggregation data for appliances to be displayed on the tile
    //  */
    // private requestElectricalAppliances(): void {
    //
    //     const s = this.disaggregation.getDisaggregationDataForAppliancesTile().pipe(
    //         tap(data => {
    //             // console.log('data', data);
    //         }),
    //         mergeMap((categories: ApplianceCategories) =>
    //             this.alignCategoryData(categories)),
    //         tap((categories: AlignedApplianceCategoryData[]) => {
    //             const mappedCategories = categories.map(el => el.name);
    //             if (!this.showAttentionIndicatorNilm) {
    //                 this.showAttentionIndicatorNilm =
    //                     !this.nilm.isProfileComplete(mappedCategories);
    //             }
    //         }),
    //         mergeMap((alignedCategories: AlignedApplianceCategoryData[]) =>
    //             this.generateDiagramSeries(alignedCategories))
    //     ).subscribe({
    //         next: (seriesData) => {
    //             // console.log('tile series data', seriesData);
    //             // need to cast to any since pie charts don't support parameter x
    //             const series = seriesData.series as any;
    //             const finalSeries = {
    //                 name: 'Series',
    //                 data: series,
    //                 type: 'pie',
    //                 custom: seriesData.nilm
    //             } as SeriesPieOptions;
    //             this.applianceChart.addSeries(finalSeries);
    //         },
    //         error: () => {
    //             this.applianceChart.showLoadingState();
    //             this.showDiagrams = false;
    //         },
    //         complete: () => {
    //             s.unsubscribe();
    //         }
    //     });
    // }
    //
    //
    // /**
    //  * Align the raw categories - ultimately this function remaps the response to an array-like
    //  * @param categories
    //  */
    // private alignCategoryData(categories: ApplianceCategories)
    //     : Observable<AlignedApplianceCategoryData[]> {
    //     return new Observable((observer) => {
    //         try {
    //             const tempData: AlignedApplianceCategoryData[] = [];
    //             for (const appliance of Object.keys(categories)) {
    //                 if (categories[appliance].usage > 0) {
    //                     tempData.push({
    //                         name: appliance,
    //                         usage: categories[appliance].usage
    //                     });
    //                 }
    //             }
    //             tempData.sort((a, b) => b.usage - a.usage);
    //             observer.next(tempData);
    //         } catch (error) {
    //             observer.error(error);
    //         }
    //     });
    // }
    //
    //
    // /**
    //  * Generates a diagram series by extracting the categories with the highest values
    //  * Other categories are summed up and displayed as 'Others'
    //  * @param alignedCategories
    //  */
    // private generateDiagramSeries(alignedCategories: AlignedApplianceCategoryData[])
    //     : Observable<{ series: ApplianceDiagramSeriesData[], nilm: boolean[] }> {
    //     return new Observable((observer) => {
    //         try {
    //             const series: ApplianceDiagramSeriesData[] = [];
    //             const customNilmValues: boolean[] = [];
    //
    //             // process categories with the highest values
    //             for (const appliance of alignedCategories.slice(0, 3)) {
    //                 let categoryComplete = true;
    //                 if (this.nilmDataAvailable) {
    //                     categoryComplete = this.nilm.nilmCategoryIsComplete(
    //
    //                         appliance.name.toLowerCase()
    //                     );
    //                     customNilmValues.push(categoryComplete);
    //                 }
    //                 series.push({
    //                     name: appliance.name,
    //                     y: appliance.usage,
    //                     color: null,
    //                     sliced: false
    //                 });
    //             }
    //
    //             // process others category
    //             let other = 0;
    //             const otherCustomNilmValues: boolean[] = [];
    //             for (const appliance of alignedCategories.slice(3)) {
    //                 other += appliance.usage;
    //                 let categoryComplete = true;
    //                 if (this.nilmDataAvailable) {
    //                     categoryComplete = this.nilm.nilmCategoryIsComplete(
    //                         appliance.name.toLowerCase()
    //                     );
    //                     otherCustomNilmValues.push(categoryComplete);
    //                 }
    //             }
    //             if (other > 0) {
    //                 series.push({
    //                     name: 'Other',
    //                     y: other,
    //                     color: null,
    //                     sliced: false
    //                 });
    //                 const nilmStateOthers = otherCustomNilmValues.every(el => el === true);
    //                 customNilmValues.push(nilmStateOthers);
    //             }
    //
    //             // assign colors
    //             let colorCounter = 0;
    //             for (const element of series) {
    //                 element.color = this.colors[colorCounter];
    //                 ++colorCounter;
    //             }
    //
    //             observer.next({series, nilm: customNilmValues});
    //         } catch (error) {
    //             observer.error(error);
    //         }
    //     });
    // }


    // private determineCalloutData(chartRef): AppliancesDiagramCallout {
    //     const point_name = chartRef.point.name.toLowerCase();
    //     const name = translateAppliance(chartRef.point.name);
    //     const value = Math.round(chartRef.point.percentage).toString();
    //     const callout: AppliancesDiagramCallout = {
    //         image: `url(/assets/img/graphics/appliances/${point_name}.svg)`,
    //         color: chartRef.point.color,
    //         label: `${name}: ${value}%`,
    //         showBoeppel: false
    //     };
    //
    //     // NILM
    //     if (point_name !== 'other') {
    //         if (point_name === 'laundry' || point_name === 'refrigeration' || point_name === 'entertainment' || point_name === 'cooking') {
    //             const profile_complete = this.determineProfileCompleteness(point_name);
    //             if (!profile_complete) {
    //                 callout.showBoeppel = true;
    //             }
    //         }
    //     }
    //
    //     return callout;
    // }


    // /**
    //  * Initialize Chart
    //  */
    // private initializeChart(): void {
    //     const self = this;
    //     this.chart = new Chart({
    //         chart: {
    //             type: 'pie',
    //             backgroundColor: 'rgba(255, 255, 255, 0)',
    //             margin: [0, 0, 25, 0],
    //             events: {
    //                 redraw() {
    //                     this.reflow();
    //                 },
    //                 render(event) {
    //                     if ('series' in event.target) {
    //                         if (event.target['series']['length'] > 0) {
    //                             const points = event.target['series'][0]['points'] as any[];
    //                             const sorted = points.sort((a, b) => {
    //                                 return b.percentage - a.percentage;
    //                             });
    //                             const name = translateAppliance(sorted[0].name);
    //                             const value = Math.floor(sorted[0].percentage).toString();
    //                             self.defaultCallout.color = sorted[0].color;
    //                             self.defaultCallout.image = `url(/assets/img/graphics/appliances/${sorted[0].name.toLowerCase()}.svg)`;
    //                             self.defaultCallout.label = `${name}: ${value}%`;
    //                             self.currentCallout = self.defaultCallout;
    //                         }
    //                     }
    //                 }
    //             }
    //         },
    //         title: {text: null},
    //         tooltip: {
    //             hideDelay: 0,
    //             shadow: false,
    //             positioner(boxWidth: number, boxHeight: number) {
    //                 return {
    //                     x: (this.chart.plotWidth / 2) - (boxWidth / 2),
    //                     y: (this.chart.plotHeight / 2) - (boxHeight / 2)
    //                 };
    //             },
    //             useHTML: true,
    //             formatter() {
    //                 self.currentCallout = self.determineCalloutData(this);
    //                 return '';
    //             },
    //             backgroundColor: 'rgba(255, 255, 255, 0)',
    //             borderWidth: 0
    //         },
    //         plotOptions: {
    //             pie: {
    //                 dataLabels: {
    //                     useHTML: true,
    //                     formatter() {
    //                         return `<div class="label"> ${Math.round(this.percentage)} %</div>`;
    //                     },
    //                     distance: 20,
    //                     padding: 0,
    //                     connectorWidth: 0,
    //                     connectorColor: 'white',
    //                     softConnector: false,
    //                     style: {
    //                         fontSize: '20px',
    //                         fontFamily: 'EONBrixSans, sans-serif',
    //                         color: '#39393a'
    //                     }
    //                 },
    //                 startAngle: -180,
    //                 states: {hover: {brightness: 0,}},
    //                 point: {
    //                     events: {
    //                         mouseOut() {
    //                             self.currentCallout = self.defaultCallout;
    //                         },
    //                     }
    //                 }
    //             }
    //         },
    //         series: [],
    //         credits: {enabled: false}
    //     });
    //
    // }

    // /**
    //  * Determines the profile completenes for a certain category.
    //  * @param point_name
    //  */
    // public determineProfileCompleteness(point_name: string): boolean {
    //     if (point_name === 'refrigeration') {
    //         if (this.nilmdata.nonTimeBasedAppliances.refrigeration !== null || this.nilmdata.nonTimeBasedAppliances.refrigeration !== undefined) {
    //             const el = this.nilmdata.nonTimeBasedAppliances.refrigeration;
    //             return this.determineCompletenesOfAppliance(el);
    //         }
    //     } else if (point_name === 'cooking') {
    //         const oven = this.nilmdata.timeBasedAppliances.oven;
    //         // const hob = this.nilmdata.timeBasedAppliances.hob;
    //         const profiles = [oven];
    //         if (profiles.every(element => element !== false && element !== undefined && element !== null)) {
    //             const results = [];
    //             for (const element of profiles) {
    //                 results.push(this.determineCompletenesOfAppliance(element));
    //             }
    //             return results.every(el => el !== false && el !== undefined && el !== null);
    //         }
    //     } else if (point_name === 'laundry') {
    //         const washingMachine = this.nilmdata.timeBasedAppliances.washingMachine;
    //         const dryer = this.nilmdata.timeBasedAppliances.dryer;
    //         const dishWasher = this.nilmdata.timeBasedAppliances.dishWasher;
    //         const profiles = [washingMachine, dryer, dishWasher];
    //         if (profiles.every(element => element !== false && element !== undefined && element !== null)) {
    //             const results = [];
    //             for (const element of profiles) {
    //                 results.push(this.determineCompletenesOfAppliance(element));
    //             }
    //             return results.every(el => el !== false && el !== undefined && el !== null);
    //         }
    //     } else if (point_name === 'entertainment') {
    //         let el;
    //         if (this.nilmdata.nonTimeBasedAppliances.entertainment !== null ||
    //             this.nilmdata.nonTimeBasedAppliances.entertainment !== undefined) {
    //             el = this.nilmdata.nonTimeBasedAppliances.entertainment;
    //         }
    //         if (el.profileComplete || el.profileComplete === false && el.profileAdded === true) {
    //             return true;
    //         }
    //     } else {
    //         return true;
    //     }
    //
    //     return false;
    // }

    // public determineCompletenesOfAppliance(el: any): boolean {
    //     return el.profileComplete || el.profileComplete === false && el.profileAdded === true;
    // }
    //
    // detailEntered() {
    //     if (!(this.globals.getFirstDetailsViewed())) {
    //         this.trackFirstDetailView();
    //     }
    //     this.globals.setFirstDetailsViews();
    //     this.trackDetailsEntered();
    // }
    //
    // private trackDetailsEntered(): void {
    //     this.analytics.trackEvent({
    //         action: 'dashboard_tile_tapped',
    //         properties: {
    //             category: 'Tiles',
    //             label: 'Tile: Appliances'
    //         }
    //     });
    // }
    //
    // private trackFirstDetailView(): void {
    //     this.analytics.trackEvent({
    //         action: 'first_detail_view',
    //         properties: {
    //             category: 'Screens',
    //             label: 'Screen: Consumer-Details'
    //         }
    //     });
    // }
}
