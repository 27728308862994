export enum FinanceTileLabels {
    TITLE = 'Abschlags-Check',
    LABEL_FRAGMENT_CIRCA = 'etwa',
    LABEL_FRAGMENT_ESTIMATED = 'voraussichtlich',
    LABEL_FRAGMENT_ADDITIONAL_PAYMENT = 'Nachzahlung',
    LABEL_FRAGMENT_CREDIT = 'Gutschrift',
    LABEL_FRAGMENT_EVEN = 'passt es',
    UNIT_FINANCE = '€',
    LABEL_EMPTY_STATE = 'Daten werden noch gesammelt',
}
