import {Component, OnInit} from '@angular/core';
import {Globals} from '../../../services/globals.service';
import {TILE_TYPE, TileService} from '../../../services/tile.service';
import {TrackAnalyticsService} from '../../../services/track-analytics.service';
import {AnimationOptions} from 'ngx-lottie';
import {FinanceDataService, FinancialTrend} from '../../../services/finance-data.service';
import {catchError, ignoreElements, map, share} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {FinanceTileLabels} from '../../../shared/texts/tiles/finance-tile.labels';

@Component({
    selector: 'app-finance-tile',
    templateUrl: './finance-tile.component.html',
    styleUrls: ['./finance-tile.component.scss'],
    providers: [Globals]
})

export class FinanceTileComponent implements OnInit {
    private readonly type: TILE_TYPE = TILE_TYPE.FINANCE;
    readonly LABELS = FinanceTileLabels;

    financeData$: Observable<FinancialTrend> = this.financeDataService.getFinancialTrend();
    financeDataError$: Observable<boolean> = this.financeData$.pipe(
        ignoreElements(),
        catchError((error) => of(true))
    );

    lottieConfig: AnimationOptions = {
        path: 'assets/anim/finance.json',
        renderer: 'svg',
        autoplay: true,
        loop: true,
        name: 'Smart Meter'
    };

    constructor(private _globals: Globals,
                private analytics: TrackAnalyticsService,
                private tiles: TileService,
                private financeDataService: FinanceDataService) {
    }


    ngOnInit() {
    }


    /**
     * Handles on tile clicked Event
     */
    onTileClicked(): void {
        this.detailEntered();
        this.tiles.openDetailView(this.type);
    }


    /**
     * Handles tracking events on opening detail
     */
    private detailEntered() {
        if (!(this._globals.getFirstDetailsViewed())) {
            this.trackFirstDetailView();
        }
        this._globals.setFirstDetailsViews();
        this.trackDetailsEntered();
    }

    /*
     * ANALYTICS
     * =============================================================================================
     */
    private trackDetailsEntered(): void {
        this.analytics.trackEvent({
            action: 'dashboard_tile_tapped',
            properties: {
                category: 'Tiles',
                label: 'Tile: Finance',
            }
        });
    }

    private trackFirstDetailView(): void {
        // Erstes aufrufen eines Detail Screens
        this.analytics.trackEvent({
            action: 'first_detail_view',
            properties: {
                category: 'Screens',
                label: 'Screen: Finance-Details'
            }
        });
    }
}
