<div class="overlay-minimal">
    <div class="overlay-container">
        <h1>E-Mail-Adresse ändern</h1>
        <div>
            Geben Sie hier Ihre neue E-Mail-Adresse ein.
        </div>

        <form [formGroup]="formGroup" (ngSubmit)="submitChange()">
            <fieldset class="eon-input m-b-s">
                <legend>Neue E-Mail-Adresse</legend>
                <input type="email" class="eon-input" formControlName="email">
            </fieldset>

            <fieldset class="eon-input m-b-s">
                <legend>Neue E-Mail-Adresse (wiederholen)</legend>
                <input type="email" class="eon-input" formControlName="email2">
            </fieldset>

            <button class="eon-button-new"
                    type="submit"
                    [disabled]="submitDisabled">
                Ändern
            </button>
        </form>

    </div>
    <div class="close-overlay-control center-contents" (click)="close(false)">
        <i class="close-s dark-grey"></i>
    </div>
</div>
