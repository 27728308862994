<div id="container">
    <aside class="sidebar lft">
        <a href="#" id="hamburger" title="Menü" class="mobi">
            <span class="burger">
                <i class="top"></i>
                <i class="middle"></i>
                <i class="bottom"></i>
            </span>
            <span class="text"> Menü</span>
        </a>

        <app-menu activeElement="account"></app-menu>
    </aside>

    <div id="inner">
        <div class="page">
            <div class="page-card">
                <h1>Mein E.ON</h1>

                <section>
                    <h2>Hier können Sie alles rund um Ihren Vertrag schnell und einfach online ändern</h2>
                    <ul>
                        <li>Vertragsdaten anpassen</li>
                        <li>Abschlag anpassen</li>
                        <li>Rechnung einsehen</li>
                    </ul>
                    <button class="eon-button-new"
                            (click)="openLink()">
                        Mein E.ON
                    </button>
                </section>
            </div>
        </div>
    </div>
</div>


