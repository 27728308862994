<div class="tile-container">
    <div class="tile" angulartics2On="click" angularticsCategory="Screens" angularticsAction="screen_view"
         angularticsLabel="screen: Live; previous_screen: Übersicht"
         title="Details anzeigen" (click)="onTileClicked()">

        <div class="tile-inner">
            <div class="tile-heading">
                <h3>Live</h3>
                <div *ngIf="isNotRealtime" class="energy-saver-warning">
                    <i class="energy-saver"></i>
                    Energiesparmodus
                </div>
            </div>

            <div class="tile-content col">
                <div class="live-tile-current-value"
                     [class.alert]="status.trend > 1  && !status.noZone"
                     [class.muted]="isNotRealtime"
                     [class.feedin]="currentConsumption < 0">
                    <i [class.feedin]="currentConsumption < 0"
                       [class.consumption]="currentConsumption >= 0 && !(status.trend > 1  && !status.noZone)"
                       [class.consumption-alert]="currentConsumption >= 0 && (status.trend > 1  && !status.noZone)">
                    </i>
<!--                    {{abs(currentConsumption.toLocaleString('de-DE'))}} {{valueUnit}}-->
                    {{currentConsumptionFormatted()}} {{valueUnit}}
                </div>
                <div class="grow-space">
                    <div class="tile-chart-abs">
                        <app-live-chart #liveChart></app-live-chart>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
