export const HouseholdAttributeIdentifierMapping = [
    // property
    {key: 'PT.01', value: 'freistehendes Haus'},
    {key: 'PT.02', value: 'Doppelhaushälfte'},
    {key: 'PT.03', value: 'Bungalow'},
    {key: 'PT.04', value: 'Reihenhaus'},
    {key: 'PT.05', value: 'Reiheneckhaus'},
    {key: 'PT.06', value: 'Wohnung'},
    // property size
    {key: 'PS.01', value: '1 Zimmer'},
    {key: 'PS.02', value: '2 Zimmer'},
    {key: 'PS.03', value: '3 Zimmer'},
    {key: 'PS.04', value: '4 Zimmer'},
    // property age
    {key: 'PA.01', value: 'vor 1919'},
    {key: 'PA.02', value: '1920 - 1975'},
    {key: 'PA.03', value: '1979 - 1999'},
    {key: 'PA.04', value: 'nach 2000'},
    // occupants
    {key: 'OCC.01', value: '1 Person'},
    {key: 'OCC.02', value: '2 Personen'},
    {key: 'OCC.03', value: '3 Personen'},
    {key: 'OCC.04', value: '4 Personen'},
    {key: 'OCC.05', value: '5+ Personen'},
    // space heating
    {key: 'SH.01', value: 'Gasheizung'},
    {key: 'SH.02', value: 'Wärmespeicher'},
    {key: 'SH.03', value: 'Elektroheizung'},
    {key: 'SH.04', value: 'Wärmepumpe'},
    {key: 'SH.05', value: 'Ölheizung'},
    {key: 'SH.07', value: 'Andere'},
    {key: 'SH.06', value: 'Solarzelle'},
    {key: 'SH.08', value: 'Hybrid-Wärmepumpe'},
    {key: 'SH.09', value: 'Fernwärme'},
    {key: 'SH.10', value: 'Holzpellets'},
    // water heating
    {key: 'WH.01', value: 'Gasheizkessel'},
    {key: 'WH.02', value: 'Elektrischer Boiler'},
    {key: 'WH.03', value: 'Ölheizkessel'},
    {key: 'WH.04', value: 'Solartherme'},
    {key: 'WH.05', value: 'Andere'},
];
