<div class="tile-container">

    <div class="tile" angulartics2On="click" angularticsCategory="Screens" angularticsAction="screen_view"
         angularticsLabel="screen: Verbrauchs-Alarm; previous_screen: Übersicht"
         (click)="onTileClicked()">

        <div class="tile-inner">
            <h3 class="tile-heading">Verbrauchsalarm</h3>
            <div class="tile-content col">
                <ng-container *ngIf="!showEmptyState">
                    <div class="consumption-alert-info p-b-s">
                        <div class="consumption-alert-info-text center-contents-v">
                            Maximum der letzten 7 Tage
                        </div>
                        <div class="consumption-alert-info-icon"
                             [class.high-alert]="currentZone !== 'lower'"
                             [class.low-alert]="currentZone === 'lower'">
                        </div>
                    </div>
                </ng-container>

                <div class="consumption-alert-tile-chart">
                    <ng-container *ngIf="!showEmptyState; else emptyState">
                        <div class="tile-chart-abs">
                            <app-consumption-alert-chart
                                #consumptionAlertChartComponent
                                (chartLoaded)="onChartLoaded($event)">
                            </app-consumption-alert-chart>
                        </div>
                    </ng-container>
                    <ng-template #emptyState>
                        <div class="animation-container"
                             lottie [options]="lottieConfig">
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>
