import {AppliancesDetailTexts} from '../../texts/detail/appliances-detail-texts';
import {DetailBannerData} from '../../interfaces/detail-banner.interfaces';

export const appliancesProfileUpdateBanner: DetailBannerData = {
    paragraphs: [
        {
            type: 'text',
            text: AppliancesDetailTexts.BANNER_UPDATE.TEXT,
            buttonText: '',
            buttonId: ''
        },
        {
            type: 'button',
            text: '',
            buttonText: AppliancesDetailTexts.BANNER_UPDATE.BUTTON_LABEL,
            buttonId: 'update-profile'
        }
    ],
    sideButton: {
        visible: true,
        isClose: true
    }
};

export const appliancesRetrainingBanner: DetailBannerData = {
    paragraphs: [
        {
            type: 'text',
            text: AppliancesDetailTexts.BANNER_RETRAINING.TEXT,
            buttonText: '',
            buttonId: ''
        }
    ],
    sideButton: {
        visible: true,
        isClose: false
    }
};
