import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';

import {ApiService} from '../../../services/api.service';
import {MockDataService} from '../../../services/mock-data.service';
import {NguCarousel, NguCarouselConfig} from '@ngu/carousel';
import {UserService} from '../../../services/user.service';
import {PowercheckerStateService} from '../../../services/powerchecker-state.service';
import {PowerCheckerService} from '../../../services/powerchecker.service';
import {ApplicationService} from '../../../services/application.service';
import {BasePopover} from '../../../classes/BasePopover';
import {PopoverRef} from '../../../popovers/popover/popover-ref';
import {ConfigurationService, ConfigurationUserStore} from '../../../services/configuration.service';

@Component({
    selector: 'app-powerchecker-details',
    templateUrl: './powerchecker-details.component.html',
    styleUrls: ['./powerchecker-details.component.scss'],
})

export class PowerCheckerDetailsComponent extends BasePopover implements OnInit {
    @ViewChild('room') private roomInputField: ElementRef<HTMLInputElement>;
    @ViewChild('name') private nameInputField: ElementRef<HTMLInputElement>;

    editModeEnabled = false;
    plugAttributes = {
        name: '',
        room: ''
    };
    plugName = 'Mein Gerät';
    plugRoom = 'Mein Zimmer';

    state = false;

    slideshowAnimations = [
        {
            path: 'assets/anim/stecker/powerchecker_1.json',
            renderer: 'svg',
            autoplay: true,
            autoplaySegments: false,
            loop: true,
            name: 'Smart Meter'
        },
        {
            path: 'assets/anim/stecker/powerchecker_2.json',
            renderer: 'svg',
            autoplay: true,
            autoplaySegments: false,
            loop: true,
            name: 'Smart Meter'
        },
        {
            path: 'assets/anim/stecker/powerchecker_3.json',
            renderer: 'svg',
            autoplay: true,
            autoplaySegments: false,
            loop: true,
            name: 'Smart Meter'
        },
        {
            path: 'assets/anim/stecker/powerchecker_4.json',
            renderer: 'svg',
            autoplay: true,
            autoplaySegments: false,
            loop: true,
            name: 'Smart Meter'
        },
    ];

    carouselTileConfig: NguCarouselConfig = {
        grid: {xs: 1, sm: 1, md: 1, lg: 1, all: 0},
        speed: 300,
        point: {visible: true},
        touch: true,
        loop: true,
        animation: 'lazy',
        interval: {
            timing: 10000,
            initialDelay: 10000
        },
        load: 4
    };

    infoVisible = false;


    constructor(public _apiService: ApiService,
                private _mockData: MockDataService,
                private userService: UserService,
                private powercheckerState: PowercheckerStateService,
                private powerchecker: PowerCheckerService,
                private application: ApplicationService,
                protected popoverRef: PopoverRef,
                private configuration: ConfigurationService) {
        super(popoverRef);
    }

    ngOnInit() {
        // console.log('RIP PowerChecker');
        this.getPlugAttributes();

        const stateS = this.powercheckerState.stateChange.subscribe(
            (value) => {
                this.state = value;
            }
        );
        this.addSub(stateS);

        if (!this.application.isDemoMode()) {
            this.initializePlugStatus();
            return;
        }

        const s = this._mockData.getPlugStatus().subscribe(
            (response: any) => {
                this.state = response.data.on_off === 1;
                s.unsubscribe();
            }
        );
    }


    /**
     * Initialize the plugs attributes
     */
    private initPlugAttributes(): void {
        this.plugAttributes = this.userService.getPlugAttributes();
        if (this.plugAttributes === null || this.plugAttributes === undefined) {
            this.getPlugAttributes();
            return;
        }

        this.plugName = this.plugAttributes.name;
        this.plugRoom = this.plugAttributes.room;
    }


    /**
     * Get the plugs current set status
     */
    private initializePlugStatus(): void {
        const sub = this.powerchecker.getState().subscribe(
            // const sub = this._apiService.getPlugStatus().subscribe(
            (res) => {
                if (!res) {
                    return;
                }
                if ('relay' in res) {
                    if ('on_off' in res.relay) {
                        const value = res.relay.on_off;
                        this.state = value === 1;
                    }
                }
                sub.unsubscribe();
            }
        );
    }


    /**
     * Get plug attributes from user storage
     */
    private getPlugAttributes(): void {
        if (this.application.isDemoMode()) {
            return;
        }

        this.configuration.requestDashboardConfiguration().subscribe({
            next: (storedData) => {
                if (storedData.status === 'success') {
                    const storedAttributes = storedData.data as ConfigurationUserStore;
                    this.plugAttributes = storedData.data;
                    if (storedAttributes.power_checker.name !== '') {
                        this.plugName = storedAttributes.power_checker.name;
                    }
                    if (storedAttributes.power_checker.name !== '') {
                        this.plugRoom = storedAttributes.power_checker.room;
                    }
                    this.userService.updatePlugAttributes(storedAttributes.power_checker);
                }
            }
        });
    }



    /**
     * On plug state changed
     */
    onPlugStateChange(state: boolean): void {
        this.state = state;
        this.powercheckerState.stateChange.next(state);
        if (this.application.isDemoMode()) {
            return;
        }
        const s = this._apiService.setPlugStatus(state ? 1 : 0).subscribe(
            (response) => {
                if ('data' in response) {
                    if ('relay' in response.data) {
                        if ('on_off' in response.data) {
                            const value = response.data.relay.on_off;
                            this.state = value === 1;
                        }
                    }
                }
                s.unsubscribe();
            },
            (error) => {
                s.unsubscribe();
            }
        );
    }


    /**
     * On saved changes made to the powerchecker attributes
     */
    onSaveChanges(): void {
        if (!this.editModeEnabled) {
            this.editModeEnabled = true;
            return;
        }

        const newName = this.nameInputField.nativeElement.value;
        const newRoom = this.roomInputField.nativeElement.value;

        if (this.editModeEnabled) {
            this.userService.plugAttributesChanged.next({name: newName, room: newRoom});
            if (this.application.isDemoMode()) {
                if (newName !== this.plugAttributes.name || newRoom !== this.plugAttributes.room) {
                    this.plugName = newName;
                    this.plugRoom = newRoom;
                }
                this.editModeEnabled = !this.editModeEnabled;
                return;
            }
            if (newName !== this.plugAttributes.name || newRoom !== this.plugAttributes.room) {
                this.userService.updatePlugAttributes({name: newName, room: newRoom});
                const s = this._apiService.setPlugAttributes().subscribe(
                    (response) => {
                        s.unsubscribe();
                    },
                    (error) => {
                        console.log('Error:', error);
                        s.unsubscribe();
                    }
                );
            }
        }
        this.editModeEnabled = !this.editModeEnabled;
    }

    onInputFocus(evt: FocusEvent): void {
        const target = evt.target as HTMLInputElement;
        target.select();
    }
}
