import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    private selectedLanguage = 'de';

    constructor(private translate: TranslateService) {
        this.initTranslate();
    }

    initTranslate(): void {
        this.translate.setDefaultLang('de');
        this.translate.use(this.selectedLanguage);
    }

    getLanguage(): string {
        return this.selectedLanguage;
    }

    setLanguage(language: string): void {
        this.selectedLanguage = language;
        this.translate.use(language);
    }
}
