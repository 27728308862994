<p>
    {{TEXTS.RETRAINING_INFO_PARAGRAPH}}
</p>


<ng-container *ngIf="retrainingCategoryData$ | async as retrainingData">

    <button class="eon-button-new m-b-x"
            (click)="onRetrainAllButtonClick(retrainingData)">
        {{LABELS.BTN_RETRAIN_ALL}}
    </button>

    <div *ngFor="let entry of retrainingData"
         class="retraining-category-item"
         [class.inactive]="entry.data.isInRetraining || entry.data.disabled">

        <div class="icon"
             [class.inactive]="entry.data.isInRetraining || entry.data.disabled"
             [style.mask-image]="determineIconUrl(entry)"
             [style.-webkit-mask-image]="determineIconUrl(entry)">
        </div>

        <div class="title font-bold"
             [class.inactive]="entry.data.isInRetraining || entry.data.disabled">
            {{entry.data.label}}
        </div>

        <div class="progress-container"
             [class.inactive]="entry.data.isInRetraining || entry.data.disabled">
            <div class="progress-bar"
                 [style.width]="determineRetrainingProgressPercentageStyle(entry)">
            </div>
        </div>

        <button class="retrain-single"
                [disabled]="entry.data.isInRetraining || entry.data.disabled"
                (click)="onRetrainButtonClick(entry)">
        </button>
    </div>
</ng-container>
