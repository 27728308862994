<div id="container">
    <aside class="sidebar lft">
        <a href="#" id="hamburger" title="Menü" class="mobi">
            <span class="burger">
                <i class="top"></i>
                <i class="middle"></i>
                <i class="bottom"></i>
            </span>
            <span class="text"> Menü</span>
        </a>
        <div class="blackbox">
            <div class="version">
                <a href="javascript:;" (click)="showChangelog()">{{ version }}</a>
            </div>
            <div [class.demo]="username === 'Demo'" class="logo">
                <a href="#/" title="E.ON Smart Control">
                    <span>E.ON Smart Control</span>
                </a>
            </div>
        </div>
        <div class="block block-menu">
            <nav class="inner">
                <ul class="clearfix">
                    <li><a href="#/" title="Übersicht">Übersicht</a></li>
                    <li><a href="#/meine-daten" title="Meine Daten">Meine Daten</a></li>
                    <li><a href="#/mein-haushalt" title="Mein Haushalt">Mein Haushalt</a></li>
                    <li><a href="#/mein-kundenkonto" angulartics2On="click" angularticsCategory="Meine Daten" angularticsAction="my_account_enter" title="{{ providerMenuName }}">{{ providerMenuName }}</a></li>
                    <li><a href="#/einstellungen" title="Einstellungen">Einstellungen</a></li>
                    <li><a href="#/kontakt" title="Kontakt">Kontakt</a></li>
                    <li><a href="javascript:;" title="{{ username === 'Demo' ? 'Demo-Modus verlassen' : 'Logout' }}" class="open-logout">{{ username === 'Demo' ? 'Demo-Modus verlassen' : 'Logout' }}</a></li>
                </ul>
            </nav>
        </div>
        <div class="block block-submenu">
            <div class="inner">
                <div *ngIf="providerclass?.length > 0" class="presentbar">
                    <small>Präsentiert von</small>
                    <div [class]="providerclass"></div>
                </div>
                <nav class="submenu">
                    <ul>
                        <li><a href="#/impressum" angulartics2On="click" angularticsCategory="Menü" angularticsAction="view_imprint" title="Impressum">Impressum</a></li>
                        <li><a href="assets/downloads/Datenschutzinformationen-E.ON Smart Control.pdf" target="_blank" angulartics2On="click" angularticsCategory="Menü" angularticsAction="view_privacy" title="Datenschutz">Datenschutz</a></li>
                    </ul>
                </nav>
            </div>
        </div>
    </aside>
    <div id="inner">
        <main id="mainContent" class="clearfix">
            <div class="kontakt">
                <div class="head">
                    <h2>Datenschutz</h2>
                </div>
                <div class="block block-content">
                    <div class="inner">
                        <a href="assets/downloads/Datenschutzinformationen-E.ON Smart Control.pdf" target="_blank">Download der Datenschutzbestimmungen</a>
                    </div>
                </div>
            </div>
        </main>
    </div>
</div>
<div *ngIf="logs" class="overlay-infos">
    <div class="overlay-box">
        <button type="button" (click)="hideChangelog()" class="icon-close fltrt">
            <span>Schließen</span>
        </button>
        <div *ngFor="let logs of changelog">
            <h4>{{ logs.version }}</h4>
            <ul *ngIf="logs.features.length > 0">
                <li class="head">Neue Funktionen:</li>
                <li *ngFor="let feature of logs.features">{{ feature.log }}</li>
            </ul>
            <ul *ngIf="logs.adjustments.length > 0">
                <li class="head">Anpassungen:</li>
                <li *ngFor="let adjustment of logs.adjustments">{{ adjustment.log }}</li>
            </ul>
            <ul *ngIf="logs.bugfixes.length > 0">
                <li class="head">Bugfixes:</li>
                <li *ngFor="let bugfix of logs.bugfixes">{{ bugfix.log }}</li>
            </ul>
        </div>
    </div>
</div>
<div id="overlay-logout" class="overlay-infos" style="display: none;">
    <div class="overlay-box">
        <button type="button" class="close-logout icon-close fltrt">
            <span>Schließen</span>
        </button>
        <h4>Abmelden</h4>
        <p>Wollen Sie sich wirklich abmelden?</p>
        <button type="button" (click)="_apiService.logoutUser()" class="btn orange">Ja</button>
        <button type="button" class="close-logout btn outline">Nein</button>
    </div>
</div>
