<div class="overlay">
    <div class="overlay-container-cdk">

        <div class="header">
            <div class="grow-space">
                <h2 class="m-a-n">Tarif Historie</h2>
            </div>
            <button type="button" class="icn-btn-close"
                    (click)="close(null)"></button>
        </div>

        <div class="content">
            <div class="scroll-list">
                <p *ngFor="let t of tariffs; let i = index">
                    <!--        <strong>{{ providername }}</strong><br>-->
                    <strong>{{provider}}</strong><br>
                    <strong>Tarif:</strong> {{t.name}}<br>

                    <strong>vom </strong> {{determineStartDate(t)}} <strong>bis </strong> {{determineEndDate(t, i)}}
                    <br>

                    <strong>Grundpreis:</strong> {{ t.basePrice}} pro Jahr<br>
                    <strong>Arbeitspreis:</strong> {{t.workPrice}}
                </p>
            </div>
        </div>

    </div>
</div>
