<div class="blackbox">
    <div class="version">
        <a href="javascript:;" (click)="showChangelog()">{{ version }}</a>
    </div>
</div>

<nav class="-block-menu">
    <div class="-logo"></div>

    <ul class="-menu-entries">
        <li>
            <a routerLink="/" title="Übersicht"
               [ngClass]="{active : activeElement == 'dashboard'}">Übersicht</a>
        </li>
        <li>
            <a routerLink="/meine-daten" title="Meine Daten"
               [ngClass]="{active : activeElement == 'profile'}">Meine Daten</a>
        </li>
        <li>
            <a routerLink="/mein-haushalt" title="Mein Haushalt"
               [ngClass]="{active: activeElement === 'household'}">Mein Haushalt</a>
        </li>
<!--        <li>-->
<!--            <a routerLink="/mein-kundenkonto" title="{{ providerMenuName }}"-->
<!--               [ngClass]="{active: activeElement === 'account'}"-->
<!--               angulartics2On="click" angularticsCategory="Meine Daten" angularticsAction="my_account_enter"-->
<!--            >{{ providerMenuName }}</a>-->
<!--        </li>-->
        <li>
            <a routerLink="/einstellungen" title="Einstellungen"
               [ngClass]="{active: activeElement === 'settings'}">Einstellungen</a>
        </li>
        <li>
            <a routerLink="/kontakt" title="Kontakt"
               [ngClass]="{active: activeElement === 'contact'}">Kontakt</a>
        </li>
        <li>
            <a routerLink="/hilfe" title="Hilfe"
               [ngClass]="{active: activeElement === 'help'}">Hilfe</a>
        </li>
        <li>
            <a href="javascript:;"
               title="{{ application.isDemoMode() ? 'Demo-Modus verlassen' : 'Logout' }}"
               (click)="onLogout()">{{ application.isDemoMode() ? 'Demo-Modus verlassen' : 'Logout' }}</a>
        </li>
    </ul>

</nav>

<div class="block block-submenu">
    <div class="inner">
        <nav class="submenu">
            <ul>
                <li><a routerLink="/impressum" title="Impressum" class="eon-regular"
                       angulartics2On="click" angularticsCategory="Menü" angularticsAction="view_imprint"
                >Impressum</a></li>
                <li>
                    <a [href]="privacyLink" target="_blank" title="Datenschutz" class="eon-regular"
                       angulartics2On="click" angularticsCategory="Menü" angularticsAction="view_privacy"
                    >Datenschutz</a>
                </li>
            </ul>
        </nav>
    </div>
</div>
