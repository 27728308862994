<div class="overlay-small">
    <div class="overlay-container">
        <h1>2-Faktor-Authentifizierung</h1>
        <p>
            Bitte geben Sie das Einmalkennwort aus der Authenticator-App ein, das beim Anlegen des Accounts generiert
            wurde.
        </p>
        <form [formGroup]="form" (ngSubmit)="submitCode()">
            <div>
                <fieldset class="eon-input">
                    <legend>Passwort</legend>
                    <input id="code-input" class="eon-input" type="text"
                           placeholder="Code eingeben"
                           (keydown)="onKeyDown($event)"
                           (keyup)="onKeyUp($event)"
                           formControlName="code">
                    <label for="code-input"></label>
                </fieldset>
                <div class="loading-indicator"
                     [class.hidden]="!isLoading">
                    <ng-lottie class="loading-spinner" height="35px"
                               [options]="loadingAnimation"></ng-lottie>
                </div>
            </div>
            <button class="eon-button-new"
                    type="submit"
                    [disabled]="submitDisabled || isLoading">
                Absenden
            </button>
        </form>
    </div>
    <div class="close-overlay-control center-contents" (click)="close(false)">
        <i class="close-s dark-grey"></i>
    </div>
</div>
