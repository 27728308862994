import {
    ChangeDetectorRef,
    Component, OnChanges,
    OnDestroy,
    OnInit, SimpleChanges,
} from '@angular/core';
import {NilmService} from '../../../services/nilm.service';
import {ProfileUpdateService} from '../../../services/profile-update.service';
import {
    AppliancesDetailDataProviderService
} from '../../../services/data-provider/appliances-detail-data-provider.service';
import {AppliancesDetailLabels} from '../../../shared/texts/detail/appliances-detail.labels';
import {AppliancesDetailTexts} from '../../../shared/texts/detail/appliances-detail-texts';
import {AppliancesDetailDataMode} from '../../../shared/enums/appliances-detail-data-mode.enum';
import {Subject, Subscription, tap} from 'rxjs';
import {map} from 'rxjs/operators';
import * as moment from 'moment/moment';
import {ViewState} from '../../../shared/enums/view-state.enum';
import {translateAppliance} from '../../../tiles/appliances/appliances.utils';
import {AnimationOptions} from 'ngx-lottie';


@Component({
    selector: 'app-appliance-detail-data-display',
    templateUrl: './appliance-detail-data-display.component.html',
    styleUrls: ['./appliance-detail-data-display.component.scss']
})
export class ApplianceDetailDataDisplayComponent implements OnInit, OnDestroy {
    protected readonly LABELS = AppliancesDetailLabels;
    protected readonly TEXTS = AppliancesDetailTexts;
    protected readonly ViewState = ViewState;

    protected translateAppliance = translateAppliance;

    private readonly monthDateDisplayFormat = 'MMMM YYYY';
    private readonly yearDateDisplayFormat = 'YYYY';

    currentMode: string = AppliancesDetailDataMode.MONTH;
    currentDataDate = '';
    timeframeOffset = 0;
    interactionDisabled = true;
    currentNilmPhaseAllowsRetraining = false;
    private _subscription: Subscription;

    isDataAvailable = true;

    appliancesDetailData$ = this.appliancesDetailDataProvider.appliancesDetailData$.pipe(
        tap(data => {
            if (!data.categories.length && data.viewState === 'SUCCESS'){
                this.isDataAvailable = false;
            } else {
                this.isDataAvailable = true;
            }
            this.interactionDisabled = false;
            this.cdRef.detectChanges();
        })
    );

    lottieConfig: AnimationOptions = {
        path: 'assets/anim/empty-states/animation_disaggregation_no_data.json',
        renderer: 'svg',
        autoplay: true,
        loop: true,
        name: 'Gerate '
    };

    currentNilmPhaseAllowsRetraining$ = this.nilm.getNilmStatusData().pipe(
        map(data => data.nilmStatus === 3)
    );

    enterRetraining$ = new Subject<boolean>();

    constructor(
        private nilm: NilmService,
        private profileUpdate: ProfileUpdateService,
        private appliancesDetailDataProvider: AppliancesDetailDataProviderService,
        private cdRef: ChangeDetectorRef
    ) {
        this._subscription = appliancesDetailDataProvider.currentModeChange.subscribe((value) => {
            this.handlePositionUpdate(value);
        });
    }


    ngOnInit(): void {
        this.appliancesDetailDataProvider.getAppliancesDataForCurrentMode();
        this.currentDataDate = moment()
            .locale('de')
            .subtract(this.timeframeOffset, 'months')
            .format(this.monthDateDisplayFormat);
        this.nilm.getNilmStatusData().subscribe(data =>
            this.currentNilmPhaseAllowsRetraining = data.nilmStatus === 3
        );
    }

    ngOnDestroy() {
        this._subscription.unsubscribe();
    }

    /**
     * Skip a position forward until the logical end is reached
     */
    positionForward() {
        if (this.timeframeOffset > 0) {
            --this.timeframeOffset;
            this.handlePositionUpdate();
        }
    }


    /**
     * Skip a position back
     */
    positionBack() {
        ++this.timeframeOffset;
        this.handlePositionUpdate();
    }


    /**
     * Toggle an accordion element
     */
    toggleAccordion(element): void {
        element.accordionOpen = !element.accordionOpen;
    }

    /**
     * Determine the icon path for an appliance category
     */
    determineApplianceCategoryItemPath(iconName): string {
        return `url(/assets/img/icons/household/${iconName}.png)`;
    }


    /**
     * Handle Position update
     * @private
     */
    private handlePositionUpdate(mode?: string): void {
        if (mode) {
            this.currentMode = mode;
            this.appliancesDetailDataProvider.updateCurrentOffset(0);
            this.timeframeOffset = 0;
        } else {
            this.appliancesDetailDataProvider.updateCurrentOffset(this.timeframeOffset);
        }
        this.appliancesDetailDataProvider.getAppliancesDataForCurrentMode();
        this.interactionDisabled = true;
        this.cdRef.detectChanges();

        switch (this.currentMode) {
            case AppliancesDetailDataMode.MONTH: {
                this.currentDataDate = moment()
                    .locale('de')
                    .subtract(this.timeframeOffset, 'months')
                    .format(this.monthDateDisplayFormat);
                break;
            }
            case AppliancesDetailDataMode.YEAR: {
                this.currentDataDate = moment()
                    .locale('de')
                    .subtract(this.timeframeOffset, 'years')
                    .format(this.yearDateDisplayFormat);
                break;
            }
        }
    }
}
