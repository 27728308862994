<div id="container">
    <aside class="sidebar lft">
        <a href="#" id="hamburger" title="Menü" class="mobi">
            <span class="burger">
                <i class="top"></i>
                <i class="middle"></i>
                <i class="bottom"></i>
            </span>
            <span class="text"> Menü</span>
        </a>

        <app-menu activeElement="contact"></app-menu>
    </aside>

    <div id="inner">
        <main class="page">
            <div class="page-card">
                <h1>Kontakt</h1>
                <h2>Direktkontakt</h2>
                <section>
                    <h3>Telefon</h3>
                    <p>Rufen Sie uns auf unserer kostenlosen Hotline an.</p>
                    <button class="eon-button-new"
                            (click)="openPhone()">
                        <i class="phone-sm white m-r-s"></i> 0800-99449021
                    </button>
                </section>
                <section>
                    <h3>E-Mail</h3>
                    <p>Schreiben Sie uns und teilen Sie uns Ihr Anliegen per E-Mail mit.</p>

                    <button class="eon-button-new"
                            (click)="openMail()">
                        <i class="mail-sm white m-r-s"></i> smartcontrol@eon.com
                    </button>
                </section>
            </div>
        </main>
    </div>

</div>
