<div class="login-container">

    <div class="login-card">
        <div class="top-logo center-contents">
            <div class="eon-logo-new"></div>
        </div>
        <div class="app-logo-container center-contents">
            <div class="app-icon"></div>
        </div>

        <ng-container [ngSwitch]="state">

            <!-- * INITIAL * -->
            <ng-container *ngSwitchCase="LoginState.INITIAL">
                <h1 class="eon-headline">
                    {{TEXTS.WELCOME_LINE_1}}
                    <br>
                    {{TEXTS.WELCOME_LINE_2}}
                </h1>

                <div class="grow-space">
                    <p>
                        {{TEXTS.FIRST_TIME_1}}
                        <strong>{{TEXTS.CODE}}</strong>
                        {{TEXTS.FIRST_TIME_2}}
                    </p>
                </div>

                <div class="center-contents btn-container">
                    <button class="eon-button-new"
                            (click)="onFirstTimeButtonPress()">
                        {{TEXTS.REGISTER}}
                    </button>
                </div>

                <div class="center-contents">
                    <a href="javascript:;" target="_self"
                       class="eon-link-new"
                       (click)="changeState(LoginState.LOGIN)">
                        {{TEXTS.HAS_PASSWORD}}
                    </a>
                </div>

                <div class="demo-btn pointer eon-bold">
                    <span (click)="continueWithDemoMode()">
                        {{state === LoginState.LOGIN || state === LoginState.INITIAL ? TEXTS.DEMO_MODE : ''}} </span>
                </div>
            </ng-container>

            <!-- * LOGIN * -->
            <ng-container *ngSwitchCase="LoginState.LOGIN">
                <form class="login-form" [formGroup]="loginForm" (ngSubmit)="login()">
                    <div class="grow-space">
                        <div class="btn-container">
                            <fieldset class="eon-input">
                                <legend>{{TEXTS.PLACEHOLDER_EMAIL}}</legend>
                                <input id="login-email" type="email" class="eon-input"
                                       formControlName="email">
                            </fieldset>
                        </div>

                        <div class="text-field-error eon-regular">
                            <div *ngIf="displayEmailError">
                                <i class="alert-s bordeaux m-r-s"></i>
                                {{TEXTS.EMAIL_ERROR}}
                            </div>
                        </div>

                        <div class="btn-container">
                            <fieldset class="eon-input">
                                <legend>{{TEXTS.PLACEHOLDER_PASSWORD}}</legend>
                                <input class="eon-input" [type]="passwordVisible ? 'text' : 'password'"
                                       formControlName="password">
                                <i [class.password-hidden]="!passwordVisible"
                                   [class.password-visible]="passwordVisible"
                                   (click)="passwordVisible = !passwordVisible">
                                </i>
                            </fieldset>
                        </div>
                        <div class="text-field-error eon-regular">
                            <div *ngIf="displayPasswordError">
                                <i class="alert-s bordeaux m-r-s"></i>
                                {{TEXTS.PASSWORD_ERROR}}
                            </div>
                        </div>

                        <div class="split-row">
                            <div class="demo-btn pointer eon-bold">
                            <span (click)="continueWithDemoMode()">
                                {{state === LoginState.LOGIN || state === LoginState.INITIAL ? 'Demo Modus' : ''}}
                            </span>
                            </div>
                            <div class="password-reset eon-bold">
                                <span (click)="onResetPasswordLinkClick()">
                                    {{TEXTS.FORGOT_PASSWORD}}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="center-contents btn-container">
                        <button class="eon-button-new"
                                [disabled]="loginDisabled">
                            {{TEXTS.LOGIN}}
                        </button>
                    </div>
                    <div class="center-contents">
                        <a href="javascript:;" target="_self" class="eon-link-new"
                           (click)="onRegisterLinkClick()">
                            {{TEXTS.REGISTER}}
                        </a>
                    </div>

                </form>
            </ng-container>

            <!-- * PASSWORD RESET * -->
            <ng-container *ngSwitchCase="LoginState.PASSWORD_RESET">
                <h1 class="eon-headline">
                    {{TEXTS.WELCOME_LINE_1}}
                    <br>
                    {{TEXTS.WELCOME_LINE_2}}
                </h1>

                <form [formGroup]="passwordResetForm" (ngSubmit)="resetPassword()">

                    <div class="grow-space p-t-l">
                        <fieldset class="eon-input">
                            <legend>{{TEXTS.PLACEHOLDER_PASSWORD}}</legend>
                            <input class="eon-input" type="email"
                                   formControlName="email">
                        </fieldset>
                    </div>

                    <div class="text-field-error eon-regular">
                        <div *ngIf="displayEmailResetError">
                            <i class="alert-s bordeaux m-r-s"></i>
                            {{TEXTS.EMAIL_ERROR}}
                        </div>
                    </div>

                    <div class="center-contents btn-container">
                        <button class="eon-button-new"
                                type="submit"
                                [disabled]="emailResetDisabled">
                            {{TEXTS.RESET}}
                        </button>
                    </div>
                </form>

                <div class="center-contents">
                       <div class="center-contents">
                        <a href="javascript:;" target="_self" class="eon-link-new"
                           (click)="changeState(LoginState.LOGIN)">
                            {{TEXTS.BACK_TO_LOGIN}}
                        </a>
                    </div>
                </div>

                <div class="demo-btn pointer eon-bold">
                    <span (click)="continueWithDemoMode()">
                        {{state === LoginState.LOGIN || state === LoginState.INITIAL ? TEXTS.DEMO_MODE : ''}} </span>
                </div>

            </ng-container>
        </ng-container>

    </div>
</div>
